/**
 * @require ./tools.js
 */
(function (tools) {
    var browsers = [
        { ua: navigator.userAgent, subUa: 'OPR', identity: 'Opera', versionSearch: 'OPR' },
        { ua: navigator.userAgent, subUa: 'Chrome', identity: 'Chrome' },
        { ua: navigator.userAgent, subUa: 'OmniWeb', versionSearch: 'OmniWeb/', identity: 'OmniWeb' },
        { ua: navigator.vendor, subUa: 'Apple', identity: 'Safari', versionSearch: 'Version' },
        { ua: navigator.vendor, subUa: 'iCab', identity: 'iCab' },
        { ua: navigator.vendor, subUa: 'KDE', identity: 'Konqueror' },
        { ua: navigator.userAgent, subUa: 'Firefox', identity: 'Firefox' },
        { ua: navigator.vendor, subUa: 'Camino', identity: 'Camino' },
        { ua: navigator.userAgent, subUa: 'Netscape', identity: 'Netscape' },
        { ua: navigator.userAgent, subUa: '(MSIE|trident)', identity: 'Internet Explorer', versionSearch: '(rv|MSIE)' },
        { ua: navigator.userAgent, subUa: 'Gecko', identity: 'Mozilla', versionSearch: 'rv' },
        { ua: navigator.userAgent, subUa: 'Mozilla', identity: 'Netscape', versionSearch: 'Mozilla'}
    ];

    var identity, version;

    function init() {
        var browserInfos = getBrowser();

        this.identity = browserInfos.identity;
        this.version = browserInfos.version;
    }

    function getBrowser() {
        var identity = 'unknown';
        var version = -1;
        var versionSearchString;

        $.each(browsers, function (_, browser) {
            var regex;

            if (!browser.ua) {
                return;
            }

            regex = new RegExp(browser.subUa, 'ig');

            if (regex.test(browser.ua)) {
                versionSearchString = browser.versionSearch || browser.identity;
                identity = browser.identity;
                return false;
            }
        });

        return {
            identity: identity,
            version: getVersion(versionSearchString)
        };
    }

    function getVersion(versionSearchString) {
        var regex = new RegExp(versionSearchString, 'ig');
        var ua, index;

        if (regex.test(navigator.userAgent)) {
            ua = navigator.userAgent;
            index = regex.lastIndex;
        } else if (regex.test(navigator.appVersion)) {
            ua = navigator.appVersion;
            index = regex.lastIndex;
        } else {
            return -1;
        }

        return parseFloat(/\d+/.exec(ua.substring(index + 1)));
    }

    tools.browserdetect = {
        init: init,
        identity: identity,
        version: version
    };
})(window.Tools || (window.Tools = {}));