/**
 * @require ./tools.charts.js
 */
(function (chartTools) {
    function createNumberChartSeriesFromRecorditems(recorditems, sortBy) {
        if (!(recorditems instanceof Array) || recorditems.length === 0) {
            return null;
        }

        var chartData = [];

        var regEx = /(^[<>][+-]?\d+(\.\d+)?$)|(^[<>]?[+-]?\d+(\.\d+)?e[+-]?\d+(\.\d+)?$)/;
        var regExNotMeasurable = /(^n{2}$)|(^ni$)|(^n\.(n\.|i\.)$)/;

        recorditems.forEach(function (recorditem) {
            var value = recorditem.Value || 0;
            var tmp, tmpIdx;

            var timestamp = sortBy === 'deadlineTimestamp' ? recorditem.DeadlineTimestamp : null;

            if (!timestamp) {
                timestamp = recorditem.CreationTimestamp;
            }

            if (regEx.test(value)) {
                if (/^[<>]/.test(value)) {
                    value = value.substr(1);
                }

                if ((tmpIdx = value.indexOf('e')) > 0) {
                    tmp = value.substr(tmpIdx).substr(1);
                    value = value.substr(0, tmpIdx);
                }

                value = parseFloat(value);

                if (!isNaN(value)) {
                    if (tmp) {
                        value *= Math.pow(10, tmp);
                    }

                    chartData.push([ timestamp.getTime(), value, recorditem.OID ]);
                }
            } else if (value === 0 || regExNotMeasurable.test(value)) {
                chartData.push([ timestamp.getTime(), 0, recorditem.OID ]);
            } else if (!isNaN(parseInt(recorditem.Value, 10))) {
                chartData.push([ timestamp.getTime(), typeof recorditem.Value === 'undefined' ? null : recorditem.Value, recorditem.OID ]);
            }
        });

        return chartData;
    }

    chartTools.series = {
        fromRecorditems: {
            createNumberChartSeries: createNumberChartSeriesFromRecorditems
        }
    };
})(window.Tools.charts);