/**
 * @require ./tools.js
 */
(function (tools) {
    function getPropertyPath(identifier, useModified) {
        var dataSource = useModified ? changemode.Properties : Properties;
        var path;

        if (dataSource.hasOwnProperty(identifier)) {
            var parent = dataSource[identifier];

            if (!parent.Parent) {
                return '';
            }

            parent = parent.Parent;
            path = parent.Title;

            for (var i = 2; i > 0 && (parent = parent.Parent); i--) {
                path = '{0} &rsaquo; {1}'.format(parent.Title, path);
            }
        }

        return path;
    }

    tools.properties = {
        getPropertyPath: getPropertyPath
    };
})(window.Tools || (window.Tools = {}));