/**
 * @require ../tools.js
 * @require tools.formula-methods.js
 */
(function (global) {
    function Stack() {
        this._values = [];
    }

    Stack.prototype.isEmpty = function () {
        return this._values.length === 0;
    };

    Stack.prototype.push = function (value) {
        this._values.push(value);
        return this;
    };

    Stack.prototype.pop = function () {
        return this._values.pop();
    };

    Stack.prototype.peek = function () {
        return this.isEmpty() ? undefined : this._values[this._values.length - 1];
    };


    function OperatorToken(symbol, isUnary) {
        this._symbol = symbol;
        this._isUnary = isUnary || false;

        switch (symbol) {
            case '!':
                this._rightAssociative = true;
                this._precedence = 7;
                break;
            case '^':
                this._rightAssociative = true;
                this._precedence = 7;
                break;
            case '*':
            case '/':
            case '%':
                this._rightAssociative = false;
                this._precedence = 6;
                break;
            case '+':
            case '-':
                if (isUnary) {
                    this._rightAssociative = true;
                    this._precedence = 7;
                } else {
                    this._rightAssociative = false;
                    this._precedence = 5;
                }
                break;
            case '<':
            case '<=':
            case '>':
            case '>=':
                this._rightAssociative = false;
                this._precedence = 4;
                break;
            case '==':
            case '!=':
                this._rightAssociative = false;
                this._precedence = 3;
                break;
            case '&&':
            case '&':
                this._rightAssociative = false;
                this._precedence = 2;
                break;
            case '||':
            case '|':
                this._rightAssociative = false;
                this._precedence = 1;
                break;
            default:
                throw 'Unknown operator "' + symbol + '"';
        }
    }

    OperatorToken.prototype.getSymbol = function () {
        return this._symbol;
    };

    OperatorToken.prototype.isLeftAssociative = function () {
        return !this._rightAssociative;
    };

    OperatorToken.prototype.isRightAssociative = function () {
        return this._rightAssociative;
    };

    OperatorToken.prototype.isUnary = function () {
        return this._isUnary;
    };

    OperatorToken.prototype.isBinary = function () {
        return !this._isUnary;
    };

    OperatorToken.prototype.comparePrecedence = function (other) {
        return this._precedence - other._precedence;
    };

    OperatorToken.prototype.toString = function () {
        return this._symbol;
    };


    function FunctionToken(name, arity) {
        this._name = name;
        this._arity = arity || 0;
    }

    FunctionToken.prototype.getName = function () {
        return this._name;
    };

    FunctionToken.prototype.setName = function (name) {
        this._name = name;
        return this;
    };

    FunctionToken.prototype.getArity = function () {
        return this._arity;
    };

    FunctionToken.prototype.setArity = function (arity) {
        this._arity = arity;
        return this;
    };

    FunctionToken.prototype.toString = function () {
        return this._name;
    };


    function FunctionLeftParenthesisToken() {
    }

    FunctionLeftParenthesisToken.prototype.toString = function () {
        return '(';
    };


    function FunctionRightParenthesisToken() {
    }

    FunctionRightParenthesisToken.prototype.toString = function () {
        return ')';
    };


    function LeftParenthesisToken() {
    }

    LeftParenthesisToken.prototype.toString = function () {
        return '(';
    };


    function RightParenthesisToken() {
    }

    RightParenthesisToken.prototype.toString = function () {
        return ')';
    };


    function CommaToken() {
    }

    CommaToken.prototype.toString = function () {
        return ',';
    };


    function NumberToken(value) {
        this._value = value;
    }

    NumberToken.prototype.getValue = function () {
        return this._value;
    };

    NumberToken.prototype.toString = function () {
        return this._value.toString();
    };


    function StringToken(value) {
        this._value = value || '';
    }

    StringToken.prototype.getValue = function () {
        return this._value;
    };

    StringToken.prototype.toString = function () {
        return '"' + DOMPurify.sanitize(this._value) + '"';
    };


    function PartialStringToken(value) {
        this._value = value || '';
    }

    PartialStringToken.prototype.getValue = function () {
        return this._value;
    };

    PartialStringToken.prototype.toString = function () {
        return '"' + DOMPurify.sanitize(this._value);
    };


    function ReferenceToken(type, id) {
        this._type = type;
        this._id = id;
    }

    ReferenceToken.prototype.getType = function () {
        return this._type;
    };

    ReferenceToken.prototype.getId = function () {
        return this._id;
    };

    ReferenceToken.prototype.toString = function () {
        return '#' + this._type + '<' + this._id + '>';
    };

    ReferenceToken.prototype.getValue = function () {
        return this._id;
    };


    function WhitespaceToken() {
    }

    WhitespaceToken.prototype.toString = function () {
        return ' ';
    };


    function UnknownToken(value) {
        this._value = value;
    }

    UnknownToken.prototype.toString = function () {
        return this._value;
    };


    var _numberRegex = /[\d.]/;
    var _alphaRegex = /[a-z]/i;
    var _alphanumericRegex = /[\da-z_]/i;
    var _referenceRegex = /^#([\a-z]+)<([\da-z_-]+)>$/i;
    var dot = '.';


    /**
     * Filtert, bis auf den ersten, alle Punkte einer eingegebenen Zahl heraus, um die Gültigkeit der Eingabe sicherzustellen
     * @param value
     * @return {string}
     */
    function filterRedundantDots(value) {
        var newValue = value;

        if (value && value.contains('.') &&
            value.split('').filter(function (char) {
                return char === dot;
            }).length >= 2) {
            var pointCounter = 0;

            newValue = value.split('').filter(function (char) {
                if (char === dot) {
                    pointCounter++;

                    if (pointCounter > 1) {
                        return false;
                    }
                }

                return true;
            }).join('');
        }

        return newValue;
    }

    function tokenize(expression) {
        var tokens = [];
        var parenthesesStack = new Stack();
        var idx = 0;
        var lastIdx = expression.length - 1;
        var state = null;
        var remember = null;
        var char, nextChar, j, otherToken, match;

        while (idx <= lastIdx) {
            char = expression[idx];

            switch (state) {
                case 'WithinReference':
                    if (char === '>') {
                        match = _referenceRegex.exec(remember + char);

                        if (match) {
                            tokens.push(new ReferenceToken(match[1], match[2]));
                        } else {
                            tokens.push(new UnknownToken(remember + char));
                        }

                        state = null;
                        remember = null;
                    } else if (idx === lastIdx) {
                        tokens.push(new UnknownToken(remember + char));
                        state = null;
                        remember = null;
                    } else {
                        remember += char;
                    }
                    break;
                case 'WithinString':
                    if (char === '"') {
                        tokens.push(new StringToken(remember));
                        state = null;
                        remember = null;
                    } else if (idx === lastIdx) {
                        tokens.push(new PartialStringToken(remember + char));
                        state = null;
                        remember = null;
                    } else {
                        remember += char;
                    }
                    break;
                case 'WithinNumber':
                    var fixDecimals = false;
                    var isNumber = _numberRegex.test(char); // '.' ist erlaubt

                    remember = filterRedundantDots(remember);

                    if (isNumber) {
                        if (idx !== lastIdx) {
                            // Zeichen merken, da das Ende noch nicht erreicht ist
                            remember += char;
                            break;
                        }

                        if (char === dot || char === '0') {
                            // Wenn das letzte Zeichen ein Punkt oder eine Null ist -> nicht entfernen! Eingabe merken
                            fixDecimals = true;
                            remember += char;
                        } else {
                            tokens.push(new NumberToken(Number(remember + char)));
                        }

                        if (!fixDecimals) {
                            remember = null;
                            state = null;
                            break;
                        }
                    }

                    if (!isNumber || char === dot || char === '0') {
                        var floatTokens = [];
                        remember = filterRedundantDots(remember);
                        var numberValue = Number(remember);
                        var hasDecimals = (numberValue - Math.floor(numberValue)) !== 0;

                        if (remember && remember.contains(dot)) {
                            if (remember[remember.length - 1] === dot) {
                                // Wenn das letzte Zeichen vom remember ein Punkt ist, UnknownToken hinzufügen.
                                floatTokens.push(new UnknownToken(dot));
                            } else if (remember.length >= 3) {
                                var decimalPart = remember.split(dot)[1];

                                if (!hasDecimals) {
                                    // Für den Fall das z.B. "4.0" eingegeben wurde, damit die 0 und der Punkt nicht entfernt werden
                                    floatTokens.push(new UnknownToken(dot));
                                }

                                // Alle Nullen hinzufügen, die hinter der eingegebenen Kommazahl hinzugefügt wurden z.B. "4.0100".
                                // Erklärung: die Zahl "4.01" wird als NumberToken hinzugefügt und alle Nullen dahinter werden als UnknownToken hinzugefügt.
                                // Dadurch sind im Nachhinein z. B. Eingaben wie "4.012300123" möglich.
                                for (var i = decimalPart.length - 1; i >= 0; i--) {
                                    var number = decimalPart[i];

                                    if (number !== '0') {
                                        break;
                                    }

                                    floatTokens.push(new UnknownToken(number));
                                }
                            }
                        }

                        tokens.push(new NumberToken(numberValue));
                        tokens = tokens.concat(floatTokens);

                        state = null;
                        remember = null;

                        if (!fixDecimals) {
                            idx--;
                        }
                    }
                    break;
                case 'WithinFunction':
                    if (char === '(') {
                        tokens.push(new FunctionToken(remember));
                        tokens.push(new FunctionLeftParenthesisToken());
                        parenthesesStack.push(new FunctionLeftParenthesisToken());
                        state = null;
                    } else if (!_alphanumericRegex.test(char)) {
                        tokens.push(new FunctionToken(remember));
                        state = null;
                        remember = null;
                        idx--;
                    } else {
                        remember += char;

                        if (idx === lastIdx) {
                            tokens.push(new FunctionToken(remember));
                            state = null;
                            remember = null;
                        }
                    }
                    break;
                default:
                    switch (char) {
                        case ' ':
                            tokens.push(new WhitespaceToken());
                            break;
                        case ',':
                            tokens.push(new CommaToken());
                            break;
                        case '(':
                            tokens.push(new LeftParenthesisToken());
                            parenthesesStack.push(new LeftParenthesisToken());
                            break;
                        case ')':
                            if (parenthesesStack.pop() instanceof FunctionLeftParenthesisToken) {
                                tokens.push(new FunctionRightParenthesisToken());
                            } else {
                                tokens.push(new RightParenthesisToken());
                            }
                            break;
                        case '#':
                            state = 'WithinReference';
                            remember = '';
                            idx--;
                            break;
                        case '"':
                            if (idx === lastIdx) {
                                tokens.push(new PartialStringToken());
                            } else {
                                state = 'WithinString';
                                remember = '';
                            }
                            break;
                        case '=':
                            nextChar = idx < lastIdx ? expression[idx + 1] : null;

                            if (nextChar === '=') {
                                tokens.push(new OperatorToken('=='));
                                idx++;
                            } else {
                                tokens.push(new UnknownToken(char));
                            }
                            break;
                        case '!':
                            nextChar = idx < lastIdx ? expression[idx + 1] : null;

                            if (nextChar === '=') {
                                tokens.push(new OperatorToken('!='));
                                idx++;
                            } else {
                                tokens.push(new OperatorToken('!', true));
                            }
                            break;
                        case '<':
                        case '>':
                            nextChar = idx < lastIdx ? expression[idx + 1] : null;

                            if (nextChar === '=') {
                                tokens.push(new OperatorToken(char + '='));
                                idx++;
                            } else {
                                tokens.push(new OperatorToken(char));
                            }
                            break;
                        case '&':
                            nextChar = idx < lastIdx ? expression[idx + 1] : null;

                            if (nextChar === '&') {
                                tokens.push(new OperatorToken('&&'));
                                idx++;
                            } else {
                                tokens.push(new OperatorToken('&'));
                            }
                            break;
                        case '|':
                            nextChar = idx < lastIdx ? expression[idx + 1] : null;

                            if (nextChar === '|') {
                                tokens.push(new OperatorToken('||'));
                                idx++;
                            } else {
                                tokens.push(new OperatorToken('|'));
                            }
                            break;
                        case '^':
                        case '*':
                        case '/':
                        case '%':
                            tokens.push(new OperatorToken(char));
                            break;
                        case '+':
                        case '-':
                            otherToken = null;
                            j = tokens.length - 1;

                            while (j >= 0) {
                                if (!(tokens[j] instanceof WhitespaceToken)) {
                                    otherToken = tokens[j];
                                    break;
                                }

                                j--;
                            }

                            if (otherToken instanceof OperatorToken ||
                                otherToken instanceof FunctionLeftParenthesisToken ||
                                otherToken instanceof LeftParenthesisToken ||
                                otherToken instanceof CommaToken) {
                                tokens.push(new OperatorToken(char, true));
                            } else {
                                tokens.push(new OperatorToken(char));
                            }
                            break;
                        case '0':
                        case '1':
                        case '2':
                        case '3':
                        case '4':
                        case '5':
                        case '6':
                        case '7':
                        case '8':
                        case '9':
                            state = 'WithinNumber';
                            remember = '';
                            idx--;
                            break;
                        default:
                            if (_alphaRegex.test(char)) {
                                state = 'WithinFunction';
                                remember = '';
                                idx--;
                            }
                            break;
                    }
                    break;
            }

            idx++;
        }

        return tokens;
    }

    function stringify(tokens) {
        return tokens
            .map(function (token) {
                return token.toString();
            })
            .join('');
    }

    function convertInfixToRpn(tokens) {
        var output = [];
        var idx = 0;
        var lastIdx = tokens.length - 1;
        var tokenStack = new Stack();
        var arityStack = new Stack();
        var token, otherToken, arity;

        while (idx <= lastIdx) {
            token = tokens[idx];

            if (token instanceof OperatorToken) {
                while (true) {
                    otherToken = tokenStack.peek();

                    if (otherToken instanceof OperatorToken &&
                        (otherToken.comparePrecedence(token) > 0 ||
                            otherToken.comparePrecedence(token) === 0 &&
                            otherToken.isLeftAssociative())) {
                        output.push(tokenStack.pop());
                    } else {
                        break;
                    }
                }

                tokenStack.push(token);
            } else if (token instanceof FunctionToken) {
                if (idx + 2 > lastIdx) {
                    throw 'Unexpected end of input';
                }

                if (tokens[idx + 2] instanceof RightParenthesisToken ||
                    tokens[idx + 2] instanceof FunctionRightParenthesisToken) {
                    output.push(token);
                    idx += 2;
                } else {
                    arityStack.push(1);
                    tokenStack.push(token);
                }
            } else if (token instanceof LeftParenthesisToken ||
                token instanceof FunctionLeftParenthesisToken) {
                tokenStack.push(token);
            } else if (token instanceof RightParenthesisToken ||
                token instanceof FunctionRightParenthesisToken) {
                while (true) {
                    otherToken = tokenStack.pop();

                    if (!otherToken) {
                        throw 'Unbalanced parentheses';
                    }

                    if (otherToken instanceof LeftParenthesisToken ||
                        otherToken instanceof FunctionLeftParenthesisToken) {
                        if (tokenStack.peek() instanceof FunctionToken) {
                            arity = arityStack.pop();
                            output.push(tokenStack.pop().setArity(arity));
                        }
                        break;
                    }

                    output.push(otherToken);
                }
            } else if (token instanceof CommaToken) {
                arity = arityStack.pop();

                if (!arity) {
                    throw 'Unexpected token ,';
                }

                arityStack.push(arity + 1);

                while (true) {
                    otherToken = tokenStack.peek();

                    if (!otherToken) {
                        throw 'Invalid formula';
                    }

                    if (otherToken instanceof LeftParenthesisToken ||
                        otherToken instanceof FunctionLeftParenthesisToken) {
                        break;
                    }

                    output.push(tokenStack.pop());
                }
            } else if (token instanceof NumberToken ||
                token instanceof StringToken ||
                token instanceof ReferenceToken) {
                output.push(token);
            } else if (token instanceof PartialStringToken) {
                throw 'Unexpected end of input';
            } else if (token instanceof UnknownToken) {
                throw 'Unexpected token ' + token.toString();
            }

            idx++;
        }

        while (true) {
            token = tokenStack.pop();

            if (!token) {
                break;
            }

            if (token instanceof LeftParenthesisToken ||
                token instanceof FunctionLeftParenthesisToken) {
                throw 'Unbalanced parentheses';
            }

            output.push(token);
        }

        return output;
    }

    function isNumber(value) {
        return typeof value === 'number' && !isNaN(value);
    }

    function isString(value) {
        return typeof value === 'string';
    }

    function isDate(value) {
        return value instanceof Date;
    }

    function isNumberOrStringOrDate(value) {
        return isNumber(value) || isString(value) || isDate(value);
    }

    function Formula(options) {
        options = options || {};

        this._functions = {};

        Object
            .keys((options.functions || {}).Functions || {})
            .map(function (key) {
                var name = key.toLowerCase();
                var fn = ((options || {}).functions || {}).Functions[key];

                this._functions[name] = fn;
            }, this);

        this._functions['Context'] = (options.functions || {}).Context;
        this._functions['GetFunction'] = (options.functions || {}).__proto__.GetFunction;
        this._functions['UpdateContext'] = (options.functions || {}).__proto__.UpdateContext;
    }

    Formula.prototype.evaluate = function (expression) {
        var infixTokens, rpnTokens, idx, lastIdx;
        var resultStack, token, i, arity, parameterArray, fnName, fn, result, symbol;

        expression = expression.replace(/\s/g, ' ');

        infixTokens = tokenize(expression);
        rpnTokens = convertInfixToRpn(infixTokens);
        idx = 0;
        lastIdx = rpnTokens.length - 1;
        resultStack = new Stack();

        while (idx <= lastIdx) {
            token = rpnTokens[idx];

            if (token instanceof OperatorToken) {
                parameterArray = [];

                if (token.isUnary()) {
                    if (resultStack.isEmpty()) {
                        throw 'Operator ' + token.getSymbol() + ' expected 1 parameter.';
                    }

                    parameterArray.push(resultStack.pop());
                } else {
                    for (i = 0; i < 2; i++) {
                        if (resultStack.isEmpty()) {
                            throw 'Operator ' + token.getSymbol() + ' expected 2 parameters.';
                        }

                        parameterArray.push(resultStack.pop());
                    }
                }

                symbol = token.getSymbol();

                if ((symbol === '+' || symbol === '==') && token.isBinary()) {
                    if (!parameterArray.every(isNumberOrStringOrDate)) {
                        throw 'Invalid number or string.';
                    }
                } else if (!parameterArray.every(isNumber)) {
                    throw 'Invalid number.';
                }

                switch (symbol) {
                    case '!':
                        resultStack.push(!parameterArray[0]);
                        break;
                    case '^':
                        resultStack.push(parameterArray[1], parameterArray[0]);
                        break;
                    case '*':
                        resultStack.push(parameterArray[1] * parameterArray[0]);
                        break;
                    case '/':
                        if (parameterArray[0] === 0) {
                            throw 'Invalid argument.';
                        }

                        resultStack.push(parameterArray[1] / parameterArray[0]);
                        break;
                    case '%':
                        if (parameterArray[0] === 0) {
                            throw 'Invalid argument.';
                        }

                        resultStack.push(parameterArray[1] % parameterArray[0]);
                        break;
                    case '+':
                        if (token.isUnary()) {
                            resultStack.push(+parameterArray[0]);
                        } else {
                            var firstParameter = parameterArray[0];
                            var secondParameter = parameterArray[1];
                            var date, dateString, isTimeNull;

                            if (firstParameter instanceof Date) {
                                date = firstParameter;
                                isTimeNull = Tools.dateTime.isTimeOfDateNull(date);
                                dateString = Tools.dateTime.getDateString(date);
                                firstParameter = isTimeNull
                                    ? dateString
                                    : (dateString + ' ' + date.toLocaleTimeString()).slice(0, -2); // damit die Sekunden nicht angezeigt werden
                            }

                            if (secondParameter instanceof Date) {
                                date = secondParameter;
                                isTimeNull = Tools.dateTime.isTimeOfDateNull(date);
                                dateString = Tools.dateTime.getDateString(date);
                                secondParameter = isTimeNull
                                    ? dateString
                                    : (dateString + ' ' + date.toLocaleTimeString()).slice(0, -2);
                            }

                            resultStack.push(secondParameter + firstParameter);
                        }
                        break;
                    case '-':
                        if (token.isUnary()) {
                            resultStack.push(-parameterArray[0]);
                        } else {
                            resultStack.push(parameterArray[1] - parameterArray[0]);
                        }
                        break;
                    case '<':
                        resultStack.push(parameterArray[1] < parameterArray[0]);
                        break;
                    case '<=':
                        resultStack.push(parameterArray[1] <= parameterArray[0]);
                        break;
                    case '>':
                        resultStack.push(parameterArray[1] > parameterArray[0]);
                        break;
                    case '>=':
                        resultStack.push(parameterArray[1] >= parameterArray[0]);
                        break;
                    case '==':
                        resultStack.push(parameterArray[1] === parameterArray[0]);
                        break;
                    case '!=':
                        resultStack.push(parameterArray[1] !== parameterArray[0]);
                        break;
                    case '&&':
                        resultStack.push(parameterArray[1] && parameterArray[0]);
                        break;
                    case '&':
                        resultStack.push(parameterArray[1] & parameterArray[0]);
                        break;
                    case '||':
                        resultStack.push(parameterArray[1] || parameterArray[0]);
                        break;
                    case '|':
                        resultStack.push(parameterArray[1] | parameterArray[0]);
                        break;
                }
            } else if (token instanceof FunctionToken) {
                fnName = token.getName();
                fn = this._functions['GetFunction'](fnName);

                if (typeof fn !== 'function') {
                    throw 'Function ' + token.getName() + ' does not exist.';
                }

                parameterArray = [];

                for (i = 0, arity = token.getArity(); i < arity; i++) {
                    if (resultStack.isEmpty()) {
                        throw 'Function ' + token.getName() + ' expected ' + arity + ' parameters.';
                    }

                    parameterArray.push(resultStack.pop());
                }

                resultStack.push(fn.apply(this._functions, parameterArray.reverse()));
            } else {
                resultStack.push(token.getValue());
            }

            idx++;
        }

        if (resultStack.isEmpty()) {
            throw 'Invalid expression';
        }

        result = resultStack.pop();

        if (!resultStack.isEmpty()) {
            throw 'Invalid expression';
        }

        return result;
    };

    Formula.containsFormulaFunctions = function (str) {
        return /\w*\(.*\)/.test(str || '');
    };

    Formula.tokenize = tokenize;
    Formula.stringify = stringify;
    Formula.OperatorToken = OperatorToken;
    Formula.FunctionToken = FunctionToken;
    Formula.FunctionLeftParenthesisToken = FunctionLeftParenthesisToken;
    Formula.FunctionRightParenthesisToken = FunctionRightParenthesisToken;
    Formula.LeftParenthesisToken = LeftParenthesisToken;
    Formula.RightParenthesisToken = RightParenthesisToken;
    Formula.CommaToken = CommaToken;
    Formula.NumberToken = NumberToken;
    Formula.StringToken = StringToken;
    Formula.PartialStringToken = PartialStringToken;
    Formula.ReferenceToken = ReferenceToken;
    Formula.WhitespaceToken = WhitespaceToken;
    Formula.UnknownToken = UnknownToken;

    return (global.Formula = Formula);
})(window);