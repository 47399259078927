/**
 * @require ./tools.js
 */
(function (global) {
    var InteractJs = {};

    /***
     * Prüft, ob sich das zu verschiebende Element auch tatsächlich über dem sichtbaren Teil der Baumstruktur befindet.
     * @return {boolean}
     * @see https://interactjs.io/docs/dropzone/#checker
     */
    InteractJs.IsBeingDraggedOverTreeNode = function (dragEvt, evt, dropped, dropzone, dropElement, draggable, draggableElement) {
        if (!dropped) {
            return false;
        }

        if (!evt) {
            return false;
        }

        var evtPath = evt.composedPath();

        if (!(evtPath instanceof Array && evtPath.length)) {
            evtPath = evt.path;
        }

        if (evtPath instanceof Array) {
            return evtPath.some(function (domElement) {
                if (domElement.classList) {
                    return domElement.classList.contains('jquery-tree-node');
                }

                return false;
            });
        }

        return false;
    };

    /**
     * @param $dropzone
     * @param $dropElement
     * @return {{X: number, Y: number}|null}
     * @constructor
     */
    InteractJs.GetPositionInDropzone = function ($dropzone, $dropElement) {
        if (!($dropzone instanceof $) || !($dropElement instanceof $)) {
            return null;
        }

        const dropzonePostion = $dropzone.position();
        const elementPosition = $dropElement.position();

        return {
            X: elementPosition.left - dropzonePostion.left,
            Y: elementPosition.top - dropzonePostion.top
        };
    };

    return (global.InteractJs = InteractJs);
})(window.Tools || (window.Tools = {}));