/**
 * @require ./tools.js
 */
(function (tools) {
    tools.http = (function () {
        function get(method, success, error, data, dataType) /*Deferred*/ {
            return createRequest('GET', method, success, error, false, data, dataType);
        }

        function put(method, data, success, error, dataType, csrfRequiredOverride) /*Deferred*/ {
            return createRequest('PUT', method, success, error,
                csrfRequiredOverride == null ? true : csrfRequiredOverride, data, dataType);
        }

        function post(method, data, success, error, dataType, csrfRequiredOverride) /*Deferred*/ {
            return createRequest('POST', method, success, error,
                csrfRequiredOverride == null ? true : csrfRequiredOverride, data, dataType);
        }

        function del(method, success, error, dataType, csrfRequiredOverride) /*Deferred*/ {
            return createRequest('DELETE', method, success, error,
                csrfRequiredOverride == null ? true : csrfRequiredOverride, null, dataType);
        }

        function createRequest(type, method, success, error, csrfRequired, data, dataType) /*Deferred*/ {
            success = typeof success === 'function' ? success : $.noop;
            error = typeof error === 'function' ? error : $.noop;
            dataType = dataType === undefined ? 'json' : dataType;

            function getOriginAjaxSettings() {
                var settings = {
                    type: type,
                    url: Config.BaseUri + method,
                    contentType: 'application/json; charset=utf-8',
                    dataType: dataType,
                    beforeSend: function (xhr) {
                        xhr.withCredentials = true;
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    success: success,
                    error: error
                };

                if (csrfRequired) {
                    settings.headers = settings.headers || {};
                    settings.headers['X-Csrf-Token'] = Tools.Cookies.getCSRFToken();
                }

                if (Tools.IsSet(data)) {
                    // Werden bei GET automatisch als Query-Parameter zur url hinzugefügt
                    settings.data = type === 'GET' || typeof data === 'string' ? data : JSON.stringify(data);
                }

                return settings;
            }

            var originAjax;
            var deferred = Tools.CreateAbortableDeferred(function () {
                if (originAjax) {
                    originAjax.abort();
                }
            });

            sendHeartbeatIfRequired(csrfRequired, error)
                .then(function() {
                    //nur wenn das deferred noch nicht abgeschlossen / rejected wurde
                    if (deferred.state() !== 'pending') {
                        return;
                    }

                    originAjax = $.ajax(getOriginAjaxSettings());
                    originAjax.then(deferred.resolve, deferred.reject);
                }, deferred.reject);

            return deferred.promise();
        }

        function sendHeartbeatIfRequired(csrfRequired, errorCallback) /*Deferred*/ {
            var csrfToken = Tools.Cookies.getCSRFToken();

            if (!csrfRequired || (csrfToken && Tools.IsCsrfTokenValid(csrfToken))) {
                return $.Deferred().resolve().promise();
            }

            return $.ajax({
                type: 'GET',
                url: Config.BaseUri + 'heartbeat',
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                beforeSend: function(xhr) {
                    xhr.withCredentials = true;
                },
                xhrFields: {
                    withCredentials: true
                },
                error: errorCallback
            });
        }

        return {
            get: get,
            put: put,
            post: post,
            del: del
        };
    })();
})(window.Tools || (window.Tools = {}));