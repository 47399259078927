/**
 * @require ./tools.js
 */
(function (tools) {
    function prepareIssueForWebApp(issue) {
        if (!issue) {
            return null;
        }

        issue.CustomID = issue.CustomID || '' + issue.ID;
        issue.NaturalizedCustomID = naturalizeCustomID(issue.CustomID);
        issue.CreationTimestamp = new Date(issue.CreationTimestamp);
        issue.ModificationTimestamp = new Date(issue.ModificationTimestamp);
        issue.RemindingTimestamp = issue.RemindingTimestamp ? new Date(issue.RemindingTimestamp) : null;
        issue.DeadlineTimestamp = issue.DeadlineTimestamp ? new Date(issue.DeadlineTimestamp) : null;
        issue.TerminationTimestamp = issue.TerminationTimestamp ? new Date(issue.TerminationTimestamp) : null;
        issue.Creator = Users[issue.CreatorOID];
        issue.Editor = Users[issue.EditorOID];
        issue.State = issue.StateOID ? Properties[issue.StateOID] : null;
        issue.Priority = issue.PriorityOID ? Properties[issue.PriorityOID] : null;
        issue.ParameterCount = issue.ParameterCount || 0;
        issue.RequiredParameterCount = issue.RequiredParameterCount || 0;
        issue.CollectedParameterCount = issue.CollectedParameterCount || 0;
        issue.CollectedRequiredParameterCount = issue.CollectedRequiredParameterCount || 0;
        issue.OpenParameterCount = issue.RequiredParameterCount - issue.CollectedRequiredParameterCount;

        issue.Classifications = $.map(issue.Classifications || [], function (oid) {
            return Properties[oid];
        });

        issue.Keywords = $.map(issue.Keywords || [], function (oid) {
            return Properties[oid];
        });

        (issue.Files || []).sort(Tools.SortByPosition);

        var checkpointPreviewImage = null;
        if (!issue.Files && issue.AdditionalData && issue.AdditionalData.CheckpointPreviewImages) {
            var previewImagesFromCheckpoints = issue.AdditionalData.CheckpointPreviewImages;
            // Indices für Vorschaubilder aufsteigend sortieren
            var indices = Object.keys(previewImagesFromCheckpoints).sort(function(a, b) { return +a - +b; });

            for (var i = 0; i < indices.length; i++) {
                var key = indices[i]

                // erstes gültiges Bild aus der Liste verwenden
                checkpointPreviewImage = previewImagesFromCheckpoints[key];
                if (checkpointPreviewImage) {
                    break;
                }
            }
        }

        issue.LocationPath = Explorer.CreateOrganisationUnitPath(issue.AssignedElementOID);
        issue.Users = prepareUsers(issue.Users);
        issue.Teams = prepareTeams(issue.Teams);
        issue.Recipients = prepareRecipients(issue.NotificationRecipients);
        issue.Comments = prepareComments(issue.Comments);
        issue.Image = prepareImage(issue.Files, checkpointPreviewImage);

        return Tools.Escape.Issue(issue);
    }

    function naturalizeCustomID(input) {
        if (!input) {
            return '';
        }

        return input
            .replace(/\s{2,}/g, ' ')
            .replace(/\d+/g, function (val) {
                return val.padleft('0', 15);
            });
    }

    function prepareUsers(users) {
        users = $.map(users || [], function (oid) {
            return Users[oid];
        });

        users.sort(Tools.SortByTitle);

        return users;
    }

    function prepareTeams(teams) {
        teams = $.map(teams || [], function (oid) { return Teams[oid]; });

        teams = Tools.sort(teams, 'Title');

        return teams;
    }

    function prepareRecipients(notificationRecipients) {
        if (!notificationRecipients) {
            return recipients;
        }

        var recipients = [].concat(
            notificationRecipients.Users || [],
            notificationRecipients.Contacts || []
        );

        (notificationRecipients.Teams || []).forEach(function (teamOID) {
            var team = Teams[teamOID];

            if (!team || !team.Users) {
                return;
            }

            team.Users.forEach(function (userRole) {
                recipients = Tools.addUnique(recipients, userRole.OID);
            });
        });

        (notificationRecipients.ContactGroups || []).forEach(function (contactGroupOID) {
            var contactGroup = ContactGroups[contactGroupOID];

            if (!contactGroup || !contactGroup.Contacts) {
                return;
            }

            contactGroup.Contacts.forEach(function (contactOID) {
                recipients = Tools.addUnique(recipients, contactOID);
            });
        });

        (notificationRecipients.Unsubscribed || []).forEach(function (userOID) {
            recipients = Tools.remove(recipients, userOID);
        });

        return recipients;
    }

    function prepareComments(comments) {
        comments = $.map(comments || [], function (comment) {
            comment.Timestamp = new Date(comment.Timestamp);

            if ((comment.Creator = Users[comment.CreatorOID])) {
                comment.Creator = $.extend({}, comment.Creator);
                comment.Creator.Image = Files[comment.Creator.ImageOID];
            }

            return comment;
        });

        comments.sort(function (a, b) {
            return a.Timestamp.getTime() - b.Timestamp.getTime();
        });

        return comments;
    }

    function prepareImage(files, checkpointPreviewImage) {
        for (var i = 0, len = (files || []).length; i < len; i++) {
            var file = files[i];

            if (file.MimeType && file.MimeType.contains('image/') && !file.MimeType.contains('svg')) {
                return file;
            }
        }

        if (checkpointPreviewImage && checkpointPreviewImage.MimeType.contains('image/')) {
            return checkpointPreviewImage;
        }

        return null;
    }

    function prepareIssueForWebService(issue, changeComment) {
        issue.PrecedingOID = issue.OID;
        issue.OID = uuid();

        return {
            OID: issue.OID,
            PrecedingOID: issue.PrecedingOID,
            ID: issue.ID,
            Revision: (issue.Revision || 0) + 1,
            ParentID: issue.ParentID,
            CustomID: Tools.unescapeHtml(issue.CustomID),
            Type: issue.Type,
            Title: Tools.unescapeHtml(issue.Title),
            Description: Tools.unescapeHtml(issue.Description),
            CreatorOID: issue.CreatorOID,
            EditorOID: User.OID,
            CreationTimestamp: Tools.dateTime.toGMTString(issue.CreationTimestamp || new Date()),
            ModificationTimestamp: Tools.dateTime.toGMTString(new Date()),
            RemindingTimestamp: Tools.dateTime.isValidDate(issue.RemindingTimestamp) ? Tools.dateTime.toGMTString(issue.RemindingTimestamp) : null,
            DeadlineTimestamp: Tools.dateTime.isValidDate(issue.DeadlineTimestamp) ? Tools.dateTime.toGMTString(issue.DeadlineTimestamp) : null,
            TerminationTimestamp: Tools.dateTime.isValidDate(issue.TerminationTimestamp) ? Tools.dateTime.toGMTString(issue.TerminationTimestamp) : null,
            StateOID: issue.StateOID,
            PriorityOID: issue.PriorityOID,
            AssignedElementOID: issue.AssignedElementOID,
            AssignedFormOID: issue.AssignedFormOID,
            AssignedMeasureOID: issue.AssignedMeasureOID,
            AssignedSchedulingOID: issue.AssignedSchedulingOID,
            AssignedScheduleOID: issue.AssignedScheduleOID,
            AssignedRecorditemOID: issue.AssignedRecorditemOID,
            LocationMarkers: issue.LocationMarkers,
            ChangeComment: !!changeComment ? Tools.unescapeHtml(changeComment) : null,
            IsArchived: issue.IsArchived,
            IsInvestigationCompleted: issue.IsInvestigationCompleted,
            AdditionalData: issue.AdditionalData,
            Classifications: (issue.Classifications || []).map(function (d) {
                return d.OID;
            }),
            Keywords: (issue.Keywords || []).map(function (d) {
                return d.OID;
            }),
            Files: issue.Files,
            Resubmissionitems: issue.Resubmissionitems,
            Disturbances: (issue.Disturbances || []).map(function (d) {
                return {OID: d.OID, Type: d.Type};
            }),
            Investigationitems: issue.Investigationitems,
            EstimatedEffort: issue.EstimatedEffort,
            ResponsibilityAssignments: issue.ResponsibilityAssignments
        };
    }

    function getRightToEditIssueType(issueType) {
        switch (issueType) {
            case Enums.issueType.Incident:
                return 'TMFT-TASKS';
            case Enums.issueType.Resubmission:
                return 'TMFT-SCHEDULINGS';
            case Enums.issueType.Form:
            case Enums.issueType.Survey:
                return 'TMFT-FORMS';
            case Enums.issueType.Note:
            case Enums.issueType.DisturbancesCompleted:
                return 'TMFT-NOTES';
            case Enums.issueType.Disturbance:
                return 'TMFT-DISTURBANCES';
            case Enums.issueType.Inspection:
                return 'TMFT-INSPECTIONS';
            case Enums.issueType.Investigation:
                return 'TMFT-INVESTIGATIONS';
            default:
                return null;
        }
    }

    function getInvestigationStatusText(investigationStatus) {
        if (isNaN(investigationStatus)) {
            return null;
        }

        switch (investigationStatus) {
            case Enums.Investigations.Status.Open:
                return i18next.t('misc.investigationStatus.open');
            case Enums.Investigations.Status.Finished:
                return i18next.t('misc.investigationStatus.finished');
            case Enums.Investigations.Status.Pulled:
                return i18next.t('misc.investigationStatus.pulled');
            case Enums.Investigations.Status.Unsynced:
                return i18next.t('misc.investigationStatus.unsynced');
            case Enums.Investigations.Status.CancellationRequested:
                return i18next.t('misc.investigationStatus.cancellationRequested');
            case Enums.Investigations.Status.Cancelled:
                return i18next.t('misc.investigationStatus.cancelled');
            case Enums.Investigations.Status.Error:
                return i18next.t('misc.investigationStatus.error');
            default:
                return i18next.t('misc.unknown');
        }
    }

    function getInvestigationErrorText(errorCode) {
        if (isNaN(errorCode)) {
            return null;
        }

        switch (errorCode) {
            case Enums.Investigations.Error.MissingAdditionalProperty:
                return i18next.t('misc.investigationStatus.errorDetails.missingAdditionalProperty');
            case Enums.Investigations.Error.MissingScheduling:
                return i18next.t('misc.investigationStatus.errorDetails.missingScheduling');
            case Enums.Investigations.Error.NoRecipients:
                return i18next.t('misc.investigationStatus.errorDetails.noRecipients');
            case Enums.Investigations.Error.UploadError:
                return i18next.t('misc.investigationStatus.errorDetails.uploadError');
            case Enums.Investigations.Error.DownloadError:
                return i18next.t('misc.investigationStatus.errorDetails.downloadError');
            case Enums.Investigations.Error.NoMailTemplateConfigured:
                return i18next.t('misc.investigationStatus.errorDetails.noMailTemplateConfigured');
            default:
                return i18next.t('misc.unknown');
        }
    }

    tools.issue = {
        prepareIssueForWebApp: prepareIssueForWebApp,
        prepareCommentsForWebApp: prepareComments,
        prepareIssueForWebService: prepareIssueForWebService,
        getRightToEditIssueType: getRightToEditIssueType,
        getInvestigationStatusText: getInvestigationStatusText,
        getInvestigationErrorText: getInvestigationErrorText
    };
})(window.Tools || (window.Tools = {}));
