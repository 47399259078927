/**
 * @require ../tools.js
 */
(function (global) {
    /**
     * @abstract
     * @constructor
     */
    const DataLoaderBase = function () {
        if (this.constructor === DataLoaderBase) {
            throw new Error('This class cannot be instanciated.')
        }
    };

    DataLoaderBase.prototype.constructor = DataLoaderBase;

    /**
     * Lädt die angeforderten Daten vom Server und cached diese.
     */
    DataLoaderBase.prototype.LoadAll = function () {
        const deferred = $.Deferred();

        Tools.http.get(this.Method, data => {
            this.Data = {
                Timestamp: new Date(),
                Data: data,
                DataMap: {}
            };

            if (data instanceof Array) {
                /**
                 * @type {object}
                 */
                this.Data.DataMap = data.reduce(function (map, elem) {
                    map[elem.OID] = elem;

                    return map;
                }, {});

                this.Data.Data = this.Prepare();
            }

            const response = createResponse.call(this, this.Data, true);

            deferred.resolve(response);
        }, xhr => {
            this.Data = {
                Timestamp: new Date(),
                Data: null,
                DataMap: {}
            };

            deferred.reject(xhr);
        });

        return deferred.promise();
    };

    /**
     * Prüft, ob die Daten bereits geladen wurden und greift ggf. auf den eigenen Cache zurück.
     */
    DataLoaderBase.prototype.GetAll = function () {
        const deferred = $.Deferred();

        if (this.Data != null) {
            deferred.resolve(createResponse.call(this, this.Data, false));
        } else {
            this.LoadAll()
                .then(deferred.resolve, deferred.reject);
        }

        return deferred.promise();
    };

    /**
     * Entfernt die gecachten Daten des DataLoaders.
     */
    DataLoaderBase.prototype.Reset = function () {
        delete this.Data;
    };

    /**
     * @param {object} data
     * @param {boolean} loadedFromServer
     * @returns {{Data: Object[], Map: Object, Root: (Object|null), Timestamp: Date, LoadedFromServer: Boolean}|null}
     */
    function createResponse(data, loadedFromServer) {
        if (!data) {
            return null;
        }

        const response = {
            Data: data.Data,
            Map: data.DataMap,
            Timestamp: data.Timestamp,
            LoadedFromServer: loadedFromServer,
            Root: null
        };

        if (data.Root) {
            response.Root = data.Root;
        }

        return response;
    }

    DataLoaderBase.prototype.Prepare = $.noop;

    global.DataLoaderBase = DataLoaderBase;
})(window.Tools || (window.Tools = {}));