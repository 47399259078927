/**
 * @require ./tools.js
 */
(function (tools) {
    var escapeBase = function(getValue) {
        function escapeArray(arr, escapeFunction) {
            if (!arr || !(arr instanceof Array)) {
                return arr;
            }

            var len = arr.length;
            for (var cnt = 0; cnt < len; cnt++) {
                if (escapeFunction instanceof  Function) {
                    escapeFunction(arr[cnt]);
                } else {
                    arr[cnt] = getValue(arr[cnt]);
                }
            }
        }

        function escapeDictionary(dic, escapeFunction) {
            if (!dic) {
                return dic;
            }

            for (var key in dic) {
                if (!dic.hasOwnProperty(key)) {
                    continue;
                }

                if (escapeFunction instanceof  Function) {
                    escapeFunction(dic[key]);
                } else {
                    dic[key] = getValue(dic[key]);
                }
            }

            return dic;
        }

        function escapeClient(client) {
            if (!client) {
                return client;
            }

            client.CompanyName = getValue(client.CompanyName);
            client.Name = getValue(client.Name);

            return client;
        }

        function escapeSchedule(schedule) {
            if (!schedule) {
                return schedule;
            }

            schedule.Title = getValue(schedule.Title);

            return schedule
        }

        function escapeScheduling(scheduling) {
            if (!scheduling) {
                return scheduling;
            }

            scheduling.Title = getValue(scheduling.Title);
            scheduling.Description = getValue(scheduling.Description);

            return scheduling;
        }

        function property(property) {
            if (!property) {
                return property;
            }

            property.Title = getValue(property.Title);
            property.Description = getValue(property.Description);
            property.ActivationDescription = getValue(property.ActivationDescription);
            property.ChangeDescription = getValue(property.ChangeDescription);

            return property;
        }

        function element(element, withLastRecorditem) {
            if (!element) {
                return element;
            }

            element.Title = getValue(element.Title);
            element.Description = getValue(element.Description);
            element.QRCode = getValue(element.QRCode);
            element.CostCenter = getValue(element.CostCenter);
            element.Address = address(element.Address);
            element.GradingScale = gradingScale(element.GradingScale);
            element.Unit = property(element.Unit);

            escapeDictionary(element.Structure);
            escapeArray(element.Properties, property);
            escapeArray(element.AdditionalProperties, additionalProperty);
            escapeArray(element.Actions, workflow);

            if (withLastRecorditem)  {
                recorditem(element.LastRecorditem)
            }

            return element;
        }

        function folder(folder) {
            if (!folder) {
                return folder;
            }

            folder.Title = getValue(folder.Title);

            return folder;
        }

        function file(file) {
            if (!file) {
                return file;
            }

            file.Title = getValue(file.Title);
            file.Description = getValue(file.Description);

            return file;
        }

        function role(role) {
            if (!role) {
                return role;
            }

            role.Title = getValue(role.Title);
            role.Description = getValue(role.Description);

            return role;
        }

        function contact(contact) {
            if (!contact) {
                return contact;
            }

            contact.Firstname = getValue(contact.Firstname);
            contact.Lastname = getValue(contact.Lastname);
            contact.Company = getValue(contact.Company);
            contact.Description = getValue(contact.Description);

            return contact
        }

        function contactGroup(contactGroup) {
            if (!contactGroup) {
                return contactGroup;
            }

            contactGroup.Title = getValue(contactGroup.Title);
            contactGroup.Description = getValue(contactGroup.Description);

            return contactGroup
        }

        function user(user) {
            if (!user) {
                return user;
            }

            user.Username = getValue(user.Username);
            user.Firstname = getValue(user.Firstname);
            user.Lastname = getValue(user.Lastname);
            user.Fullname = getValue(user.Fullname);
            user.Title = getValue(user.Title);

            return user;
        }

        function team(team) {
            if (!team) {
                return team;
            }

            team.Title = getValue(team.Title);
            team.Description = getValue(team.Description);

            return team
        }

        function recorditem(recorditem, withElement, withIssue) {
            if (!recorditem) {
                return recorditem;
            }

            if (recorditem.Value && recorditem.Element &&
                (recorditem.Element.Type === Enums.elementType.Line ||
                    recorditem.Element.Type === Enums.elementType.Memo ||
                    recorditem.Element.Type === Enums.elementType.PhoneNumber ||
                    recorditem.Element.Type === Enums.elementType.Scancode)) {
                recorditem.Value = getValue(recorditem.Value);
            }

            escapeArray(recorditem.Comments, comment);
            escapeDictionary(recorditem.Workflows, workflow);

            if (withElement) {
                element(recorditem.Element);
            }

            if (withIssue) {
                issue(recorditem.Issue);
            }

            recorditem.WorkflowInformation = getValue(recorditem.WorkflowInformation);

            return recorditem;
        }

        function issue(issue) {
            if (!issue) {
                return issue;
            }

            issue.CustomID = getValue(issue.CustomID);
            issue.Title = getValue(issue.Title);

            if (!(issue.IsTemplate || !!issue.TemplateID)) {
                issue.Description = getValue(issue.Description);
            }

            escapeArray(issue.Comments, comment);

            return issue;
        }

        function additionalProperty(additionalProp) {
            if (!additionalProp) {
                return additionalProp;
            }

            additionalProp.Title = getValue(additionalProp.Title);

            if (additionalProp.Value && (
                additionalProp.Type === Enums.additionalPropertyType.Text ||
                additionalProp.Type === Enums.additionalPropertyType.Info ||
                additionalProp.Type === Enums.additionalPropertyType.PhoneNumber)) {
                additionalProp.Value = getValue(additionalProp.Value);
            }

            return additionalProp;
        }

        function workflow(workflow) {
            if (!workflow) {
                return workflow;
            }

            if (workflow.Title) {
                workflow.Title = getValue(workflow.Title);
            }

            if (workflow.Text) {
                workflow.Text = getValue(workflow.Text);
            }

            return workflow;
        }

        function comment(comment) {
            if (!comment) {
                return comment;
            }

            comment.Text = getValue(comment.Text);

            return comment;
        }

        function address(address) {
            if (!address) {
                return address;
            }

            address.City = getValue(address.City);
            address.ZipCode = getValue(address.ZipCode);
            address.Name = getValue(address.Name);
            address.Phone = getValue(address.Phone);
            address.Street = getValue(address.Street);
            address.WebAddress = getValue(address.WebAddress);

            return address;
        }

        function customMenuItem(menuItem) {
            if (!menuItem) {
                return menuItem;
            }

            menuItem.Title = getValue(menuItem.Title);
            menuItem.Description = getValue(menuItem.Description);

            return menuItem;
        }

        function mailTemplate(mailTemplate) {
            if (!mailTemplate) {
                return mailTemplate;
            }

            mailTemplate.Title = getValue(mailTemplate.Title);
            mailTemplate.Description = getValue(mailTemplate.Description);
            mailTemplate.Subject = getValue(mailTemplate.Subject);

            return mailTemplate;
        }

        function elementCoding(elementCoding) {
            if (!elementCoding) {
                return elementCoding;
            }

            elementCoding.Prefix = getValue(elementCoding.Prefix);
            elementCoding.Description = getValue(elementCoding.Description);

            return elementCoding;
        }

        function gradingScale(scale) {
            if (!scale) {
                return scale;
            }

            escapeArray(scale.Gradings, grading);

            return scale;
        }

        function grading(grad) {
            if (!grad) {
                return grad;
            }

            grad.Text = getValue(grad.Text);

            return grad;
        }

        function excelImportElementItem(item) {
            if (!item) {
                return item;
            }

            item.Title = Tools.escapeHtml(item.Title);
            item.GroupTitle = Tools.escapeHtml(item.GroupTitle);
            item.GroupDescription = Tools.escapeHtml(item.GroupDescription);
            item.ParameterTitle = Tools.escapeHtml(item.ParameterTitle);
            item.ParameterDescription = Tools.escapeHtml(item.ParameterDescription);
            item.Keywords = Tools.escapeHtml(item.Keywords);
            item.PropertyTitle = Tools.escapeHtml(item.PropertyTitle);

            return item;
        }

        function excelImportUserItem(item) {
            if (!item) {
                return item;
            }

            item.Team = Tools.escapeHtml(item.Team);
            item.OrganisationUnit = Tools.escapeHtml(item.OrganisationUnit);
            item.Role = Tools.escapeHtml(item.Role);
            item.LicenseType = Tools.escapeHtml(item.LicenseType);
            item.FirstName = Tools.escapeHtml(item.FirstName);
            item.LastName = Tools.escapeHtml(item.LastName);
            item.EmailAddress = Tools.escapeHtml(item.EmailAddress);

            return item;
        }

        function individualDataSchema(schema) {
            if (!schema) {
                return schema;
            }

            schema.NameSingular = Tools.escapeHtml(schema.NameSingular);
            schema.NamePlural = Tools.escapeHtml(schema.NamePlural);

            return schema;
        }

        function individualDataEntity(entity, schema) {
            if (!entity || !schema || !schema.Properties) {
                return entity;
            }

            var len = schema.Properties.length;
            var property;
            for (var cnt = 0; cnt < len; cnt++) {
                property = schema.Properties[cnt];

                if (!property.Name || property.Type !== Enums.IndividualdataSchemaPropertyType.Text) {
                    continue;
                }

                entity[property.Name] = Tools.escapeHtml(entity[property.Name]);
            }

            return entity;
        }

        function analysisQuery(query) {
            if (!query) {
                return query;
            }

            query.Title = getValue(query.Title);

            if (query.Description) {
                query.Description = getValue(query.Description);
            }

            return query;
        }

        return {
            Client: escapeClient,
            Schedule: escapeSchedule,
            Scheduling: escapeScheduling,
            Property: property,
            Element: element,
            Folder: folder,
            File: file,
            Role: role,
            Contact: contact,
            ContactGroup: contactGroup,
            User: user,
            Team: team,
            Recorditem: recorditem,
            Issue: issue,
            Comment: comment,
            Workflow: workflow,
            Address: address,
            CustomMenuItem: customMenuItem,
            MailTemplate: mailTemplate,
            ElementCoding: elementCoding,
            GradingScale: gradingScale,
            ExcelImportElementItem: excelImportElementItem,
            ExcelImportUserItem: excelImportUserItem,
            AdditionalProperty: additionalProperty,
            IndividualDataSchema: individualDataSchema,
            IndividualDataEntity: individualDataEntity,
            AnalysisQuery: analysisQuery
        };
    };

    tools.Escape = (function() {
        function getEscapedValue(val) {
            if (!val) {
                return val;
            }

            return Tools.escapeHtml(val);
        }

        return escapeBase(getEscapedValue);
    })();

    tools.UnEscape = (function() {
        function getUnEscapedValue(val) {
            if (!val) {
                return val;
            }

            return Tools.unescapeHtml(val);
        }

        return escapeBase(getUnEscapedValue);
    })();
})(window.Tools || (window.Tools = {}));