/**
 * @require ./tools.js
 */
(function (tools) {
    var Evaluation = {};
    var Linking = { And: 0, Or: 1 };
    var Operators = { eq: 0, ne: 1, lt: 2, le: 3, gt: 4, ge: 5 };

    var _recorditem, _element, _evaluations;

    function evaluate(recorditem, element) {
        if (!recorditem && !element) {
            return null;
        }

        _recorditem = recorditem;
        _element = element || recorditem.Element;

        if (!_element) {
            return null;
        }

        _evaluations = (_element.Evaluation || []).filter(function (e) {
            return e.Type === _element.Type;
        });

        if (!_evaluations.length) {
            return null;
        }

        switch (_element.Type) {
            case Enums.elementType.Checkbox:
                return evaluateBool();
            case Enums.elementType.Number:
                return evaluateNumeric();
            case Enums.elementType.Date:
                return evaluateDate();
            case Enums.elementType.Time:
                return evaluateTime();
            case Enums.elementType.ListBox:
                return evaluateListBox();
            default:
                return evaluateDefault();
        }
    }

    function evaluateBool() {
        var recorditemValue, i, len, evaluation;

        recorditemValue = (_recorditem || {}).Value;

        for (i = 0, len = _evaluations.length; i < len; i++) {
            evaluation = _evaluations[i];

            if (evaluation.Value === 1 && recorditemValue === true ||
                evaluation.Value === 2 && recorditemValue === false ||
                evaluation.Value === 3 && recorditemValue === null ||
                evaluation.Value === 3 && typeof recorditemValue === 'undefined') {
                return evaluation.CategoryOID;
            }
        }

        return null;
    }

    function evaluateNumeric() {
        var recorditemValue, i, len, evaluation, firstValue, secondValue;

        function compareNumbers(operator, firstValue, secondValue) {
            switch (operator) {
                case Operators.eq:
                    return firstValue === secondValue;
                case Operators.ne:
                    return firstValue !== secondValue;
                case Operators.lt:
                    return firstValue < secondValue;
                case Operators.le:
                    return firstValue <= secondValue;
                case Operators.gt:
                    return firstValue > secondValue;
                case Operators.ge:
                    return firstValue >= secondValue;
                default:
                    return true;
            }
        }

        if (!_recorditem) {
            return null;
        }

        recorditemValue = parseFloat(_recorditem.Value);

        if (isNaN(recorditemValue)) {
            return null;
        }

        for (i = 0, len = _evaluations.length; i < len; i++) {
            evaluation = _evaluations[i];
            firstValue = parseFloat(evaluation.FirstValue);
            secondValue = parseFloat(evaluation.SecondValue);

            switch (evaluation.Linking) {
                case Linking.And:
                    if (compareNumbers(evaluation.FirstOperator, recorditemValue, firstValue) &&
                        compareNumbers(evaluation.SecondOperator, recorditemValue, secondValue)) {
                        return evaluation.CategoryOID;
                    }
                    break;
                case Linking.Or:
                    if (compareNumbers(evaluation.FirstOperator, recorditemValue, firstValue) ||
                        compareNumbers(evaluation.SecondOperator, recorditemValue, secondValue)) {
                        return evaluation.CategoryOID;
                    }
                    break;
                default:
                    if (compareNumbers(evaluation.FirstOperator, recorditemValue, firstValue)) {
                        return evaluation.CategoryOID;
                    }
                    break;
            }
        }

        return null;
    }

    function evaluateDate() {
        var recorditemValue, modificationTimestamp, modificationDate,
            recorditemDate, i, len, evaluation, valueType,
            firstOperator, secondOperator, firstValue, secondValue;

        function addDays(date, days) {
            var newDate = new Date(date);

            newDate.setDate(date.getDate() + days);

            return newDate;
        }

        function addMonths(date, months) {
            var newDate = new Date(date);

            newDate.setMonth(date.getMonth() + months);

            return newDate;
        }

        function addYears(date, years) {
            var newDate = new Date(date);

            newDate.setFullYear(date.getFullYear() + years);

            return newDate;
        }

        if (!_recorditem) {
            return null;
        }

        recorditemValue = new Date(_recorditem.Value);
        modificationTimestamp = new Date(_recorditem.ModificationTimestamp);

        if (isNaN(recorditemValue)) {
            return null;
        }

        modificationDate = new Date(modificationTimestamp.getFullYear(), modificationTimestamp.getMonth(), modificationTimestamp.getDate());
        recorditemDate = new Date(recorditemValue.getFullYear(), recorditemValue.getMonth(), recorditemValue.getDate());

        for (i = 0, len = _evaluations.length; i < len; i++) {
            evaluation = _evaluations[i];
            valueType = evaluation.ValueType;
            firstOperator = evaluation.FirstOperator;
            secondOperator = evaluation.SecondOperator;
            firstValue = parseInt(evaluation.FirstValue, 10);
            secondValue = parseInt(evaluation.SecondValue, 10);

            switch (firstOperator) {
                case Operators.eq:
                    if (valueType === 0 && addDays(recorditemDate, firstValue).getTime() === modificationDate.getTime() ||
                        valueType === 1 && addMonths(recorditemDate, firstValue).getTime() === modificationDate.getTime() ||
                        valueType === 2 && addYears(recorditemDate, firstValue).getTime() === modificationDate.getTime()) {
                        return evaluation.CategoryOID;
                    }
                    break;
                case Operators.ne:
                    if (valueType === 0 && addDays(recorditemDate, firstValue).getTime() !== modificationDate.getTime() ||
                        valueType === 1 && addMonths(recorditemDate, firstValue).getTime() !== modificationDate.getTime() ||
                        valueType === 2 && addYears(recorditemDate, firstValue).getTime() !== modificationDate.getTime()) {
                        return evaluation.CategoryOID;
                    }
                    break;
                case Operators.lt:
                    if (valueType === 0 && addDays(recorditemDate, firstValue).getTime() > modificationDate.getTime() ||
                        valueType === 1 && addMonths(recorditemDate, firstValue).getTime() > modificationDate.getTime() ||
                        valueType === 2 && addYears(recorditemDate, firstValue).getTime() > modificationDate.getTime()) {
                        if (secondOperator === 4 && secondValue < firstValue) {
                            if (valueType === 0 && addDays(recorditemDate, secondValue).getTime() < modificationDate.getTime() ||
                                valueType === 1 && addMonths(recorditemDate, secondValue).getTime() < modificationDate.getTime() ||
                                valueType === 2 && addYears(recorditemDate, secondValue).getTime() < modificationDate.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else if (secondOperator === 5 && secondValue < firstValue) {
                            if (valueType === 0 && addDays(recorditemDate, secondValue).getTime() <= modificationDate.getTime() ||
                                valueType === 1 && addMonths(recorditemDate, secondValue).getTime() <= modificationDate.getTime() ||
                                valueType === 2 && addYears(recorditemDate, secondValue).getTime() <= modificationDate.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else {
                            return evaluation.CategoryOID;
                        }
                    }
                    break;
                case Operators.le:
                    if (valueType === 0 && addDays(recorditemDate, firstValue).getTime() >= modificationDate.getTime() ||
                        valueType === 1 && addMonths(recorditemDate, firstValue).getTime() >= modificationDate.getTime() ||
                        valueType === 2 && addYears(recorditemDate, firstValue).getTime() >= modificationDate.getTime()) {
                        if (secondOperator === 4 && secondValue < firstValue) {
                            if (valueType === 0 && addDays(recorditemDate, secondValue).getTime() < modificationDate.getTime() ||
                                valueType === 1 && addMonths(recorditemDate, secondValue).getTime() < modificationDate.getTime() ||
                                valueType === 2 && addYears(recorditemDate, secondValue).getTime() < modificationDate.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else if (secondOperator === 5 && secondValue < firstValue) {
                            if (valueType === 0 && addDays(recorditemDate, secondValue).getTime() <= modificationDate.getTime() ||
                                valueType === 1 && addMonths(recorditemDate, secondValue).getTime() <= modificationDate.getTime() ||
                                valueType === 2 && addYears(recorditemDate, secondValue).getTime() <= modificationDate.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else {
                            return evaluation.CategoryOID;
                        }
                    }
                    break;
                case Operators.gt:
                    if (valueType === 0 && addDays(recorditemDate, firstValue).getTime() < modificationDate.getTime() ||
                        valueType === 1 && addMonths(recorditemDate, firstValue).getTime() < modificationDate.getTime() ||
                        valueType === 2 && addYears(recorditemDate, firstValue).getTime() < modificationDate.getTime()) {
                        if (secondOperator === 2 && secondValue > firstValue) {
                            if (valueType === 0 && addDays(recorditemDate, secondValue).getTime() > modificationDate.getTime() ||
                                valueType === 1 && addMonths(recorditemDate, secondValue).getTime() > modificationDate.getTime() ||
                                valueType === 2 && addYears(recorditemDate, secondValue).getTime() > modificationDate.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else if (secondOperator === 3 && secondValue > firstValue) {
                            if (valueType === 0 && addDays(recorditemDate, secondValue).getTime() >= modificationDate.getTime() ||
                                valueType === 1 && addMonths(recorditemDate, secondValue).getTime() >= modificationDate.getTime() ||
                                valueType === 2 && addYears(recorditemDate, secondValue).getTime() >= modificationDate.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else {
                            return evaluation.CategoryOID;
                        }
                    }
                    break;
                case Operators.ge:
                    if (valueType === 0 && addDays(recorditemDate, firstValue).getTime() <= modificationDate.getTime() ||
                        valueType === 1 && addMonths(recorditemDate, firstValue).getTime() <= modificationDate.getTime() ||
                        valueType === 2 && addYears(recorditemDate, firstValue).getTime() <= modificationDate.getTime()) {
                        if (secondOperator === 2 && secondValue > firstValue) {
                            if (valueType === 0 && addDays(recorditemDate, secondValue).getTime() > modificationDate.getTime() ||
                                valueType === 1 && addMonths(recorditemDate, secondValue).getTime() > modificationDate.getTime() ||
                                valueType === 2 && addYears(recorditemDate, secondValue).getTime() > modificationDate.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else if (secondOperator === 3 && secondValue > firstValue) {
                            if (valueType === 0 && addDays(recorditemDate, secondValue).getTime() >= modificationDate.getTime() ||
                                valueType === 1 && addMonths(recorditemDate, secondValue).getTime() >= modificationDate.getTime() ||
                                valueType === 2 && addYears(recorditemDate, secondValue).getTime() >= modificationDate.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else {
                            return evaluation.CategoryOID;
                        }
                    }
                    break;
            }

            if ((secondOperator === Operators.lt || secondOperator === Operators.le) && secondValue <= firstValue ||
                (secondOperator === Operators.gt || secondOperator === Operators.ge) && secondValue >= firstValue) {
                switch (secondOperator) {
                    case Operators.lt:
                        if (valueType === 0 && addDays(recorditemDate, secondValue).getTime() > modificationDate.getTime() ||
                            valueType === 1 && addMonths(recorditemDate, secondValue).getTime() > modificationDate.getTime() ||
                            valueType === 2 && addYears(recorditemDate, secondValue).getTime() > modificationDate.getTime()) {
                            return evaluation.CategoryOID;
                        }
                        break;
                    case Operators.le:
                        if (valueType === 0 && addDays(recorditemDate, secondValue).getTime() >= modificationDate.getTime() ||
                            valueType === 1 && addMonths(recorditemDate, secondValue).getTime() >= modificationDate.getTime() ||
                            valueType === 2 && addYears(recorditemDate, secondValue).getTime() >= modificationDate.getTime()) {
                            return evaluation.CategoryOID;
                        }
                        break;
                    case Operators.gt:
                        if (valueType === 0 && addDays(recorditemDate, secondValue).getTime() < modificationDate.getTime() ||
                            valueType === 1 && addMonths(recorditemDate, secondValue).getTime() < modificationDate.getTime() ||
                            valueType === 2 && addYears(recorditemDate, secondValue).getTime() < modificationDate.getTime()) {
                            return evaluation.CategoryOID;
                        }
                        break;
                    case Operators.ge:
                        if (valueType === 0 && addDays(recorditemDate, secondValue).getTime() <= modificationDate.getTime() ||
                            valueType === 1 && addMonths(recorditemDate, secondValue).getTime() <= modificationDate.getTime() ||
                            valueType === 2 && addYears(recorditemDate, secondValue).getTime() <= modificationDate.getTime()) {
                            return evaluation.CategoryOID;
                        }
                        break;
                }
            }
        }

        return null;
    }

    function evaluateTime() {
        var recorditemValue, modificationTimestamp, recorditemTimestamp,
            i, len, evaluation, valueType, firstOperator, secondOperator,
            firstValue, secondValue;

        function addSeconds(date, seconds) {
            var newDate = new Date(date);

            newDate.setSeconds(date.getSeconds() + seconds);

            return newDate;
        }

        function addMinutes(date, minutes) {
            var newDate = new Date(date);

            newDate.setMinutes(date.getMinutes() + minutes);

            return newDate;
        }

        function addHours(date, hours) {
            var newDate = new Date(date);

            newDate.setHours(date.getHours() + hours);

            return newDate;
        }

        if (!_recorditem) {
            return null;
        }

        recorditemValue = new Date(_recorditem.Value);
        modificationTimestamp = new Date(_recorditem.ModificationTimestamp);

        if (isNaN(recorditemValue)) {
            return null;
        }

        recorditemTimestamp = new Date(modificationTimestamp);
        recorditemTimestamp.setHours(recorditemValue.getHours());
        recorditemTimestamp.setMinutes(recorditemValue.getMinutes());

        for (i = 0, len = _evaluations.length; i < len; i++) {
            evaluation = _evaluations[i];
            valueType = evaluation.ValueType;
            firstOperator = evaluation.FirstOperator;
            secondOperator = evaluation.SecondOperator;
            firstValue = parseInt(evaluation.FirstValue, 10);
            secondValue = parseInt(evaluation.SecondValue, 10);

            switch (firstOperator) {
                case Operators.eq:
                    if (valueType === 0 && addSeconds(recorditemTimestamp, firstValue).getTime() === modificationTimestamp.getTime() ||
                        valueType === 1 && addMinutes(recorditemTimestamp, firstValue).getTime() === modificationTimestamp.getTime() ||
                        valueType === 2 && addHours(recorditemTimestamp, firstValue).getTime() === modificationTimestamp.getTime()) {
                        return evaluation.CategoryOID;
                    }
                    break;
                case Operators.ne:
                    if (valueType === 0 && addSeconds(recorditemTimestamp, firstValue).getTime() !== modificationTimestamp.getTime() ||
                        valueType === 1 && addMinutes(recorditemTimestamp, firstValue).getTime() !== modificationTimestamp.getTime() ||
                        valueType === 2 && addHours(recorditemTimestamp, firstValue).getTime() !== modificationTimestamp.getTime()) {
                        return evaluation.CategoryOID;
                    }
                    break;
                case Operators.lt:
                    if (valueType === 0 && addSeconds(recorditemTimestamp, firstValue).getTime() > modificationTimestamp.getTime() ||
                        valueType === 1 && addMinutes(recorditemTimestamp, firstValue).getTime() > modificationTimestamp.getTime() ||
                        valueType === 2 && addHours(recorditemTimestamp, firstValue).getTime() > modificationTimestamp.getTime()) {
                        if (secondOperator === 4 && secondValue < firstValue) {
                            if (valueType === 0 && addSeconds(recorditemTimestamp, secondValue).getTime() < modificationTimestamp.getTime() ||
                                valueType === 1 && addMinutes(recorditemTimestamp, secondValue).getTime() < modificationTimestamp.getTime() ||
                                valueType === 2 && addHours(recorditemTimestamp, secondValue).getTime() < modificationTimestamp.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else if (secondOperator === 5 && secondValue < firstValue) {
                            if (valueType === 0 && addSeconds(recorditemTimestamp, secondValue).getTime() <= modificationTimestamp.getTime() ||
                                valueType === 1 && addMinutes(recorditemTimestamp, secondValue).getTime() <= modificationTimestamp.getTime() ||
                                valueType === 2 && addHours(recorditemTimestamp, secondValue).getTime() <= modificationTimestamp.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else {
                            return evaluation.CategoryOID;
                        }
                    }
                    break;
                case Operators.le:
                    if (valueType === 0 && addSeconds(recorditemTimestamp, firstValue).getTime() >= modificationTimestamp.getTime() ||
                        valueType === 1 && addMinutes(recorditemTimestamp, firstValue).getTime() >= modificationTimestamp.getTime() ||
                        valueType === 2 && addHours(recorditemTimestamp, firstValue).getTime() >= modificationTimestamp.getTime()) {
                        if (secondOperator === 4 && secondValue < firstValue) {
                            if (valueType === 0 && addSeconds(recorditemTimestamp, secondValue).getTime() < modificationTimestamp.getTime() ||
                                valueType === 1 && addMinutes(recorditemTimestamp, secondValue).getTime() < modificationTimestamp.getTime() ||
                                valueType === 2 && addHours(recorditemTimestamp, secondValue).getTime() < modificationTimestamp.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else if (secondOperator === 5 && secondValue < firstValue) {
                            if (valueType === 0 && addSeconds(recorditemTimestamp, secondValue).getTime() <= modificationTimestamp.getTime() ||
                                valueType === 1 && addMinutes(recorditemTimestamp, secondValue).getTime() <= modificationTimestamp.getTime() ||
                                valueType === 2 && addHours(recorditemTimestamp, secondValue).getTime() <= modificationTimestamp.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else {
                            return evaluation.CategoryOID;
                        }
                    }
                    break;
                case Operators.gt:
                    if (valueType === 0 && addSeconds(recorditemTimestamp, firstValue).getTime() < modificationTimestamp.getTime() ||
                        valueType === 1 && addMinutes(recorditemTimestamp, firstValue).getTime() < modificationTimestamp.getTime() ||
                        valueType === 2 && addHours(recorditemTimestamp, firstValue).getTime() < modificationTimestamp.getTime()) {
                        if (secondOperator === 2 && secondValue > firstValue) {
                            if (valueType === 0 && addSeconds(recorditemTimestamp, secondValue).getTime() > modificationTimestamp.getTime() ||
                                valueType === 1 && addMinutes(recorditemTimestamp, secondValue).getTime() > modificationTimestamp.getTime() ||
                                valueType === 2 && addHours(recorditemTimestamp, secondValue).getTime() > modificationTimestamp.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else if (secondOperator === 3 && secondValue > firstValue) {
                            if (valueType === 0 && addSeconds(recorditemTimestamp, secondValue).getTime() >= modificationTimestamp.getTime() ||
                                valueType === 1 && addMinutes(recorditemTimestamp, secondValue).getTime() >= modificationTimestamp.getTime() ||
                                valueType === 2 && addHours(recorditemTimestamp, secondValue).getTime() >= modificationTimestamp.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else {
                            return evaluation.CategoryOID;
                        }
                    }
                    break;
                case Operators.ge:
                    if (valueType === 0 && addSeconds(recorditemTimestamp, firstValue).getTime() <= modificationTimestamp.getTime() ||
                        valueType === 1 && addMinutes(recorditemTimestamp, firstValue).getTime() <= modificationTimestamp.getTime() ||
                        valueType === 2 && addHours(recorditemTimestamp, firstValue).getTime() <= modificationTimestamp.getTime()) {
                        if (secondOperator === 2 && secondValue > firstValue) {
                            if (valueType === 0 && addSeconds(recorditemTimestamp, secondValue).getTime() > modificationTimestamp.getTime() ||
                                valueType === 1 && addMinutes(recorditemTimestamp, secondValue).getTime() > modificationTimestamp.getTime() ||
                                valueType === 2 && addHours(recorditemTimestamp, secondValue).getTime() > modificationTimestamp.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else if (secondOperator === 3 && secondValue > firstValue) {
                            if (valueType === 0 && addSeconds(recorditemTimestamp, secondValue).getTime() >= modificationTimestamp.getTime() ||
                                valueType === 1 && addMinutes(recorditemTimestamp, secondValue).getTime() >= modificationTimestamp.getTime() ||
                                valueType === 2 && addHours(recorditemTimestamp, secondValue).getTime() >= modificationTimestamp.getTime()) {
                                return evaluation.CategoryOID;
                            }
                        } else {
                            return evaluation.CategoryOID;
                        }
                    }
                    break;
            }

            if ((secondOperator === Operators.lt || secondOperator === Operators.le) && secondValue <= firstValue ||
                (secondOperator === Operators.gt || secondOperator === Operators.ge) && secondValue >= firstValue) {
                switch (secondOperator) {
                    case Operators.lt:
                        if (valueType === 0 && addSeconds(recorditemTimestamp, secondValue).getTime() > modificationTimestamp.getTime() ||
                            valueType === 1 && addMinutes(recorditemTimestamp, secondValue).getTime() > modificationTimestamp.getTime() ||
                            valueType === 2 && addHours(recorditemTimestamp, secondValue).getTime() > modificationTimestamp.getTime()) {
                            return evaluation.CategoryOID;
                        }
                        break;
                    case Operators.le:
                        if (valueType === 0 && addSeconds(recorditemTimestamp, secondValue).getTime() >= modificationTimestamp.getTime() ||
                            valueType === 1 && addMinutes(recorditemTimestamp, secondValue).getTime() >= modificationTimestamp.getTime() ||
                            valueType === 2 && addHours(recorditemTimestamp, secondValue).getTime() >= modificationTimestamp.getTime()) {
                            return evaluation.CategoryOID;
                        }
                        break;
                    case Operators.gt:
                        if (valueType === 0 && addSeconds(recorditemTimestamp, secondValue).getTime() < modificationTimestamp.getTime() ||
                            valueType === 1 && addMinutes(recorditemTimestamp, secondValue).getTime() < modificationTimestamp.getTime() ||
                            valueType === 2 && addHours(recorditemTimestamp, secondValue).getTime() < modificationTimestamp.getTime()) {
                            return evaluation.CategoryOID;
                        }
                        break;
                    case Operators.ge:
                        if (valueType === 0 && addSeconds(recorditemTimestamp, secondValue).getTime() <= modificationTimestamp.getTime() ||
                            valueType === 1 && addMinutes(recorditemTimestamp, secondValue).getTime() <= modificationTimestamp.getTime() ||
                            valueType === 2 && addHours(recorditemTimestamp, secondValue).getTime() <= modificationTimestamp.getTime()) {
                            return evaluation.CategoryOID;
                        }
                        break;
                }
            }
        }

        return null;
    }

    function evaluateListBox() {
        var recorditemValue, i, len, evaluation, evaluationValue;

        if (!_recorditem) {
            return null;
        }

        recorditemValue = parseInt(_recorditem.Value, 10);

        for (i = 0, len = _evaluations.length; i < len; i++) {
            evaluation = _evaluations[i];
            evaluationValue = parseInt(evaluation.Value, 10);

            if (recorditemValue === evaluationValue) {
                return evaluation.CategoryOID;
            }
        }

        return null;
    }

    function evaluateDefault() {
        var recorditemValue, i, len, evaluation, evaluationValue;

        recorditemValue = _recorditem && _recorditem.Value ? _recorditem.Value : '';

        for (i = 0, len = _evaluations.length; i < len; i++) {
            evaluation = _evaluations[i];
            evaluationValue = parseInt(evaluation.Value, 10);

            if (evaluationValue === 0 && recorditemValue ||
                evaluationValue === 1 && !recorditemValue) {
                return evaluation.CategoryOID;
            }
        }

        return null;
    }

    Evaluation.evaluate = evaluate;

    tools.evaluation = {
        evaluate: evaluate
    };
})(window.Tools || (window.Tools = {}));