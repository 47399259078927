/**
 * @require ./tools.js
 */
(function (tools) {
    var StartTools = {};

    StartTools.GetUserTeamIdent = function (userIdentifier) {
        return Object.keys(changemode.Teams)
            .filter(function (teamIdent) {
                var team = changemode.Teams[teamIdent];

                if (!(team.Users || []).length) {
                    return false;
                }

                return team.Users.some(function (teamUser) {
                    return teamUser.OID === userIdentifier;
                });
            })[0];
    };

    StartTools.GetUserTeam = function (userIdentifier) {
        var teamIdentifier = StartTools.GetUserTeamIdent(userIdentifier);

        if (!teamIdentifier) {
            return null;
        }

        var team = changemode.Teams[teamIdentifier];

        return team == null || team.ModificationType === Enums.ModificationType.Deleted ? null : team;
    };

    StartTools.GetTeamLocations = function (teamIdentifier) {
        if (!teamIdentifier) {
            return null;
        }

        var teamLocations = [];

        (function walk(location) {
            if (location.Type > 1) {
                return;
            }

            if ((location.Teams || []).length) {
                var isAssigned = location.Teams.some(function (assignedTeam) {
                    return assignedTeam.OID === teamIdentifier;
                });

                if (isAssigned) {
                    teamLocations.push(location.OID);
                }
            }

            (location.Children || []).forEach(walk);
        })(changemode.Root);

        return teamLocations;
    };

    tools.AwenkoStart = StartTools;
})(window.Tools || (window.Tools = {}));