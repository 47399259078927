/**
 * @require ./tools.js
 */
(function (global) {
    var Sessions = {};

    var _hasActiveSession;

    Sessions.setSessionActive = function (isActive) {
        _hasActiveSession = isActive;
    };

    Sessions.hasActiveSession = function () {
        return _hasActiveSession === true;
    };

    Sessions.getActiveUserIdentifier = function () {
        var activeSessionUserOID = Tools.Cookies.get(Config.CookieConfig.SessionUserCookieName);

        return activeSessionUserOID && activeSessionUserOID.length ? activeSessionUserOID : null;
    };

    return (global.Sessions = Sessions);
})(window.Tools || (window.Tools = {}));