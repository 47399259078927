/**
 * @require ./tools.js
 */
(function (tools) {
    var DateTime = {};

    var daysOfWeek = {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6
    };

    DateTime.compare = function (dateA, dateB) {
        if (dateA === dateB) {
            return 0;
        }

        if (!dateA && dateB) {
            return 1;
        }

        if (dateA && !dateB) {
            return -1;
        }

        if (typeof dateA === 'string') {
            dateA = new Date(dateA);
        }

        if (typeof dateB === 'string') {
            dateB = new Date(dateB);
        }

        return dateA.getTime() - dateB.getTime();
    };

    DateTime.setDate = function (date, day, month, year) {
        if (!(date instanceof Date) || !day || !month || !year) {
            return null;
        }

        return new Date(
            year,
            month - 1,
            day,
            date.getHours(),
            date.getMinutes(),
            date.getSeconds());
    };

    DateTime.getDateFromObject = function (date) {
        if (date instanceof Date || !isNaN((date = new Date(date)).getTime())) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        }

        return null;
    };

    DateTime.getTimeFromObject = function (date) {
        if (date instanceof Date || !isNaN((date = new Date(date)).getTime())) {
            return new Date(0, 0, 0, date.getHours(), date.getMinutes(), date.getSeconds());
        }

        return null;
    };

    DateTime.getDifferenceString = function (datetime) {
        if (typeof datetime === 'string') {
            datetime = new Date(datetime);
        }

        var now = new Date();
        var differenceInMinutes = (datetime.getTime() - now.getTime()) / 60000;
        var year = now.getFullYear();
        var month = now.getMonth();
        var day = now.getDate();

        if (datetime > new Date(year, month, day + 2)) {
            return Tools.dateTime.dateTimeToString(datetime);
        }

        if (datetime >= new Date(year, month, day + 1)) {
            return i18next.t('dateTime.tomorrowAt', { time: DateTime.timeToString(datetime) });
        }

        if (differenceInMinutes > 59) {
            return i18next.t('dateTime.todayAt', { time: DateTime.timeToString(datetime) });
        }

        if (differenceInMinutes > 2) {
            return i18next.t('dateTime.inMinutes', { minutes: differenceInMinutes + 1 | 0 });
        }

        if (differenceInMinutes > 1) {
            return i18next.t('dateTime.inOneMinute');
        }

        if (differenceInMinutes > 0) {
            return i18next.t('dateTime.inAFewSeconds');
        }

        if (differenceInMinutes > -1) {
            return i18next.t('dateTime.fewSecondsAgo');
        }

        if (differenceInMinutes > -2) {
            return i18next.t('dateTime.oneMinuteAgo');
        }

        if (differenceInMinutes > -60) {
            return i18next.t('dateTime.minutesAgo', { minutes: -differenceInMinutes | 0 });
        }

        if (datetime >= new Date(year, month, day)) {
            return i18next.t('dateTime.todayAt', { time: DateTime.timeToString(datetime) });
        }

        if (datetime > new Date(year, month, day - 1)) {
            return i18next.t('dateTime.yesterdayAt', { time: DateTime.timeToString(datetime) });
        }

        return DateTime.dateTimeToString(datetime);
    };

    DateTime.dateToString = function (datetime) {
        var year = datetime.getFullYear().toString();
        var month = (datetime.getMonth() + 1).toString().padleft('0', 2);
        var day = datetime.getDate().toString().padleft('0', 2);
        var dayOfWeek = datetime.getDay();
        var dayOfWeekName;

        switch (dayOfWeek) {
            case daysOfWeek.Sunday:
                dayOfWeekName = i18next.t('weekdays.sunday_abbreviation');
                break;
            case daysOfWeek.Monday:
                dayOfWeekName = i18next.t('weekdays.monday_abbreviation');
                break;
            case daysOfWeek.Tuesday:
                dayOfWeekName = i18next.t('weekdays.tuesday_abbreviation');
                break;
            case daysOfWeek.Wednesday:
                dayOfWeekName = i18next.t('weekdays.wednesday_abbreviation');
                break;
            case daysOfWeek.Thursday:
                dayOfWeekName = i18next.t('weekdays.thursday_abbreviation');
                break;
            case daysOfWeek.Friday:
                dayOfWeekName = i18next.t('weekdays.friday_abbreviation');
                break;
            case daysOfWeek.Saturday:
                dayOfWeekName = i18next.t('weekdays.saturday_abbreviation');
                break;
        }

        return i18next.t('dateTime.dateFormat', {
            dayOfWeek: dayOfWeekName,
            day: day,
            month: month,
            year: year
        });
    };

    DateTime.weekdayToOnWeekdayText = function (day) {
        var weekdayText = i18next.t('changeMode.scheduleWindow.on') + " ";

        switch (day) {
            case Enums.Weekdays.Monday:
                weekdayText += i18next.t('weekdays.monday');
                break;
            case Enums.Weekdays.Tuesday:
                weekdayText += i18next.t('weekdays.tuesday');
                break;
            case Enums.Weekdays.Wednesday:
                weekdayText += i18next.t('weekdays.wednesday');
                break;
            case Enums.Weekdays.Thursday:
                weekdayText += i18next.t('weekdays.thursday');
                break;
            case Enums.Weekdays.Friday:
                weekdayText += i18next.t('weekdays.friday');
                break;
            case Enums.Weekdays.Saturday:
                weekdayText += i18next.t('weekdays.saturday');
                break;
            default:
                weekdayText += i18next.t('weekdays.sunday');
        }

        return weekdayText;
    }

    DateTime.timeToString = function (datetime) {
        var hour = datetime.getHours().toString().padleft('0', 2);
        var minute = datetime.getMinutes().toString().padleft('0', 2);

        return i18next.t('dateTime.timeFormat', {
            hour: hour,
            minute: minute
        });
    };

    DateTime.exportDateTimeFormat = function (datetime) {
        var year = datetime.getFullYear().toString();
        var month = (datetime.getMonth() + 1).toString().padleft('0', 2);
        var day = datetime.getDate().toString().padleft('0', 2);
        var hour = datetime.getHours().toString().padleft('0', 2);
        var minute = datetime.getMinutes().toString().padleft('0', 2);
        var seconds = datetime.getSeconds().toString().padleft('0', 2);

        // Mit i18next wird nichts angezeigt Bug?
        return year + '-' + month + '-' + day + '_' + hour + '-' + minute + '-' + seconds;
    };

    DateTime.dateTimeToString = function (datetime) {
        return DateTime.dateToString(datetime) + ' ' + DateTime.timeToString(datetime);
    };

    DateTime.toGMTString = function (datetime, withoutTime) {
        var value = datetime.toGMTString().replace(/^(\S{3},\W)(\d?)(\d\W\S{3}\W\d{4}\W\d{2}:\d{2}:\d{2}\W)UTC$/,
            function(){
                var a=Array.prototype.slice.call(arguments,1,-2);
                a.push("GMT");
                0===a[1].length && (a[1]="0");
                return a.join('')
            });

        if (withoutTime) {
            return value.substring(0, value.length-13);
        } else {
            return value;
        }
    };

    DateTime.isValidDate = function (datetime) {
        var value = typeof datetime === 'string' ?
            new Date(datetime) :
            (datetime instanceof Date ? new Date(datetime.getTime()) : null);

        return value && !isNaN(value.getDate());
    };

    DateTime.isTimeOfDateNull = function (datetime) {
        if (!DateTime.isValidDate(datetime)) {
            return true;
        }

        return (datetime.getHours() + datetime.getMinutes() + datetime.getSeconds()) === 0;
    };

    DateTime.getWeekNumber = function (date) {
        var newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        var dayNum = newDate.getUTCDay() || 7;

        newDate.setUTCDate(newDate.getUTCDate() + 4 - dayNum);

        var yearStart = new Date(Date.UTC(newDate.getUTCFullYear(), 0, 1));

        return Math.ceil((((newDate - yearStart) / 86400000) + 1) / 7);
    };

    DateTime.isDate = function (date) {
        return Object.prototype.toString.call(date) === '[object Date]' &&
            !isNaN(date.getTime());
    };

    DateTime.getDateString = function(date) {
        // 1.8.2019 => 01.08.2019
        return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth()+1)).slice(-2) + '.' + date.getFullYear();
    };

    tools.dateTime = DateTime;
})(window.Tools || (window.Tools = {}));