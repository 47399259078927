/**
 * @require ./tools.js
 */
(function (tools) {
    function getAllCookies() {
        var domainCookies = document.cookie.split(';');
        var cookies = [];

        for (var i = 0 ; i <= domainCookies.length - 1; i++) {
            var splitted = domainCookies[i].split('=');

            cookies.push({
                name: $.trim(splitted[0]),
                value: splitted[1]
            });
        }

        return cookies;
    }

    function getCookie(key) {
        if (!key) {
            return null;
        }

        var cookieArray = document.cookie.split(';');
        var cookie, i, len;

        for (i = 0, len = (cookieArray || []).length; i < len; i++) {
            cookie = cookieArray[i].split('=');

            if ($.trim(cookie[0]) === key) {
                return unescape(cookie[1]);
            }
        }
    }

    function getCSRFToken() {
        var csrf = Tools.Cookies.get(Config.CookieConfig.CsrfCookieName);

        return  csrf && csrf.length ? csrf : null;
    }

    function setCookie(key, value, invalidateOnWindowClosed) {
        if (!key) {
            return;
        }

        var expires = new Date();

        if (invalidateOnWindowClosed) {
            document.cookie = '{0}={1};'.format(key, escape(value));
        } else {
            expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
            document.cookie = '{0}={1}; expires={2}'.format(key, escape(value), expires.toGMTString());
        }
    }

    function delCookie(key) {
        if (!key) {
            return;
        }

        document.cookie = '{0}=;'.format(key);
        document.cookie = '{0}=; expires={1}'.format(key, new Date(0).toGMTString());
    }

    function migrateExistingCookies() {
        var deferreds = {
            updateNotifications: [],
            dashboard: []
        };

        var migrationKeys = getMigrationKeys();
        var migrationStatusFlags = getMigrationStatusFlags(migrationKeys);

        if (Object.keys(migrationKeys).length !== Object.keys(migrationStatusFlags).length) {
            (Tools.Cookies.getAll() || []).forEach(function (cookie) {
                var action;

                if (cookie.name.startsWith('update-notification-')) {
                    if (migrationStatusFlags.updateNotificationsMigrated) {
                        return;
                    }

                    action = migrateNotificationReadCookie(cookie);

                    if (action) {
                        deferreds.updateNotifications.push(action);
                    }
                } else if (cookie.name.startsWith('explorer-dashboard-show-')) {
                    if (migrationStatusFlags.explorerDashboardSettingsMigrated) {
                        return;
                    }

                    action = migrateDashboardCookie(cookie);

                    if (action) {
                        deferreds.dashboard.push(action);
                    }
                } else if (cookie.name === 'shrink-navigation') {
                    if (migrationStatusFlags.shrinkNavigationMigrated) {
                        return;
                    }

                    localStorage.setItem('shrink-navigation', cookie.value);
                    Tools.Cookies.delete(cookie.name);
                }
            });

            if (!migrationStatusFlags.updateNotificationsMigrated) {
                $.when.apply($, deferreds.updateNotifications).then(function () {
                    localStorage.setItem(migrationKeys.updateNotificationsMigrated, 'true');
                });
            }

            if (!migrationStatusFlags.explorerDashboardSettingsMigrated) {
                $.when.apply($, deferreds.dashboard).then(function () {
                    localStorage.setItem(migrationKeys.explorerDashboardSettingsMigrated, 'true');
                });
            }

            if (!migrationStatusFlags.shrinkNavigationMigrated) {
                localStorage.setItem(migrationKeys.shrinkNavigationMigrated, 'true');
            }
        }

        return $.when.apply($, deferreds.updateNotifications.concat(deferreds.dashboard));
    }

    function getMigrationKeys() {
        return {
            updateNotificationsMigrated: 'update-notifications-migrated',
            explorerDashboardSettingsMigrated: 'explorer-dashboard-settings-migrated',
            shrinkNavigationMigrated: 'shrink-navigation-migrated'
        };
    }

    function getMigrationStatusFlags(keys) {
        if (!keys || !Object.values(keys).length) {
            return {};
        }

        var flags = {};

        for (var key in keys) {
            if (localStorage.getItem(keys[key]) !== 'true') {
                continue;
            }

            flags[key] = true;
        }

        return flags;
    }

    function migrateNotificationReadCookie(cookie) {
        if (cookie == null) {
            return null;
        }

        var regex = /update-notification-([A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12})-read/ig;
        var matches = regex.exec(cookie.name);

        if (!matches) {
            return null;
        }

        var notificationOID = matches[1];

        if (!Tools.IsValidGuid(notificationOID)) {
            return null;
        }

        return Tools.http.post('update-notifications', {
            Action: Enums.UpdateNotificationAction.MarkAsRead,
            NotificationOID: notificationOID
        }).then(function () {
            Tools.Cookies.delete(cookie.name);
        }, function (xhr) {
            if (xhr.status === Enums.HttpStatusCode.Conflict &&
                xhr.responseText === 'Notification does not exist') {
                Tools.Cookies.delete(cookie.name);
                return;
            }

            return $.Deferred().reject().promise();
        });
    }

    function migrateDashboardCookie(cookie) {
        if (cookie == null) {
            return null;
        }

        if (cookie.value !== 'true') {
            Tools.Cookies.delete(cookie.name);
            return null;
        }

        // ChartName aus Cookienamen extrahieren
        var chartName = cookie.name.substring(24);
        var chartSettingName = Explorer.Dashboard.GetChartSettingName(chartName);

        return Tools.UpdateOfficeUserSettings(chartSettingName, true)
            .then(function () {
                Tools.Cookies.delete(cookie.name);
            });
    }

    tools.Cookies = {
        getAll: getAllCookies,
        get: getCookie,
        getCSRFToken: getCSRFToken,
        set: setCookie,
        delete: delCookie,
        migrateExistingCookies: migrateExistingCookies
    };
})(window.Tools || (window.Tools = {}));