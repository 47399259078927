/**
 * @require ./tools.js
 */
(function (tools) {
    var $progressbar = $('#progressbar');
    var $overflow = $('#overflow-transparent');
    var inProgress = 0;
    var total = 0;
    var current = 0;
    var fnOnstop = null;

    function draw() {
        var percentage = Math.round(100 / total * current);

        percentage = Math.min(percentage, 100);
        percentage += '%';

        $progressbar.find('.progress').css('width', percentage);
        $progressbar.find('.progresstext').html(percentage);
    }

    function set(progressCurrent, progressTotal) {
        current = progressCurrent || current;
        total = progressTotal || total;
        draw();
    }

    function start(progressTotal, text, fn, screenBlock) {
        fnOnstop = null;
        total += progressTotal;

        draw();

        if (total) {
            $progressbar.removeClass('hide');

            if (text) {
                $progressbar.find('.text').html(text);
            }

            if (fn) {
                fnOnstop = fn;
            }

            if (screenBlock) {
                $progressbar.addClass('screenBlock');
                $overflow.removeClass('hide');
            } else {
                $progressbar.removeClass('screenBlock');
                $overflow.addClass('hide');
            }
        }
    }

    function stop() {
        $progressbar.addClass('hide');
        $overflow.addClass('hide');

        inProgress = 0;
        total = 0;
        current = 0;
    }

    function startProgress() {
        inProgress++;
    }

    function stopProgress() {
        current++;

        draw();

        inProgress--;

        if (inProgress <= 0) {
            $progressbar.addClass('hide');
            $overflow.addClass('hide');

            inProgress = 0;
            total = 0;
            current = 0;

            if (fnOnstop) {
                fnOnstop();
            }
        }
    }

    tools.Progressbar = {
        Set: set,
        Start: start,
        Stop: stop,
        StartProgress: startProgress,
        StopProgress: stopProgress
    };
})(window.Tools || (window.Tools = {}));