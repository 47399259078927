(function (global) {
    var Nav = {};

    var $nav;
    var $navMain;
    var availableNavigationItems = {};
    var navigationIsResized = false;

    function getHeader(path) {
        var titles = [];
        var nav, splittedPath, pathSegment;

        if (!path || !Client) {
            return '';
        }

        nav = getNav();
        splittedPath = path.split('.');

        while (true) {
            pathSegment = splittedPath.shift();

            if (!pathSegment) {
                break;
            }

            nav = Tools.getFirst(nav.Children, pathSegment, 'ID');

            if (!nav) {
                break;
            }

            if (nav.Title) {
                titles.push(nav.Title);
            }
        }

        return titles.join(' › ');
    }

    function getTitle(path) {
        var nav, splittedPath, pathSegment, title;

        if (!Client) {
            return '';
        }

        nav = getNav();
        splittedPath = path.split('.');

        while (true) {
            pathSegment = splittedPath.shift();

            if (!pathSegment) {
                break;
            }

            nav = Tools.getFirst(nav.Children, pathSegment, 'ID');

            if (!nav) {
                break;
            }

            title = nav.Title || title;
        }

        return title || '';
    }

    function generate() {
        if (!Client) {
            return;
        }

        $nav = $('#nav');
        $navMain = $nav.find('.nav-main');

        var nav = getNav();
        var html = renderNav(nav);

        $navMain.html(html);
        initScrollbar();
        bindEvents();
    }

    function getNav(withoutChangemode) {
        var clientNavigation = Tools.getFirst(Client.UI, 'nav', 'ID');
        var defaultNav = {
            ID: 'nav',
            Children: [ getCurrentModeNav() ]
        };

        if (!withoutChangemode) {
            defaultNav.Children.push(getChangemodeNav());
        }

        clientNavigation = clientNavigation ?
            getClientNavigationItems(defaultNav, clientNavigation, withoutChangemode) :
            setPathOnNavigation(defaultNav);

        var userRoles = Tools.getAllRolesOfUser(User.OID);

        (function traverse(navItem) {
            if (!!navItem.Hash) {
                availableNavigationItems[navItem.Hash] = Tools.clone(navItem, ['Children']);
            }

            navItem.Children = (navItem.Children || []).filter(function (navItem) {
                return filterNavigationItems(navItem, userRoles);
            });

            navItem.Children.forEach(traverse);
        })(clientNavigation);

        return clientNavigation;
    }

    function filterNavigationItems(navItem, userRoles) {
        if (navItem.Path === 'current.info') {
            return true;
        }

        if (!Tools.contains(
            [ 'info', 'dashboard', 'news', 'issues', 'schedulings', 'main-analyses', 'calendar' ],
            navItem.ID
        )) {
            return true;
        }

        var officeConfiguration = Client.OfficeConfiguration || {};
        var enabledNavItems = (officeConfiguration.current || {}).EnabledTabs || [];

        if (!Tools.contains(enabledNavItems, navItem.ID)) {
            return false;
        }

        return userRoles.some(function (role) {
            var current = Tools.getFirst(role.OfficeUIRights, 'current', 'ID');

            if (!current || !(current.Children || []).length) {
                return false;
            }

            var navItemConfig = Tools.getFirst(current.Children, navItem.ID, 'ID');

            return navItemConfig && navItemConfig.IsEnabled;
        });
    }

    function getCurrentModeNav() {
        return {
            ID: 'current',
            Title: i18next.t('nav.current.title'),
            IconClass: 'uebersicht',
            Children: [
                {
                    ID: 'info',
                    Title: i18next.t('nav.current.info.title'),
                    IconPath: './img/menu/raeume.svg',
                    Hash: 'explorer'
                },
                {
                    ID: 'main-analyses',
                    Hash: 'analysis',
                    Title: i18next.t('nav.current.mainAnalyses.title'),
                    IconPath: './img/menu/auswertungen.svg'
                },
                {
                    ID: 'calendar',
                    Hash: 'calendar',
                    Title: i18next.t('nav.current.calendar.title'),
                    IconPath: './img/menu/kalender.svg'
                }
            ]
        };
    }

    function getChangemodeNav() {
        return {
            ID: 'editing',
            Title: i18next.t('nav.editing.title'),
            Changemode: true,
            IconClass: 'einstellungen',
            Children: [
                {
                    ID: 'explorerchangemode',
                    Hash: 'explorerchangemode',
                    Title: i18next.t('nav.editing.rooms.title'),
                    IconPath: './img/menu/raeume.svg'
                },
                {
                    ID: 'forms',
                    Hash: 'forms',
                    Title: i18next.t('nav.editing.forms.title'),
                    IconPath: './img/menu/formulare.svg'
                },
                {
                    ID: 'events',
                    Hash: 'events',
                    Title: i18next.t('nav.editing.schedulings.title'),
                    IconPath: './img/menu/plaene.svg'
                },
                {
                    ID: 'files',
                    Hash: 'files',
                    Title: i18next.t('nav.editing.catalog.files.title'),
                    IconPath: './img/menu/dateien.svg'
                },
                {
                    ID: 'catalog',
                    Title: i18next.t('nav.editing.catalog.title'),
                    RequiredLicense: 'Catalog',
                    IconPath: './img/menu/katalog.svg',
                    Children: [
                        {
                            ID: 'items',
                            Hash: 'items',
                            Title: i18next.t('nav.editing.catalog.items.title'),
                            RequiredLicense: 'Catalog',
                            IconPath: './img/menu/objekte.svg'
                        },
                        {
                            ID: 'form-templates',
                            Hash: 'form-templates',
                            Title: i18next.t('nav.editing.catalog.formTemplates.title'),
                            RequiredLicense: 'Catalog',
                            IconPath: './img/menu/formularvorlagen.svg'
                        },
                        {
                            ID: 'testgroups',
                            Hash: 'testgroup-templates',
                            Title: i18next.t('nav.editing.catalog.testgroups.title'),
                            RequiredLicense: 'Catalog',
                            IconPath: './img/menu/pruefgruppen.svg'
                        },
                        {
                            ID: 'masterData',
                            Hash: 'master-data-templates',
                            Title: i18next.t('nav.editing.catalog.masterData.title'),
                            RequiredLicense: 'Catalog',
                            IconPath: './img/menu/stammdaten.svg'
                        },
                        {
                            ID: 'properties',
                            Hash: 'property-templates',
                            Title: i18next.t('nav.editing.catalog.properties.title'),
                            RequiredLicense: 'Catalog',
                            IconPath: './img/menu/eigenschaften.svg'
                        },
                        {
                            ID: 'schedules',
                            Hash: 'schedules',
                            Title: i18next.t('nav.editing.catalog.schedules.title'),
                            RequiredLicense: 'Catalog',
                            IconPath: './img/menu/zeitplaene.svg'
                        }
                    ]
                },
                {
                    ID: 'permissions',
                    Title: i18next.t('nav.editing.permissions.title'),
                    RequiredRight: 'CMU',
                    IconPath: './img/menu/berechtigungen.svg',
                    Children: [
                        {
                            ID: 'users',
                            Hash: 'users',
                            Title: i18next.t('nav.editing.permissions.users.title'),
                            RequiredRight: 'CMU',
                            IconPath: './img/menu/benutzer.svg'
                        },
                        {
                            ID: 'teams',
                            Hash: 'teams',
                            Title: i18next.t('nav.editing.permissions.teams.title'),
                            RequiredRight: 'CMU',
                            IconPath: './img/menu/teams.svg'
                        },
                        {
                            ID: 'roles',
                            Hash: 'roles',
                            Title: i18next.t('nav.editing.permissions.roles.title'),
                            RequiredRight: 'CMU',
                            IconPath: './img/menu/rollen.svg'
                        },
                        {
                            ID: 'contacts',
                            Hash: 'contacts',
                            Title: i18next.t('nav.editing.permissions.contacts.title'),
                            RequiredRight: 'CMU',
                            RequiredLicense: 'Contacts',
                            IconPath: './img/menu/kontakte.svg'
                        },
                        {
                            ID: 'contact-groups',
                            Hash: 'contact-groups',
                            Title: i18next.t('nav.editing.permissions.contactGroups.title'),
                            RequiredRight: 'CMU',
                            RequiredLicense: 'Contacts',
                            IconPath: './img/menu/kontaktgruppen.svg'
                        }
                    ]
                },
                {
                    ID: 'system',
                    Title: i18next.t('nav.editing.system.title'),
                    IconPath: './img/menu/system.svg',
                    Children: [
                        {
                            ID: 'categories',
                            Hash: 'categories',
                            Title: i18next.t('nav.editing.system.categories.title'),
                            IconPath: './img/menu/werteklassen.svg'
                        },
                        {
                            ID: 'priorities',
                            Hash: 'priorities',
                            Title: i18next.t('nav.editing.system.priorities.title'),
                            IconPath: './img/menu/prioritaeten.svg'
                        },
                        {
                            ID: 'keywords',
                            Hash: 'keywords',
                            Title: i18next.t('nav.editing.system.keywords.title'),
                            IconPath: './img/menu/schlagwoerter.svg'
                        },
                        {
                            ID: 'classifications',
                            Hash: 'classifications',
                            Title: i18next.t('nav.editing.system.classifications.title'),
                            IconPath: './img/menu/klassifizierungen.svg'
                        },
                        {
                            ID: 'status',
                            Hash: 'status',
                            Title: i18next.t('nav.editing.system.status.title'),
                            RequiredRight: 'CMU',
                            IconPath: './img/menu/status.svg'
                        },
                        {
                            ID: 'units',
                            Hash: 'units',
                            Title: i18next.t('nav.editing.system.units.title'),
                            IconPath: './img/menu/einheiten.svg'
                        },
                        {
                            ID: 'element-codings',
                            Hash: 'element-codings',
                            Title: i18next.t('nav.editing.system.elementCodings.title'),
                            IconPath: './img/menu/codierungen.svg'
                        },
                        {
                            ID: 'mail-templates',
                            Hash: 'mail-templates',
                            Title: i18next.t('nav.editing.system.mailTemplates.title'),
                            IconPath: './img/menu/mail-templates.svg'
                        },
                        {
                            ID: 'custommenuitems',
                            Hash: 'custom-menu-items',
                            Title: i18next.t('nav.editing.system.customMenuItems.title'),
                            RequiredLicense: 'ShowMenuItemEditorInOffice',
                            IconPath: './img/menu/menuepunkte-app.svg'
                        },
                        {
                            ID: 'company',
                            Hash: 'company',
                            Title: i18next.t('nav.editing.system.company.title'),
                            IconPath: './img/menu/firma.svg'
                        },
                        {
                            ID: 'settings',
                            Hash: 'settings',
                            Title: i18next.t('nav.editing.system.settings.title'),
                            IconPath: './img/menu/einstellungen.svg'
                        },
                        {
                            ID: 'usersettings',
                            Hash: 'usersettings',
                            Title: i18next.t('nav.editing.system.userSettings.title'),
                            RequiredRight: 'CMU',
                            IconPath: './img/menu/pers-einstellungen-app.svg'
                        },
                        {
                            ID: 'licenses',
                            Hash: 'licenses',
                            Title: i18next.t('nav.editing.system.licenses.title'),
                            IconPath: './img/menu/lizenzen.svg'
                        }
                    ]
                },
                {
                    ID: 'commit',
                    Title: i18next.t('nav.editing.commit.title'),
                    Status: true,
                    IconPath: './img/menu/freigeben.svg'
                }
            ]
        };
    }

    function setPathOnNavigation(navigation) {
        if (!navigation) {
            return;
        }

        (function traverse (navItem) {
            navItem.Children = navItem.Children || [];
            navItem.Children.forEach(function (childNavItem) {
                childNavItem.Path = (navItem.Path ? navItem.Path + '.' : '') + childNavItem.ID;

                traverse(childNavItem);
            });
        })(navigation);

        return navigation;
    }

    function getClientNavigationItems(defaultNavigation, clientNavigation, withoutChangemode) {
        var defaultNavigationMap = {};

        if (!defaultNavigation || !clientNavigation) {
            return null;
        }

        (function traverse (navItem) {
            defaultNavigationMap[navItem.ID] = navItem;

            navItem.Children = navItem.Children || [];
            navItem.Children.forEach(function (childNavItem) {
                childNavItem.Path = (navItem.Path ? navItem.Path + '.' : '') + childNavItem.ID;

                traverse(childNavItem);
            });
        })(defaultNavigation);

        (function traverse(navItem, changemode) {
            var defaultNavItem = defaultNavigationMap[navItem.ID];

            if (defaultNavItem) {
                navItem.Title = navItem.Title || defaultNavItem.Title;
                navItem.Hash = defaultNavItem.Hash;
                navItem.RequiredRight = defaultNavItem.RequiredRight;
                navItem.RequiredLicense = defaultNavItem.RequiredLicense;
                navItem.Changemode = defaultNavItem.Changemode;
                navItem.IconClass = defaultNavItem.IconClass;
                navItem.NoColl = defaultNavItem.NoColl;
                navItem.Status = defaultNavItem.Status;
                navItem.IconPath = navItem.IconPath || defaultNavItem.IconPath;

                if (defaultNavItem.Changemode) {
                    changemode = defaultNavItem.Changemode;
                }
            } else {
                navItem.Title = navItem.Title || '';
                navItem.Hash = navItem.ID;
                navItem.Modifications = changemode;
                navItem.IconClass = navItem.IconClass || '';

                if (changemode) {
                    ApplicationRoutes['^' + navItem.ID + '-modifications$'] = global.IndividualData.Show;
                    ApplicationRoutes['^' + navItem.ID + '-modifications\/(.*)$'] = global.IndividualData.Show;

                    if (!navItem.IconPath) {
                        navItem.IconPath = './img/menu/allgemein.svg';
                    }
                }
            }

            navItem.Children = navItem.Children || [];

            if (withoutChangemode && navItem.ID === 'nav') {
                navItem.Children = navItem.Children.filter(function (childNavItem) {
                    return childNavItem.ID !== 'editing';
                });
            }

            navItem.Children = navItem.Children.filter(function (childNavItem) {
                return (childNavItem.Children || []).length ||
                    defaultNavigationMap.hasOwnProperty(childNavItem.ID) ||
                    IndividualData.GetSchema(childNavItem.ID);
            });

            navItem.Children.forEach(function (childNavItem) {
                childNavItem.Path = (navItem.Path ? navItem.Path + '.' : '') + childNavItem.ID;

                traverse(childNavItem, changemode);
            });
        })(clientNavigation, false);

        if (clientNavigation.Children.length > 1) {
            clientNavigation.Children[1].Children = clientNavigation.Children[1].Children.filter(function (childNavItem) {
                return (childNavItem.Children || []).length ||
                    defaultNavigationMap.hasOwnProperty(childNavItem.ID) ||
                    IndividualData.GetSchema(childNavItem.ID);
            });
        }

        return clientNavigation;
    }

    function renderNav(nav) {
        var html = nav.Children.map(function (navItem) {
            return renderNavItem(navItem, 1);
        });

        return html.join('');
    }

    function renderNavItem(navItem, level) {
        var html = [];
        var href;

        if (navItem.RequiredLicense &&
            !(Client.Licenses && Client.Licenses[navItem.RequiredLicense])) {
            return '';
        }

        if (navItem.RequiredRight &&
                !ressources.users.hasRight(navItem.RequiredRight)) {
            return '';
        }

        if (navItem.Hash) {
            href = navItem.Modifications ?
                    'href="#{0}-modifications"'.format(navItem.Hash) :
                    'href="#{0}"'.format(navItem.Hash);
        } else {
            href = '';
        }

        switch (level) {
            case 1:
                if (navItem.Changemode) {
                    html.push('<div class="dd changemode-hide closed" data-changemode="1">');
                } else {
                    html.push('<div class="dd no-border" data-changemode="0">');
                }

                html.push(
                    '<div class="df df-title nrm menuImage first-letter-uppercase {0}">'.format(navItem.IconClass),
                    '<span class="caption">{0}</span></div>'.format(navItem.Title)
                );

                if (navItem.Children.length) {
                    html.push('<ul class="fd slideIn show">');

                    html = html.concat(navItem.Children.map(function (navItem) {
                        return renderNavItem(navItem, level + 1);
                    }));

                    html.push('</ul>');
                }

                html.push('</div>');
                break;
            case 2:
                if (navItem.Children.length) {
                    if (navItem.NoColl) {
                        html.push(
                            '<li class="has-children closed">',
                            '<a {0} data-id="{1}" data-path="{2}" class="pt first-letter-uppercase {3}"><span class="caption">{4}</span></a>'
                            .format(href, navItem.ID, navItem.Path, navItem.Status ? 'status' : '', navItem.Title)
                        );
                    } else {
                        html.push(
                            '<li class="has-children closed">',
                                '<div class="pt coll">',
                                    '<span class="coll-s"></span>',
                                    !!navItem.IconPath ? '<img class="icon" src="{0}" />'.format(navItem.IconPath) : '',
                                    '<div class="first-letter-uppercase" style="display: inline-block;">',
                                        '<span class="caption">{0}</span>'.format(navItem.Title),
                                    '</div>',
                                '</div>'
                        );
                    }

                    html.push('<ul class="slideIn">');

                    html = html.concat(navItem.Children.map(function (navItem) {
                        return renderNavItem(navItem, level + 1);
                    }));

                    html.push('</ul></li>');
                } else {
                    if (navItem.Title === i18next.t('nav.editing.commit.title')) {
                        html.push(
                            '<li>',
                                '<a {0} data-id="{1}" data-path="{2}" class="pt first-letter-uppercase {3}">'
                                    .format(href, navItem.ID, navItem.Path, navItem.Status ? 'status' : '', navItem.Title),
                                    !!navItem.IconPath ? '<img class="icon" src="{0}" />'.format(navItem.IconPath) : '',
                                    '<span class="caption">{0}</span> <span class="commit-dot"></span>'
                                        .format(navItem.Title),
                                '</a>',
                            '</li>'
                        );
                    } else {
                        html.push(
                            '<li>',
                                '<a {0} data-id="{1}" data-path="{2}" class="pt first-letter-uppercase {3}">'
                                    .format(href, navItem.ID, navItem.Path, navItem.Status ? 'status' : '', navItem.Title),
                                    !!navItem.IconPath ? '<img class="icon" src="{0}" />'
                                        .format(navItem.IconPath) : '',
                                    '<span class="caption">{0}</span>'
                                        .format(navItem.Title),
                                '</a>',
                            '</li>'
                        );
                    }
                }
                break;
            default:
                html.push(
                    '<li>',
                        '<a {0} data-id="{1}" data-path="{2}" class="pt up first-letter-uppercase {3}">'
                            .format(href, navItem.ID, navItem.Path, navItem.Status ? 'status' : ''),
                        !!navItem.IconPath ? '<img class="icon" src="{0}" />'.format(navItem.IconPath) : '',
                        '<span class="caption">',
                        navItem.Title,
                        '</span>',
                        '</a>',
                    '</li>'
                );
                break;
        }

        return html.join('');
    }

    function initScrollbar() {
        var instance = OverlayScrollbarsGlobal.OverlayScrollbars($navMain[0]);

        if (instance) {
            instance.destroy();
        }

        OverlayScrollbarsGlobal.OverlayScrollbars($navMain[0], {
            scrollbars: {
                theme: 'os-theme-light',
                autoHide: 'never'
            }
        });
    }

    function onToggleMenu(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        var $this = $(this);
        var $list = $this.next();
        var $parent = $this.parent();

        $parent.toggleClass('closed');

        $this.removeClass('coll');
        $list
            .removeClass('coll')
            .toggleClass('show');

        var scrollbarInstance = OverlayScrollbarsGlobal.OverlayScrollbars($navMain[0]);
        var animationDuration = parseFloat($list.css('animation-duration')) * 1000;

        setTimeout(function () {
            scrollbarInstance.update(true);
        }, animationDuration);
    }

    function onCommit() {
        var options = {};

        options.title = i18next.t('changeMode.messages.commitModifications.title');
        options.text = i18next.t('changeMode.messages.commitModifications.text');
        options.no = true;
        options.onYes = function () {
            Tools.Spinner.show();

            Commit.send(function () {
                Tools.Spinner.hide();
                Commit.changed = true;
                content.ReloadAccount = true;
                Commit.setChanged(false);
            });
        };

        Tools.Message.Show(options);
    }

    function onNavigationMouseEnter(evt) {
        var $tooltip = $('#gfx-tooltip');
        var $this = $(evt.currentTarget);

        if (!navigationIsResized) {
            return;
        }

        var offset = $this.offset();
        var caption = Tools.escapeHtml($this.find('.caption').text());

        $tooltip.html(caption);
        $tooltip.removeClass('hide');
        $tooltip.attr('arrow', 3);
        $tooltip.css({
            left: $this.outerWidth(false) + 8,
            top: offset.top + ($this.outerHeight(false) / 2) - ($tooltip.outerHeight(false) / 2)
        });
    }

    function onNavigationMouseLeave() {
        $('#gfx-tooltip').addClass('hide');
    }

    function updateCurrentAreaLinks() {
        var navigation = getNav(true);
        var navigationMarkup = renderNav(navigation);

        $nav.find('.dd[data-changemode="0"]')
            .html(navigationMarkup);
    }

    function getNavItem(hash) {
        return availableNavigationItems[hash];
    }

    function unbindEvents() {
        $nav.off('click.toggleMenu');
        $nav.off('click.doCommit');
        $nav.off('mouseenter.showCaptionTooltip');
        $nav.off('mouseleave.hideCaptionTooltip');
    }

    function bindEvents() {
        unbindEvents();

        $nav.on('click.toggleMenu', 'div.pt, div.df', onToggleMenu);
        $nav.on('click.doCommit', '[data-id="commit"]', onCommit);
        $nav.on('mouseenter.showCaptionTooltip', '.df, .pt', onNavigationMouseEnter);
        $nav.on('mouseleave.hideCaptionTooltip', '.df, .pt', onNavigationMouseLeave);
    }

    function setResized(resize) {
        navigationIsResized = resize;
    }

    Nav.GetHeader = getHeader;
    Nav.GetTitle = getTitle;
    Nav.Generate = generate;
    Nav.UpdateCurrentAreaLinks = updateCurrentAreaLinks;
    Nav.GetNavItem = getNavItem;
    Nav.SetResized = setResized;

    return (global.Nav = Nav);
})(window);