(function (global) {

    var $dropdownButton;
    var $dropdownPanel = $('#dropdownListPanel');
    var availableOptions = [];
    var selectedOptions = [];
    var isMultiselect;
    var allowNoSelection;
    var unselectOnSecondClick;
    var toggleAllOptionsButton;
    var updateButtonTitle;
    var defaultButtonTitle;
    var onChangeEvent;
    var onShowEvent;
    var onHideEvent;

    /**
     * @param {DropdownListData} data - Die Einstellungen der DropdownListe
     * @param {JQuery} data.Element - Der Button mit dem die DropdownListe geöffnet und geschlossen werden soll.
     * @param {Object[]=} data.Options - Die möglichen Optionen der DropdownListe.
     * @param {bool=false} data.Multiselect - Kann mehr als eine Option ausgewählt werden.
     * @param {bool=false} data.AllowNoSelection - Darf keine Option ausgewählt sein.
     * @param {bool=false} data.CheckAllButton - Button um alle Optionen an bzw. ab zu wählen.
     * @param {bool=false} data.UpdateButtonTitle - Soll der Button Title aktualisiert werden.
     * @param {bool=} data.DefaultButtonTitle - Text der angezeigt werden soll wenn nichts ausgewählt ist.
     * @param {bool=true} data.UnselectOnSecondClick - Soll eine Option abgewählt werden wenn diese zum zweiten Mal angeklickt wurde. (Nicht bei Mehrfachauswahl)
     * @param {function=} data.OnChangeEvent - Wird ausgelöst sobald eine Option angeklickt wurde.
     * @param {function=} data.OnShowEvent - Wird beim Einblenden der DropdownListe ausgelöst.
     * @param {function=} data.OnHideEvent - Wird beim Ausblenden der DropdownListe ausgelöst.
     */
    function DropdownList(data) {
        $dropdownButton = data.Element;
        addOptions(data.Options || []);
        isMultiselect = typeof data.Multiselect !== 'undefind' ? data.Multiselect : false;
        allowNoSelection = typeof data.AllowNoSelection !== 'undefind' ? data.AllowNoSelection : false;
        toggleAllOptionsButton = typeof data.ToggleAllOptionsButton !== 'undefind' ? data.ToggleAllOptionsButton : false;
        updateButtonTitle = typeof data.UpdateButtonTitle !== 'undefind' ? data.UpdateButtonTitle : false;
        defaultButtonTitle = data.DefaultButtonTitle || $dropdownButton.find('.btn-text').text();
        unselectOnSecondClick = typeof data.UnselectOnSecondClick !== 'undefind' ? data.UnselectOnSecondClick : true;
        onChangeEvent = data.OnChangeEvent;
        onShowEvent = data.OnShowEvent;
        onHideEvent = data.OnHideEvent;

        unbindEvents();
        bindEvents();

        updateButton();
    }

    function show() {
        renderOptions();
        $dropdownPanel.removeClass('hide');
        $dropdownButton.addClass('active');
    }

    function hide() {
        $dropdownPanel.addClass('hide');
        $dropdownButton.removeClass('active');
    }

    function setOptions(options) {
        var tmpOption;
        var cnt, len;

        availableOptions = options;
        selectedOptions = [];

        for (cnt = 0, len = options.length; cnt < len; cnt++) {
            tmpOption = options[cnt];

            if (tmpOption.IsSelected && (selectedOptions.length < 1 || isMultiselect)) {
                selectedOptions.push(tmpOption.Value);
            }
        }

        updateButton();
    }

    function addOptions(options) {
        var cnt, len;
        var option;

        for (cnt = 0, len = options.length; cnt < len; cnt++) {
            option = options[cnt];

            if (!Tools.contains(availableOptions, option.Value, 'Value')) {
                availableOptions.push(option);

                if (option.IsSelected) {
                    selectedOptions.push(option.Value);
                }
            }
        }
    }

    function removeOptions(options) {
        var cnt, len;
        var option;

        for (cnt = 0, len = availableOptions.length; cnt < len; cnt++) {
            option = availableOptions[cnt];

            if (Tools.contains(options, option.Value)) {
                if (Tools.contains(selectedOptions, option.Value) && !allowNoSelection && len > 1) {
                    selectOption(availableOptions[0].Value)
                }

                unselectOption(option.Value);

                availableOptions.splice(cnt, 1);
                cnt--;
                len--;
            }
        }

        if (updateButtonTitle) {
            updateButton();
        }
    }

    function getSelectedOptions() {
        return selectedOptions;
    }

    function bindEvents() {
        $dropdownButton.on('click.toggle', onToggleDropdownListClick);
        $dropdownPanel.on('click.preventPropagation', onStopPropagationClick);
        $dropdownPanel.on('click.option', '.element', onElementClick);
        $dropdownPanel.on('click.close', '.bottom .close', onCloseClick);
        $('body').on('click.close', onBodyClick);
    }

    function unbindEvents() {
        $dropdownButton.off('click.toggle');
        $dropdownPanel.off('click.option click.close');
        $('body').off('click.close')
    }

    function renderOptions() {
        var html = [];
        var tmpOption;
        var cnt;
        var len;
        var selected;

        $dropdownPanel.empty();
        html.push('<div class="content">');

        for (cnt = 0, len = availableOptions.length; cnt < len; cnt++) {
            tmpOption = availableOptions[cnt];
            selected = Tools.contains(selectedOptions, tmpOption.Value);

            html.push('<div class="element{0}" data-selection="{1}">{2}</div>'
                .format(selected ? ' isChecked' : '', tmpOption.Value, tmpOption.Title))
        }

        html.push('</div>');

        $dropdownPanel.html(html.join(''));

        var position = determinePosition();

        $dropdownPanel.css({
            top: position.top,
            left: position.left,
            'min-width': $dropdownButton.outerWidth()
        });

        $dropdownPanel.removeClass('left center right');
        $dropdownPanel.addClass(position.vertical)
    }

    function selectOption(option) {
        var optionValue;
        var $element;

        if (typeof option === 'string') {
            optionValue = option;
            $element = $dropdownPanel.find('.element[data-selection="{0}"]'.format(optionValue));
        } else {
            $element = option;
            optionValue = $element.attr('data-selection');
        }

        if (!Tools.contains(selectedOptions, optionValue)) {
            selectedOptions.push(optionValue);
            $element.addClass('isChecked');
        }
    }

    function unselectOption(option) {
        var cnt, len;
        var optionValue;
        var $element;

        if (typeof option === 'string') {
            optionValue = option;
            $element = $dropdownPanel.find('.element[data-selection="{0}"]'.format(optionValue));
        } else {
            $element = option;
            optionValue = $element.attr('data-selection');
        }


        for (cnt = 0, len = selectedOptions.length; cnt < len; cnt++) {
            if (optionValue === selectedOptions[cnt]) {
                selectedOptions.splice(cnt, 1);
                cnt--;
                len--;
                $element.removeClass('isChecked');
            }
        }
    }

    function toggleDropdownList() {
        if ($dropdownPanel.hasClass('hide')) {
            show()
        } else {
            hide()
        }
    }

    function resetOptions() {
        selectedOptions = [];
        $dropdownPanel.find('.element.isChecked').removeClass('isChecked');
        updateButton();
    }

    function updateButton() {
        var cnt, len;
        var text = '';
        var option;

        if ((selectedOptions || []).length) {
            for (cnt = 0, len = selectedOptions.length; cnt < len; cnt++) {
                option = Tools.getFirst(availableOptions, selectedOptions[cnt], 'Value');

                if (cnt === 0) {
                    text = option.Title;
                } else {
                    text += ', {0}'.format(option.Title);
                }
            }
        } else {
            text = defaultButtonTitle;
        }

        $dropdownButton.find('.btn-steal').html(text);
    }

    function determinePosition() {
        var $parent = $dropdownPanel.parent();

        var targetOffset = $dropdownButton.offset();
        var containerOffset = $parent.offset();
        var targetWidth = $dropdownButton.outerWidth(false);
        var targetHeight = $dropdownButton.outerHeight(false);
        var containerWidth = $parent.innerWidth();
        var containerHeight = $parent.innerHeight() || window.innerHeight;
        var popoverWidth = $dropdownPanel.outerWidth(false);
        var popoverHeight = $dropdownPanel.outerHeight(false);
        var scrollTop = $parent.scrollTop();
        var targetPosition = {};
        var targetCenter = {};
        var position = {};

        var offsetY = 5;


        targetPosition.left = targetOffset.left - containerOffset.left;
        targetPosition.top = targetOffset.top - containerOffset.top;

        targetCenter.left = targetPosition.left + targetWidth / 2;
        targetCenter.top = targetPosition.top + targetHeight + offsetY;

        if ((targetCenter.left - popoverWidth / 2) < 0) {
            position.vertical = 'left';
            position.left = targetCenter.left - 30;
        } else if ((targetCenter.left + popoverWidth / 2) > (containerWidth - 0)) {
            position.vertical = 'right';
            position.left = targetCenter.left - popoverWidth + 40;
        } else {
            position.vertical = 'center';
            position.left = targetCenter.left - popoverWidth / 2;
        }

        if (targetCenter.top + popoverHeight - scrollTop + 20 > containerHeight &&
            targetCenter.top - popoverHeight - scrollTop > 20) {
            position.horizontal = 'bottom';
            position.top = targetCenter.top - popoverHeight;
        } else {
            position.horizontal = 'top';
            position.top = targetCenter.top;
        }

        return position;
    }

    function onToggleDropdownListClick(evt) {
        evt.stopPropagation();
        toggleDropdownList();
    }

    function onElementClick(evt) {
        var $this = $(this);
        var selected = !$this.hasClass('isChecked');
        var $selectedOptions = $dropdownPanel.find('.element.isChecked');

        if (!isMultiselect) {
            resetOptions();
            hide();

            if (!selected && !unselectOnSecondClick) {
                selected = true;
            }
        }

        if ($selectedOptions.length === 1 && !selected && !allowNoSelection) {
            selected = true;
        }

        if (selected) {
            selectOption($this)
        } else {
            unselectOption($this)
        }

        if (updateButtonTitle) {
            updateButton();
        }



        if (onChangeEvent) {
            onChangeEvent(selectedOptions);
        }

        evt.stopPropagation();
    }

    function onCloseClick() {
        hide();
    }

    function onBodyClick() {
        hide();
    }

    function onStopPropagationClick(evt) {
        evt.stopPropagation();
        evt.preventDefault();
    }

    DropdownList.prototype.constructor = DropdownList;
    DropdownList.prototype.Show = show;
    DropdownList.prototype.Hide = hide;
    DropdownList.prototype.SetOptions = setOptions;
    DropdownList.prototype.AddOptions = addOptions;
    DropdownList.prototype.RemoveOptions = removeOptions;
    DropdownList.prototype.GetSelectedOptions = getSelectedOptions;
    DropdownList.prototype.ResetOptions = resetOptions;

    return (global.DropdownList = DropdownList);
})(window);