/**
 * @require ../tools.js
 */
(function (tools) {
    /**
     * @typedef ApexChartsOptionsWrapper
     * @property {HTMLElement} element Der Container, in welchem das Diagramm gezeichnet werden soll.
     * @property {number[]} series Enthält die Daten für das jeweilige Diagramm.
     * @property {string[]} labels Die Bezeichner der Datensätze.
     * @property {string[]|null} colors Die Hintergrundfarben der Datensätze.
     * @property {string[]|null} labelFontColors Die Schriftfarben der Labels. Müssen in der selben Reihenfolge angegeben werden.
     * @property {string|null} chartTitle Der Titel des Diagramms.
     * @property {number|null} height Die Höhe des Diagramms.
     * @property {number|null} width Die Breite des Diagramms.
     * @property {ApexSeriesContext[]|null} xAxisCategories
     * @property {boolean|null} showLegend Gibt an, ob die Farb-Legende zum Diagramm angezeigt werden soll.
     * @property {'top'|'right'|'bottom'|'left'|null} legendPosition Gibt die Position der Legende an.
     * @property {boolean|null} showLabels Gibt an, ob die Labels zu den jeweiligen Datenpunkten angezeigt werden sollen.
     * @property {Function|null} onDataPointClick Click-Handler für das Klicken auf einen Datenpunkt.
     * @property {Function|null} valueFormatter Funktion zum Formatieren der angezeigten Werte.
     * @property {string|null} yAxisCaption Wird nur bei Line-Charts unterstützt
     * @property {number|null} yAxisTicks Gibt die Anzahl der Labels auf der y-Achse an. Die Abstände werden automatisch berechnet.
     * @property {YAxisAnnotations[]|null} yAxisAnnotations Wird nur bei Line-Charts unterstützt
     * @property {number|null} yAxisMaxValue
     */

    /**
     * @typedef ApexSeriesContext
     * @property {string|null} Identifier
     * @property {string|null} Color
     * @property {string|null} Title
     */

    /**
     * @typedef ApexChartType Enum zur Auswahl einer der vom Wrapper unterstützten Diagrammtypen.
     * @type {string}
     */
    var apexChartType = {
        /**
         * Tortendiagramm
         */
        Pie: 'pie',
        /**
         * Balkendiagramm
         */
        Bar: 'bar',
        /**
         * Balkendiagramm mit mehreren Y-Werten pro X-Wert
         */
        StackedBar: 'stacked-bar',
        /**
         * Liniendiagramm
         */
        Line: 'line',
    };

    /**
     * Muss beim Start der Anwendung bzw. beim Wechsel der Sprache aufgerufen werden.
     */
    function initLanguage() {
        /**
         * @type {ApexChart}
         */
        Apex.chart = {
            locales: [{
                name: User.Language,
                options: {
                    months: [
                        i18next.t('months.january'),
                        i18next.t('months.february'),
                        i18next.t('months.march'),
                        i18next.t('months.april'),
                        i18next.t('months.may'),
                        i18next.t('months.june'),
                        i18next.t('months.july'),
                        i18next.t('months.august'),
                        i18next.t('months.september'),
                        i18next.t('months.october'),
                        i18next.t('months.november'),
                        i18next.t('months.december')
                    ],
                    shortMonths: [
                        i18next.t('months.january_abbreviation'),
                        i18next.t('months.february_abbreviation'),
                        i18next.t('months.march_abbreviation'),
                        i18next.t('months.april_abbreviation'),
                        i18next.t('months.may_abbreviation'),
                        i18next.t('months.june_abbreviation'),
                        i18next.t('months.july_abbreviation'),
                        i18next.t('months.august_abbreviation'),
                        i18next.t('months.september_abbreviation'),
                        i18next.t('months.october_abbreviation'),
                        i18next.t('months.november_abbreviation'),
                        i18next.t('months.december_abbreviation')
                    ],
                    days: [
                        i18next.t('weekdays.sunday'),
                        i18next.t('weekdays.monday'),
                        i18next.t('weekdays.tuesday'),
                        i18next.t('weekdays.wednesday'),
                        i18next.t('weekdays.thursday'),
                        i18next.t('weekdays.friday'),
                        i18next.t('weekdays.saturday')
                    ],
                    shortDays: [
                        i18next.t('weekdays.sunday_abbreviation'),
                        i18next.t('weekdays.monday_abbreviation'),
                        i18next.t('weekdays.tuesday_abbreviation'),
                        i18next.t('weekdays.wednesday_abbreviation'),
                        i18next.t('weekdays.thursday_abbreviation'),
                        i18next.t('weekdays.friday_abbreviation'),
                        i18next.t('weekdays.saturday_abbreviation')
                    ],
                    toolbar: {
                        exportToSVG: i18next.t('misc.apexCharts.toolbar.exportToSVG'),
                        exportToPNG: i18next.t('misc.apexCharts.toolbar.exportToPNG'),
                        exportToCSV: i18next.t('misc.apexCharts.toolbar.exportToCSV'),
                        menu: i18next.t('misc.apexCharts.toolbar.menu'),
                        selection: i18next.t('misc.apexCharts.toolbar.selection'),
                        selectionZoom: i18next.t('misc.apexCharts.toolbar.selectionZoom'),
                        zoomIn: i18next.t('misc.apexCharts.toolbar.zoomIn'),
                        zoomOut: i18next.t('misc.apexCharts.toolbar.zoomOut'),
                        pan: i18next.t('misc.apexCharts.toolbar.pan'),
                        reset: i18next.t('misc.apexCharts.toolbar.reset')
                    }
                }
            }],
            defaultLocale: User.Language
        };
    }

    /**
     * @param {string|null} title
     * @returns {ApexTitleSubtitle}
     */
    function getTitleConfiguration(title) {
        return {
            text: title,
            align: 'center',
            margin: 30,
            floating: true,
            offsetY: 10,
            style: {
                fontSize: '1.5rem',
                fontFamily: '"Titillium Web", Verdana, Arial, Helvetica, sans-serif'
            }
        };
    }

    /**
     * @param {boolean|null} showLegend
     * @param {'top'|'right'|'bottom'|'left'} legendPosition
     * @returns {ApexLegend}
     */
    function getLegendConfiguration(showLegend, legendPosition) {
        return {
            show: showLegend || false,
            position: legendPosition || 'bottom',
            horizontalAlign: 'center',
            markers: {
                radius: 2
            }
        };
    }

    /**
     * @param {string|null} header
     * @param {string|null} content
     * @returns {string}
     */
    function createCustomTooltip(header, content) {
        var html = ['<div class="apex-charts-custom-tooltip">'];

        if (!!header) {
            html.push('<div class="header">', header, '</div>');
        }

        if (!!content) {
            html.push('<div class="content">', content, '</div>');
        }

        return html.join('');
    }

    /**
     * @param {ApexSeriesContext|null} context
     * @returns {string|null}
     */
    function createCustomTooltipHeader(context) {
        if (!context) {
            return null;
        }

        var html = [];

        if (context.Color) {
            html.push('<span class="color" style="background-color: ', context.Color, '"></span>&nbsp;');
        }

        if (context.Title) {
            html.push(Tools.escapeHtml(context.Title));
        }

        return html.length ? html.join('') : null;
    }

    /**
     * @param {number[]} chartContext.series
     * @param {object} chartContext.w
     * @param {number} chartContext.seriesIndex
     * @param {Function|null} valueFormatter
     * @returns {string|null}
     */
    function handleCustomPieChartTooltip(chartContext, valueFormatter) {
        if (!chartContext) {
            return null;
        }

        var sumTotal = chartContext.series.reduce(function (sum, val) {
            return sum + val;
        }, 0);

        var pointValue = chartContext.series[chartContext.seriesIndex];
        var percentage = (pointValue / sumTotal * 100).toFixed(2);

        /**
         * @type {ApexSeriesContext|null}
         */
        var context = chartContext.w.config.xAxis.categories instanceof Array ?
            chartContext.w.config.xAxis.categories[chartContext.seriesIndex] :
            { Title: chartContext.w.config.labels[chartContext.seriesIndex] };

        var header = createCustomTooltipHeader(context);

        if (valueFormatter instanceof Function) {
            sumTotal = valueFormatter(sumTotal);
        }

        return createCustomTooltip(
            header,
            '<strong>' + pointValue + '</strong> / ' + sumTotal + '<br>' + percentage + ' %'
        );
    }

    /**
     * @param chartContext
     * @param {number[][]} chartContext.series
     * @param {object} chartContext.w
     * @param {number} chartContext.seriesIndex
     * @param {number} chartContext.dataPointIndex
     * @param {Function|null} valueFormatter
     * @returns {string|null}
     */
    function handleCustomBarChartTooltip(chartContext, valueFormatter) {
        if (!chartContext) {
            return null;
        }

        var series = chartContext.w.config.series[chartContext.seriesIndex];

        if (!series) {
            return null;
        }

        var data = series.data[chartContext.dataPointIndex];

        if (!data) {
            return null;
        }

        var header = createCustomTooltipHeader({
            Title: data.Title,
            Color: data.Color
        });

        var pointValue = data.y;

        if (valueFormatter instanceof Function) {
            pointValue = valueFormatter(pointValue, data);
        }

        return createCustomTooltip(
            header,
            '<strong>' + pointValue + '</strong>'
        );
    }

    /**
     * @param chartContext
     * @param {number[][]} chartContext.series
     * @param {object} chartContext.w
     * @param {number} chartContext.seriesIndex
     * @param {number} chartContext.dataPointIndex
     * @param {Function|null} valueFormatter
     * @returns {string|null}
     */
    function handleCustomStackedBarChartTooltip(chartContext, valueFormatter) {
        if (!chartContext) {
            return null;
        }

        var sumTotal = chartContext.series.reduce(function (sum, series) {
            return sum + series[chartContext.dataPointIndex];
        }, 0);

        var pointValue = chartContext.series[chartContext.seriesIndex][chartContext.dataPointIndex];
        var percentage = (pointValue / sumTotal * 100).toFixed(2);

        /**
         * @type {ApexSeriesContext}
         */
        var xCaption = chartContext.w.config.xaxis.categories[chartContext.dataPointIndex];
        var yCaption = chartContext.w.config.series[chartContext.seriesIndex].name;
        var header = createCustomTooltipHeader({
            Title: xCaption + ' - ' + yCaption
        });

        if (valueFormatter instanceof Function) {
            sumTotal = valueFormatter(sumTotal);
        }

        return createCustomTooltip(
            header,
            '<strong>' + pointValue + '</strong> / ' + sumTotal + '<br>' + percentage + ' %'
        );
    }

    /**
     * @param chartContext
     * @param {number[][]} chartContext.series
     * @param {object} chartContext.w
     * @param {number} chartContext.seriesIndex
     * @param {number} chartContext.dataPointIndex
     * @param {Function|null} valueFormatter
     * @returns {string|null}
     */
    function handleCustomLineChartTooltip(chartContext, valueFormatter) {
        if (!chartContext) {
            return null;
        }

        var pointData = chartContext.w.config.series[chartContext.seriesIndex].data[chartContext.dataPointIndex];

        /**
         * @type {ApexSeriesContext}
         */
        var header = createCustomTooltipHeader({
            Title: Tools.dateTime.dateTimeToString(new Date(pointData[0]))
        });

        var pointValue = pointData[1];

        if (valueFormatter instanceof Function) {
            pointValue = valueFormatter(pointValue);
        }

        return createCustomTooltip(
            header,
            '<strong>' + pointValue + '</strong>'
        );
    }

    /**
     * @param {ApexChartsOptionsWrapper} chartOptions
     * @returns {ApexCharts|null}
     */
    function createPieChart(chartOptions) {
        /**
         * @type {ApexOptions}
         */
        var options = {
            chart: {
                type: 'pie',
                height: 400,
                events: {
                    dataPointSelection: chartOptions.onDataPointClick
                }
            },
            title: getTitleConfiguration(chartOptions.chartTitle),
            series: chartOptions.series,
            labels: chartOptions.labels,
            dataLabels: {
                enabled: chartOptions.showLabels || false
            },
            legend: getLegendConfiguration(chartOptions.showLegend, chartOptions.legendPosition),
            colors: chartOptions.colors,
            xAxis: {
                categories: chartOptions.xAxisCategories
            },
            tooltip: {
                custom: function (chartContext) {
                    return handleCustomPieChartTooltip(chartContext, chartOptions.valueFormatter);
                }
            }
        };

        if (chartOptions.height) {
            options.chart.height = chartOptions.height;
        }

        if (chartOptions.width) {
            options.chart.width = chartOptions.width;
        }

        return new ApexCharts(chartOptions.element, options);
    }

    /**
     * @param {ApexChartsOptionsWrapper} chartOptions
     * @returns {ApexCharts|null}
     */
    function createLineChart(chartOptions) {
        /**
         * @type {ApexOptions}
         */
        var options = {
            chart: {
                type: 'line',
                offsetX: 25,
                toolbar: {
                    tools: {
                        download: false
                    }
                },
                events: {
                    dataPointSelection: chartOptions.onDataPointClick
                }
            },
            zoom: {
                autoScaleYaxis: true
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            markers: {
                size: 6
            },
            title: getTitleConfiguration(chartOptions.chartTitle),
            series: chartOptions.series,
            dataLabels: {
                enabled: chartOptions.showLabels || false
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false
                }
            },
            yaxis: {
                title: {
                    text: chartOptions.yAxisCaption
                }
            },
            legend: getLegendConfiguration(chartOptions.showLegend, chartOptions.legendPosition),
            tooltip: {
                intersect: true,
                shared: false,
                custom: function (chartContext) {
                    return handleCustomLineChartTooltip(chartContext, chartOptions.valueFormatter);
                }
            }
        };

        if (chartOptions.yAxisAnnotations instanceof Array) {
            options.annotations = {
                yaxis: chartOptions.yAxisAnnotations
            };
        }

        if (chartOptions.height) {
            options.chart.height = chartOptions.height;
        }

        if (chartOptions.width) {
            options.chart.width = chartOptions.width;
        }

        return new ApexCharts(chartOptions.element, options);
    }

    /**
     * @param {ApexChartsOptionsWrapper} chartOptions
     * @param {boolean} isStacked
     * @returns {ApexCharts|null}
     */
    function createBarChart(chartOptions, isStacked) {
        var height = isStacked ?
            35 * chartOptions.xAxisCategories.length + 150 :
            chartOptions.height;

        /**
         * @type {ApexOptions}
         */
        var options = {
            chart: {
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false
                },
                events: {
                    dataPointSelection: chartOptions.onDataPointClick
                }
            },
            plotOptions: {
                bar: {
                    horizontal: isStacked
                }
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            title: getTitleConfiguration(chartOptions.chartTitle),
            series: chartOptions.series,
            dataLabels: {
                enabled: chartOptions.showLabels || false
            },
            yaxis: {
                title: {
                    text: chartOptions.yAxisCaption
                }
            },
            legend: getLegendConfiguration(chartOptions.showLegend, chartOptions.legendPosition),
            tooltip: {
                custom: function (chartContext) {
                    return isStacked ?
                        handleCustomStackedBarChartTooltip(chartContext, chartOptions.valueFormatter) :
                        handleCustomBarChartTooltip(chartContext, chartOptions.valueFormatter);
                }
            }
        };

        if (chartOptions.series.length > 1) {
            options.colors = chartOptions.colors;
        } else if (chartOptions.series.length === 1) {
            options.fill = {
                colors: chartOptions.colors
            };
        }

        if (chartOptions.yAxisAnnotations instanceof Array) {
            options.annotations = {
                yaxis: chartOptions.yAxisAnnotations
            };
        }

        if (chartOptions.yAxisTicks) {
            options.yaxis.tickAmount = chartOptions.yAxisTicks;
        }

        if (chartOptions.xAxisCategories instanceof Array &&
            chartOptions.xAxisCategories.length > 0) {
            options.xaxis = {
                categories: chartOptions.xAxisCategories,
                labels: {
                    show: true
                }
            };
        }

        if (isStacked) {
            options.plotOptions.bar.dataLabels = {
                total: {
                    enabled: true,
                    offsetY: 6,
                    offsetX: 5,
                    formatter: function (val, opts) {
                        if (chartOptions.valueFormatter instanceof Function) {
                            return chartOptions.valueFormatter(val);
                        }

                        return val;
                    }
                }
            };
        } else {
            options.labels = chartOptions.labels;

            if (typeof chartOptions.yAxisMaxValue === 'number') {
                options.yaxis.max = chartOptions.yAxisMaxValue;
                options.yaxis.decimalsInFloat = 0;
            }
        }

        if (height) {
            options.chart.height = height;
        }

        if (chartOptions.width) {
            options.chart.width = chartOptions.width;
        }

        return new ApexCharts(chartOptions.element, options);
    }

    /**
     * @param {ApexChartsOptionsWrapper} chartOptions
     * @returns {ApexChartsOptionsWrapper|null}
     */
    function validateChartOptions(chartOptions) {
        if (!chartOptions) {
            return null;
        }

        if (isNaN(chartOptions.yAxisTicks) || chartOptions.yAxisTicks < 1) {
            delete chartOptions.yAxisTicks;
        }

        if (!(chartOptions.yAxisAnnotations instanceof Array) ||
            chartOptions.yAxisAnnotations.length === 0) {
            delete chartOptions.yAxisAnnotations;
        }

        if (chartOptions.valueFormatter && !(chartOptions.valueFormatter instanceof Function)) {
            delete chartOptions.valueFormatter;
        }

        if (chartOptions.onDataPointClick && !(chartOptions.onDataPointClick instanceof Function)) {
            delete chartOptions.onDataPointClick;
        }

        return chartOptions;
    }

    /**
     * Erstellt eine @see ApexCharts-Instanz entsprechend der angegebenen Optionen. Muss im Anschluss mit `.render` gezeichnet werden.
     * @param {ApexChartType} type
     * @param {ApexChartsOptionsWrapper} chartOptions
     * @returns {ApexCharts|null}
     */
    function createChart(type, chartOptions) {
        if (!chartOptions) {
            return null;
        }

        if (!chartOptions.element) {
            return null;
        }

        validateChartOptions(chartOptions);

        switch (type) {
            case apexChartType.Pie:
                return createPieChart(chartOptions);
            case apexChartType.Line:
                return createLineChart(chartOptions);
            case apexChartType.Bar:
            case apexChartType.StackedBar:
                return createBarChart(chartOptions, type === apexChartType.StackedBar);
        }

        return null;
    }

    tools.charts = {
        initLanguage: initLanguage,
        createChart: createChart,
        chartType: apexChartType
    };
})(window.Tools || (window.Tools = {}));