/**
 * @require ./tools.charts.js
 */
(function (chartTools) {
    /**
     *
     * @param {number} from
     * @param {number} to
     * @param {string} bgColor Must be a hex value
     * @param {string} text
     * @returns {YAxisAnnotations|null}
     */
    function createYAxisAnnotation(from, to, bgColor, text) {
        if (typeof from !== 'number') {
            return null;
        }

        if (typeof to !== 'number') {
            return null;
        }

        if (typeof bgColor !== 'string') {
            return null;
        }

        if (typeof text !== 'string') {
            return null;
        }

        return {
            y: from,
            y2: to,
            fillColor: bgColor,
            opacity: 0.4
        };
    }

    /**
     * Erstellt die Annotations für ein Liniendiagramm an Hand der Werteklassendefinition eines Prüfpunkts.
     * @param {Enums.elementType} cpType
     * @param {object[]} evaluation
     * @returns {YAxisAnnotations[]|null}
     */
    function createLineChartAnnotationsFromElementEvaluation(cpType, evaluation) {
        if (!cpType || !(typeof cpType === 'number') || cpType < 100) {
            return null;
        }

        if (!(evaluation instanceof Array)) {
            return null;
        }

        var annotations = [];
        var minValue = -1636448;
        var maxValue = 1636448;

        evaluation
            .filter(function (e) {
                return e.Type === cpType && Properties.hasOwnProperty(e.CategoryOID);
            })
            .forEach(function (e) {
                var category = Properties[e.CategoryOID];
                var from, to;

                if (e.Linking === Enums.linking.And) {
                    switch (e.FirstOperator) {
                        case Enums.operators.Unequal.Value:
                            from = minValue;
                            to = maxValue;
                            break;
                        case Enums.operators.Less.Value:
                        case Enums.operators.LessOrEqual.Value:
                            from = typeof e.SecondValue === 'number' ? e.SecondValue : minValue;
                            to = e.FirstValue;
                            break;
                        case Enums.operators.Greater.Value:
                        case Enums.operators.GreaterOrEqual.Value:
                            from = e.FirstValue;
                            to = typeof e.SecondValue === 'number' ? e.SecondValue : maxValue;
                            break;
                        default:
                            return;
                    }

                    annotations.push(createYAxisAnnotation(from, to, category.Color, category.Title));

                    return;
                }

                switch (e.FirstOperator) {
                    case Enums.operators.Unequal.Value:
                        from = minValue;
                        to = maxValue;
                        break;
                    case Enums.operators.Less.Value:
                    case Enums.operators.LessOrEqual.Value:
                        from = minValue;
                        to = e.FirstValue;
                        break;
                    case Enums.operators.Greater.Value:
                    case Enums.operators.GreaterOrEqual.Value:
                        from = e.FirstValue;
                        to = maxValue;
                        break;
                    default:
                        return;
                }

                annotations.push(createYAxisAnnotation(from, to, category.Color, category.Title));

                switch (e.SecondOperator) {
                    case Enums.operators.Unequal.Value:
                        from = minValue;
                        to = maxValue;
                        break;
                    case Enums.operators.Less.Value:
                    case Enums.operators.LessOrEqual.Value:
                        from = minValue;
                        to = e.SecondValue;
                        break;
                    case Enums.operators.Greater.Value:
                    case Enums.operators.GreaterOrEqual.Value:
                        from = e.SecondValue;
                        to = maxValue;
                        break;
                    default:
                        return;
                }

                annotations.push(createYAxisAnnotation(from, to, category.Color, category.Title));
            });

        return annotations;
    }

    function createColumnChartAnnotationsFromGradingScale(gradingScale) {
        if (!gradingScale) {
            return null;
        }

        var annotations = [];

        if (!gradingScale || !(gradingScale.Gradings || []).length) {
            return null;
        }

        for (var eCnt = 0, eLen = gradingScale.Gradings.length; eCnt < eLen; eCnt++) {
            var grading = gradingScale.Gradings[eCnt];
            var annotation = createYAxisAnnotation(
                grading.From || 0,
                grading.To || 100,
                grading.Color || '#FFF',
                grading.Text
            );

            annotations.push(annotation);
        }

        return annotations;
    }

    chartTools.annotations = {
        line: {
            fromElementEvaluation: createLineChartAnnotationsFromElementEvaluation
        },
        column: {
            fromGradingScale: createColumnChartAnnotationsFromGradingScale
        }
    };
})(window.Tools.charts);