/**
 * @require ./tools.js
 */
(function (tools) {
    tools.InputWindow = (function () {
        var $document = $(document);
        var $inputWindow = $('#input-window');
        var $overflow = $('#input-window-overflow');
        var $title = $inputWindow.find('.window-title');
        var $content = $inputWindow.find('.content');
        var $text = $content.find('.input-window-text');
        var $input = $inputWindow.find(':input');
        var $abort = $inputWindow.find('.btn-abort');
        var $ok = $inputWindow.find('.btn-ok');

        var _options;

        function show(options) {
            _options = options;
            _options.title = _options.title || '';
            _options.text = _options.text || '';
            _options.type = _options.type || 'text';
            _options.value = _options.value || '';
            _options.maxLength = _options.maxLength || 50;
            _options.emptyAllowed = _options.emptyAllowed || false;
            _options.abort = _options.abort || _options.abort !== false && typeof _options.onAbort === 'function';
            _options.onAbort = _options.onAbort || $.noop;
            _options.ok = _options.ok || _options.ok !== false && typeof _options.onOk === 'function';
            _options.onOk = _options.onOk || $.noop;

            init();

            $overflow.removeClass('hide');
            $inputWindow.removeClass('hide');
            $inputWindow.css('margin-top', $inputWindow.outerHeight() / -2);
            $input.focus();
        }

        function hide() {
            $document.off('keydown');

            $inputWindow.addClass('hide');
            $overflow.addClass('hide');
        }

        function init() {
            initLanguage();
            initInputWindow();
            initButtons();
            initEvents();
        }

        function initLanguage() {
            $abort.text(i18next.t('inputWindow.abort'));
            $ok.text(i18next.t('inputWindow.ok'));
        }

        function initInputWindow() {
            $title.html(_options.title);
            $text.html(_options.text);
            $input
                .attr('type', _options.type)
                .val(_options.value)
                .attr('maxlength', _options.maxLength);
        }

        function initButtons() {
            if (_options.abort) {
                $abort.removeClass('hide');
            } else {
                $abort.addClass('hide');
            }

            if (_options.ok) {
                $ok.removeClass('hide');
            } else {
                $ok.addClass('hide');
            }

            if (_options.emptyAllowed || _options.value) {
                $ok.removeAttr('disabled');
            } else {
                $ok.attr('disabled', 'disabled');
            }
        }

        function initEvents() {
            $input.off('keyup change');
            $document.off('keydown');
            $overflow.off('click');
            $abort.off('click');
            $ok.off('click');

            $input.on('keyup change', onChange);
            $document.on('keydown', onKeydown);

            if (_options.abort) {
                $overflow.on('click', onAbort);
                $abort.on('click', onAbort);
            }

            if (_options.ok) {
                $ok.on('click', onOk);
            }
        }

        function onChange() {
            if (_options.emptyAllowed || $input.val().trim()) {
                $ok.removeAttr('disabled')
            } else {
                $ok.attr('disabled', 'disabled');
            }
        }

        function onKeydown(event) {
            switch (event.which) {
                case 27:
                    if (_options.abort) {
                        onAbort();
                    }
                    break;
                case 13:
                    if (_options.ok) {
                        onOk();
                    }
                    break;
            }
        }

        function onAbort() {
            hide();
            _options.onAbort();
        }

        function onOk() {
            var value;

            if ($ok.attr('disabled')) {
                return;
            }

            value = $input.val().trim();

            hide();
            _options.onOk(value);
        }

        return { Show: show, Hide: hide };
    })();

    tools.Message = (function () {
        var $document = $(document);
        var $message = $('#message');
        var $overflow = $('#message-overflow');
        var $title = $message.find('.top .title');
        var $close = $message.find('.top .close');
        var $content = $message.find('.content');
        var $text = $content.find('.message-text');
        var $list = $content.find('.list');
        var $listHeader = $list.find('.list-header');
        var $listContent = $list.find('.list-content');
        var $abort = $message.find('.btn-abort');
        var $no = $message.find('.btn-no');
        var $yes = $message.find('.btn-yes');
        var $delete = $message.find('.btn-delete');
        var $lock = $message.find('.btn-lock');
        var $unlock = $message.find('.btn-unlock');
        var $ok = $message.find('.btn-ok');
        var $logout = $message.find('.btn-logout');

        var _options;
        var _queue = [];

        function show(options) {
            if (isVisible()) {
                _queue.push(options);
                return;
            }

            _options = options;
            _options.title = _options.title || '';
            _options.text = _options.text || '';
            _options.assignments = _options.assignments || [];
            _options.abort = _options.abort || _options.abort !== false && typeof _options.onAbort === 'function';
            _options.onAbort = _options.onAbort || $.noop;
            _options.no = _options.no || _options.no !== false && typeof _options.onNo === 'function';
            _options.onNo = _options.onNo || $.noop;
            _options.yes = _options.yes || _options.yes !== false && typeof _options.onYes === 'function';
            _options.onYes = _options.onYes || $.noop;
            _options.delete = _options.delete || _options.delete !== false && typeof _options.onDelete === 'function';
            _options.onDelete = _options.onDelete || $.noop;
            _options.lock = _options.lock || _options.lock !== false && typeof _options.onLock === 'function';
            _options.unlock = _options.unlock || _options.unlock !== false && typeof _options.onUnlock === 'function';
            _options.onLock = _options.onLock || $.noop;
            _options.onUnlock = _options.onUnlock || $.noop;
            _options.ok = _options.ok || _options.ok !== false && typeof _options.onOk === 'function';
            _options.onOk = _options.onOk || $.noop;
            _options.logout = _options.logout ||_options.logout !== false && typeof _options.onLogout === 'function';
            _options.onLogout = _options.onLogout || $.noop;

            init();

            $overflow.removeClass('hide');
            $message.removeClass('hide');

            if ($listContent.is(':visible')) {
                $listContent.scrollTop(0);
            }

            setPosition();
        }

        function hide() {
            $document.off('keydown');

            $message.addClass('hide');
            $overflow.addClass('hide');

            if (_queue.length) {
                var nextMessage = _queue.shift();

                show(nextMessage);
            }
        }

        function isVisible() {
            return !$message.hasClass('hide');
        }

        function init() {
            initLanguage();
            initMessage();
            initButtons();
            initEvents();
        }

        function initLanguage() {
            $listHeader.find('.textcontent').text(i18next.t('message.selectAll'));
            $listHeader.find('.text-right').text(i18next.t('message.deleteSelectedElements'));

            $abort.text(i18next.t('message.abort'));
            $no.text(i18next.t('message.no'));
            $yes.text(i18next.t('message.yes'));
            $delete.text(i18next.t('message.delete'));
            $lock.text(i18next.t('message.lock'));
            $unlock.text(i18next.t('message.unlock'));
            $ok.text(i18next.t('message.ok'));
            $logout.text(i18next.t('message.logout'));

            if ((_options.captions || []).length) {
                _options.captions.forEach(caption => {
                    let $button;

                    switch (caption.button) {
                        case 'abort':
                            $button = $abort;
                            break;
                        case 'no':
                            $button = $no;
                            break;
                        case 'yes':
                            $button = $yes;
                            break;
                        case 'ok':
                            $button = $ok;
                            break;
                        case 'delete':
                            $button = $delete;
                            break;
                    }

                    $button.text(caption.text);
                });
            }
        }

        function initMessage() {
            $title.html(_options.title);
            $text.html(_options.text);

            if (_options.assignments.length) {
                $listContent.html(renderAssignments());

                $listHeader
                    .find('input:checkbox')
                    .prop('checked', true);

                if (_options.withCheckbox) {
                    $listHeader.find('.element').removeClass('hide');
                } else {
                    $listHeader.find('.element').addClass('hide');
                }

                $list.removeClass('hide');
                $message.css({ 'width': 800, 'margin-left': -400 });
            } else {
                $list.addClass('hide');
                $message.css({ 'width': 500, 'margin-left': -250 });
            }
        }

        function initButtons() {
            if (_options.abort) {
                $abort.removeClass('hide');
            } else {
                $abort.addClass('hide');
            }

            if (_options.no) {
                $no.removeClass('hide');
            } else {
                $no.addClass('hide');
            }

            if (_options.yes) {
                $yes.removeClass('hide');
            } else {
                $yes.addClass('hide');
            }

            if (_options.delete) {
                $delete.removeClass('hide');
            } else {
                $delete.addClass('hide');
            }

            if (_options.lock) {
                $lock.removeClass('hide');
            } else {
                $lock.addClass('hide');
            }

            if (_options.unlock) {
                $unlock.removeClass('hide');
            } else {
                $unlock.addClass('hide');
            }

            if (_options.ok) {
                $ok.removeClass('hide');
            } else {
                $ok.addClass('hide');
            }

            if (_options.logout) {
                $logout.removeClass('hide');
            } else {
                $logout.addClass('hide');
            }

            if (_options.abort || _options.no || _options.ok) {
                $close.removeClass('hide');
            } else {
                $close.addClass('hide');
            }
        }

        function initEvents() {
            $document.off('keydown');
            $close.off('click');
            $overflow.off('click');
            $abort.off('click');
            $no.off('click');
            $yes.off('click');
            $delete.off('click');
            $lock.off('click');
            $unlock.off('click');
            $ok.off('click');
            $logout.off('click');

            $document.on('keydown', onKeydown);

            if (_options.abort) {
                $close.on('click', onAbort);
                $overflow.on('click', onAbort);
                $abort.on('click', onAbort);
            }

            if (_options.no) {
                if (!_options.abort) {
                    $close.on('click', onNo);
                }

                $no.on('click', onNo);
            }

            if (_options.yes) {
                $yes.on('click', onYes);
            }

            if (_options.delete) {
                $delete.on('click', onDelete);
            }

            if (_options.lock) {
                $lock.on('click', onLock);
            }

            if (_options.unlock) {
                $unlock.on('click', onUnlock);
            }

            if (_options.ok) {
                if (!_options.abort && !_options.no) {
                    $close.on('click', onOk);
                }

                $close.on('click', onOk);
                $ok.on('click', onOk);
            }

            if (_options.logout) {
                $logout.on('click', onLogout);
            }
        }

        function setPosition() {
            var $content = $message.find('.content');
            var $footer = $message.find('.bottom');
            var maxHeight = $(window).height() - 40;
            var maxWidth = $(window).width() - 60;
            var headerHeight, footerHeight, maxBodyHeight, windowHeight;

            $message.removeAttr('style');
            $content.removeAttr('style');

            $message.css({
                top: 0,
                left: 0
            });

            $message.css('max-width', maxWidth);

            var contentHeight = parseInt($content.css('padding-top'), 10) + parseInt($content.css('padding-bottom'), 10);
            var $messageText = $content.find('.message-text');
            var $list = $content.find('.list');

            if ($messageText.is(':visible')) {
                contentHeight += $messageText.outerHeight(true);
            }

            if ($list.is(':visible')) {
                contentHeight += $list.outerHeight();
            }

            headerHeight = +$message.find('.top').outerHeight();
            footerHeight = +$footer.outerHeight();
            maxBodyHeight = $(window).height() - (headerHeight + footerHeight) - 60;

            if (maxBodyHeight + headerHeight + footerHeight >= maxHeight) {
                maxBodyHeight = maxHeight - headerHeight - footerHeight - 60;
            }

            $content.css('max-height', maxBodyHeight);

            windowHeight = headerHeight + contentHeight + footerHeight;

            if (windowHeight > maxHeight) {
                windowHeight = maxHeight;
            }

            $message.css('height', windowHeight);

            $message.css({
                'margin-top': -($message.innerHeight() / 2) - 10,
                'margin-left': -($message.innerWidth() / 2)
            });

            $message.css({ top: '50%', left: '50%' });
        }

        function renderAssignments() {
            return _options.assignments
                .map(renderAssignment)
                .join('');
        }

        function renderAssignment(assignment) {
            var html = [];

            html.push('<div class="element">');

            if (_options.withCheckbox) {
                html.push('<input type="checkbox" class="textcheckbox" value="{0}" checked>'.format(assignment.Key));
                html.push('<div class="textcontent"><span>{0}</span></div>'.format(Tools.escapeHtml(assignment.Value)));
            } else {
                html.push('<div class="textcontent"><span>{0}</span></div>'.format(Tools.escapeHtml(assignment)));
            }

            html.push('</div>');

            return html.join('');
        }

        function getCheckedValues() {
            return $.map($listContent.find('input:checked'), function (checkbox) {
                return $(checkbox).val();
            });
        }

        function onKeydown(event) {
            var values;

            switch (event.which) {
                case 27:
                    if (_options.abort) {
                        hide();
                        _options.onAbort();
                    }
                    break;
                case 13:
                    values = getCheckedValues();

                    if (_options.yes) {
                        hide();
                        _options.onYes(values);
                    } else if (_options.delete) {
                        hide();
                        _options.onDelete(values);
                    } else if (_options.lock) {
                        hide();
                        _options.onLock(values);
                    } else if (_options.unlock) {
                        hide();
                        _options.onUnlock(values);
                    } else if (_options.ok) {
                        hide();
                        _options.onOk(values);
                    }
                    break;
            }
        }

        function onAbort() {
            hide();
            _options.onAbort();
        }

        function onNo() {
            hide();
            _options.onNo();
        }

        function onYes() {
            var values = getCheckedValues();

            hide();
            _options.onYes(values);
        }

        function onDelete() {
            var values = getCheckedValues();

            hide();
            _options.onDelete(values);
        }

        function onLock() {
            var values = getCheckedValues();

            hide();
            _options.onLock(values);
        }

        function onUnlock() {
            var values = getCheckedValues();

            hide();
            _options.onUnlock(values);
        }

        function onOk() {
            var values = getCheckedValues();

            hide();
            _options.onOk(values);
        }

        function onLogout() {
            var values = getCheckedValues();

            hide();
            _options.onLogout(values);
        }

        return { Show: show, Hide: hide, IsVisible: isVisible };
    })();

    tools.QRCode = (function () {
        var $qrCodeGenerator = $('#qr-code-generator');
        var $title = $qrCodeGenerator.find('.window-title');
        var $settings = $('#qr-code-settings');
        var $lblQRCodeSize = $('#lblQRCodeSize');
        var $cbQRCodeSize = window.$('#cbQRCodeSize');
        var $lblShowQRCodeTitle = $('#lblShowQRCodeTitle');
        var $showTitle = $('#cbShowQRCodeTitle');
        var $download = $qrCodeGenerator.find('.btn-download');
        var $close = $qrCodeGenerator.find('.btn-close');

        var _data;
        var _title;
        var _showTrustServiceUrl;
        var _qrCode;

        var _qrCodeSettings;

        $qrCodeGenerator.on('click', close);
        $qrCodeGenerator.on('click', '.window-close, .btn-close', close);
        $qrCodeGenerator.on('click', '.gfx-window', stopPropagation);

        function show(data, title, showTrustServiceUrl) {
            if (data) {
                _data = data;
            }

            if (title) {
                _title = title;
            }

            _showTrustServiceUrl = showTrustServiceUrl;

            initLanguage();

            if (_title) {
                _title = Tools.unescapeHtml(_title);
            }

            var scale = parseInt($cbQRCodeSize.val(), 10);
            var moduleCount = 37;
            var sizeModuleTable = {"3":21,"10":25,"20":29,"30":33,"40":37,"54":41,"60":45,"80":49,"94":53,"115":57,"127":61};
            var dataLength = _data.length;
            // Finde richtige Modulgröße für pixelgenaues Rendern
            for (var maxSize in sizeModuleTable) {
                if (dataLength < maxSize) {
                    moduleCount = sizeModuleTable[maxSize];
                    break;
                }
            }

            var logoModuleSize = Math.floor(moduleCount / 3);
            if (logoModuleSize % 2 == 0) {
                // zusätzliche Modulweite hinzufügen, damit Logo sauber zentriert wird
                logoModuleSize++;
            }

            var colorRed = "#701210";
            var colorBlue = '#2c4754';
            _qrCodeSettings = {
                text: _data,
                logo: './img/awenko_App-Logo.svg',
                height: moduleCount * scale,
                width: moduleCount * scale,
                logoWidth: logoModuleSize * scale,
                logoHeight: logoModuleSize * scale,
                dotScale: 1,
                quietZone: 15,
                // farbe: timing
                PO:colorRed,
                PI:colorBlue,
                // farbe: alignment
                AO:colorRed,
                AI:colorBlue,
                // farbe: module
                colorDark : colorBlue
            };

            if ($showTitle.is(':checked')) {
                _qrCodeSettings.title = _title;
                _qrCodeSettings.titleFont = 'bold ' + Math.floor(scale * 2.5) + 'px "Titillium Web"';
                _qrCodeSettings.titleHeight = scale * 2 + 30;
            }

            if (showTrustServiceUrl) {
                $settings.find('.qrCodeURL')
                    .removeClass('hide')
                    .find('input')
                    .val(_data);
            } else {
                $settings.find('.qrCodeURL')
                    .addClass('hide');
            }

            $qrCodeGenerator
                .find('.ui-draggable')
                .css('height', '');

            $qrCodeGenerator
                .removeClass('hide')
                .find('#qr-code-preview')
                .empty();

            _qrCode = new QRCode(document.querySelector('#qr-code-preview'), _qrCodeSettings);

            $settings.find('.qrCodeType .btn-download')
                .off('click.download')
                .on('click.download', function (event) {
                    event.preventDefault();

                    $qrCodeGenerator
                        .find('#qr-code-preview canvas')
                        .get(0)
                        .saveAs(_title + '.png');
                });

            resizeWindow();

            $(window)
                .off('resize.resizeQrCodeWindow')
                .on('resize.resizeQrCodeWindow', resizeWindow);
        }

        function initLanguage() {
            $title.text(i18next.t('qrCode.title'));
            $lblQRCodeSize.text(i18next.t('qrCode.size'));
            $cbQRCodeSize.find('option[data-size="small"]').text(i18next.t('qrCode.sizes.small'));
            $cbQRCodeSize.find('option[data-size="medium"]').text(i18next.t('qrCode.sizes.medium'));
            $cbQRCodeSize.find('option[data-size="large"]').text(i18next.t('qrCode.sizes.large'));
            $cbQRCodeSize.find('option[data-size="very-large"]').text(i18next.t('qrCode.sizes.veryLarge'));
            $lblShowQRCodeTitle.text(i18next.t('qrCode.showTitle'));
            $download.text(i18next.t('qrCode.download'));
            $close.text(i18next.t('qrCode.close'));
        }

        function getShowTrustServiceUrl() {
            return _showTrustServiceUrl;
        }

        function close() {
            _data = null;
            _title = null;
            _showTrustServiceUrl = false;

            $qrCodeGenerator.addClass('hide');
            $(window).off('resize.resizeQrCodeWindow');
        }

        function stopPropagation(event) {
            event.stopPropagation();
        }

        function generate(charCount, prefix, callback, blacklist) /* Deferred */ {
            var qrCode = '';

            if (!!prefix) {
                if (prefix.length > 50 - charCount) {
                    prefix = prefix.substr(0, 50 - charCount - 1);
                }

                qrCode = prefix;
                charCount += prefix.length;
            }

            while (qrCode.length < charCount) {
                // ASCII Bereich 48 - 90 => Zahlen & Großbuchstaben
                var asciiIndex = Math.round((90 - 48) * Math.random() + 48);

                if (asciiIndex >= 48 && asciiIndex <= 57 || asciiIndex >= 65 && asciiIndex <= 90) {
                    qrCode += String.fromCharCode(asciiIndex).toLowerCase();
                }
            }

            if (blacklist && blacklist[qrCode]) {
                return generate(charCount, prefix, callback, blacklist);
            }

            return checkAvailability(qrCode, callback)
                .then(function(isAvailable) {
                    if (Boolean(isAvailable)) {
                        if (callback instanceof Function) {
                            callback(qrCode);
                        }

                        return qrCode;
                    } else {
                        return generate(charCount, prefix, callback, blacklist);
                    }
                });
        }

        function checkAvailability(qrcode) /* Deferred */{
            return Tools.http.get('availability/', null, null, { qrcode: qrcode, type: 0 });
        }

        function getHeight() {
            var $window = $qrCodeGenerator.find('.gfx-window');
            var $content = $window.find('.content');
            var height = parseInt($window.css('border-top-width'), 10) +
                parseInt($window.css('border-bottom-width'), 10) +
                $window.find('.top').outerHeight() +
                $window.find('.bottom').outerHeight() +
                parseInt($content.css('padding-top'), 10) +
                parseInt($content.css('padding-bottom'), 10);

            $content.children().each(function (_idx, child) {
                var $child = $(child);
                var childHeight = $child.outerHeight();
                var childMargin = parseInt($child.css('margin-top'), 10) + parseInt($child.css('margin-bottom'), 10);

                height += Math.ceil(childHeight);
                height += childMargin;
            });

            return height;
        }

        function resizeWindow() {
            var screenHeight = $(window).height();
            var height = getHeight();

            if ((screenHeight - 40) < height) {
                height = screenHeight - 40;
            }

            $qrCodeGenerator.find('.gfx-window').css({
                height: height
            });
        }

        return {
            Show: show,
            Close: close,
            Generate: generate,
            CheckAvailability: checkAvailability,
            GetShowTrustServiceUrl: getShowTrustServiceUrl
        };
    })();

    tools.UsersAndTeamsPicker = (function () {
        var $userPicker = $('#user-picker');
        var $overflow = $('#overflowTools');
        var $nav = $userPicker.find('.nav');
        var $navTitle = $nav.find('.title');
        var $navPrev = $nav.find('.prev');
        var $search = $nav.find('.search');
        var $items = $userPicker.find('.items');
        var $selectedItems = $userPicker.find('.selectedItems');
        var $selectAllItemsRow = $userPicker.find('.select-all-row');
        var $unselectAllItemsRow = $userPicker.find('.unselect-all-row');
        var $currentType = $userPicker.find('.currentType');
        var $save = $userPicker.find('.save');
        var $abort = $userPicker.find('.abort');
        var selectedTeams = [];
        var selectedUsers = [];
        var selectableUsers = [];
        var selectableTeams = [];
        var mode = 'users';
        var teamOID = null;
        var searchText = null;
        var allowedTypes = [ 0, 1, 2 ];
        var hierarchy = { Global: 0, Current: 1, Root: 2 };
        var filters, datasource, locationOID, currentHierarchyType, readOnly;

        function show(settings) {
            if (!(settings || {}).saveFunction) {
                return;
            }

            initLanguage();

            allowedTypes = settings.types;
            filters = settings.filters;
            locationOID = settings.locationOID;
            datasource = settings.datasource || {};
            datasource.teams = datasource.teams || Teams;
            datasource.users = datasource.users || Users;
            readOnly = settings.readOnly === true;

            if (!settings.maximumSelectionCount) {
                maximumSelectionCount = Infinity;
            } else {
                maximumSelectionCount = settings.maximumSelectionCount;
            }

            $nav.addClass('hide');

            if (Client.Settings.ShowGlobalUsersAndTeams || settings.allowGlobalFilter) {
                currentHierarchyType = hierarchy.Global;

                $userPicker.find('.header-item[data-mode="hierarchy"]')
                    .removeClass('global current root')
                    .addClass('global');
            } else {
                currentHierarchyType = hierarchy.Root;

                $userPicker.find('.header-item[data-mode="hierarchy"]')
                    .removeClass('global current root')
                    .addClass('root');
            }

            if (Tools.contains(allowedTypes, 0)) {
                mode = 'users';
            } else if (Tools.contains(allowedTypes, 1)) {
                mode = 'teams';
            } else {
                return;
            }

            $search.val('');
            $currentType
                .removeClass('t v')
                .addClass('p')
                .text(i18next.t('userPicker.user_plural'));

            $userPicker
                .find('.header .users')
                .addClass('hide')
                .siblings()
                .removeClass('hide');

            $save.off('click');
            $abort.off('click');

            if (settings.preSelection) {
                selectedTeams = $.extend(true, [], settings.preSelection.Teams);
                selectedUsers = $.extend(true, [], settings.preSelection.Users);

                if ((selectedUsers.length || selectedTeams.length) && !readOnly) {
                    $unselectAllItemsRow.removeClass('hide');
                }
            }

            initSelectableItems();

            $selectAllItemsRow.find('.btn').on('click', function () {
                var searchText = $.trim($search.val());
                var searchRegEx = !!searchText ? new RegExp(Tools.escRegExp(searchText), 'i') : null;

                if (mode === 'users' || teamOID) {
                    var team = datasource.teams[teamOID];
                    var usersToAdd = [];

                    if (team) {
                        var selectableUserIdentifiers = selectableUsers.map(function (user) {
                            return user.OID;
                        });

                        usersToAdd = team.Users.filter(function (teamMember) {
                            var user = Users[teamMember.OID];

                            if (searchRegEx && !searchRegEx.test(user.Title)) {
                                return false;
                            }

                            return selectableUserIdentifiers.indexOf(user.OID) !== -1 &&
                                selectedUsers.indexOf(user.OID) === -1;
                        }).map(function (user) {
                            return user.OID;
                        });
                    } else {
                        usersToAdd = selectableUsers.filter(function(selectableUser) {
                            var user = Users[selectableUser.OID];

                            if (searchRegEx && !searchRegEx.test(user.Title)) {
                                return false;
                            }

                            return selectedUsers.indexOf(user.OID) === -1;
                        }).map(function (user) {
                            return user.OID;
                        });
                    }

                    selectedUsers = selectedUsers.concat(usersToAdd);
                    pickerGenerateUserView();
                } else if (mode === 'teams') {
                    var teamsToAdd = selectableTeams.filter(function(selectableTeam) {
                        var team = Teams[selectableTeam.OID];

                        if (!team.ParentOID) {
                            return false;
                        }

                        if (searchRegEx && !searchRegEx.test(team.Title)) {
                            return false;
                        }

                        return selectedTeams.indexOf(team.OID) === -1;
                    }).map(function (team) {
                        return team.OID;
                    });

                    selectedTeams = selectedTeams.concat(teamsToAdd);
                    pickerGenerateTeamView(0);
                }
            });

            $unselectAllItemsRow.find('.btn').on('click', function () {
                selectedUsers = [];
                selectedTeams = [];

                if (mode === 'users' || teamOID) {
                    pickerGenerateUserView();
                } else if (mode === 'teams') {
                    pickerGenerateTeamView(0);
                }
            });

            $abort.one('click', function(){
                close(settings);
            });

            if (readOnly) {
                $save.addClass('hide');
            } else {
                $save.removeClass('hide');
                $save.one('click', function () {
                    teamOID = null;
                    searchText = null;
                    settings.saveFunction(selectedUsers, selectedTeams);
                    close();
                });
            }

            $userPicker.on('click.stopPropagation', function (event) { event.stopPropagation(); });
            $overflow.on('click.stopPropagation', function (event) { event.stopPropagation(); });

            $userPicker.removeClass('hide');
            $overflow.removeClass('hide');

            if (maximumSelectionCount > 0 || readOnly) {
                $selectAllItemsRow.addClass('hide');
                $unselectAllItemsRow.addClass('hide');
            }

            if (mode === 'users') {
                pickerGenerateUserView();
            } else {
                pickerGenerateTeamView(mode === 'teams' ? 0 : 1);
            }
        }

        function close(settings) {
            settings = settings || {};
            if(settings.closeFunction){
                settings.closeFunction();
            }
            teamOID = null;
            searchText = null;
            selectedTeams = [];
            selectedUsers = [];

            $userPicker.addClass('hide');
            $overflow.addClass('hide');
        }

        function initLanguage() {
            $search.attr('placeholder', i18next.t('userPicker.enterSearchTerm'));
            $save.text(i18next.t('userPicker.save'));
            $abort.text(i18next.t('userPicker.abort'));
            $selectAllItemsRow.find('.btn .text').text(i18next.t('userPicker.selectAll'));
            $unselectAllItemsRow.find('.btn .text').text(i18next.t('userPicker.unselectAll'));
        }

        function initSelectableItems() {
            var prefiltered = {};
            var filterLocation;

            function getUserRoles(oid) {
                var userRoles = [];

                $.each(datasource.teams || [], function (_, team) {
                    (team.Users || []).forEach(function (teamUser) {
                        if (teamUser.OID === oid) {
                            (teamUser.Roles || []).forEach(function (role) {
                                userRoles = Tools.addUnique(userRoles, role);
                            });
                        }
                    });
                });

                return userRoles;
            }

            function getPrefilter(location) {
                $.each(location.Teams || [], function (_, elemTeam) {
                    var team = datasource.teams[elemTeam.OID];

                    prefiltered.Teams = Tools.addUnique(prefiltered.Teams, team.OID);

                    (team.Users || []).forEach(function (teamUser) {
                        prefiltered.Users = Tools.addUnique(prefiltered.Users, teamUser.OID);
                    });
                });
            }

            selectableUsers = [];
            selectableTeams = [];

            if (currentHierarchyType !== hierarchy.Global) {
                filterLocation = DataManager.OrganizationUnitLoader.Data.DataMap[locationOID] || CurrentEntity;
            }

            if (filterLocation) {
                if (currentHierarchyType === hierarchy.Current) {
                    if ((filterLocation.Teams || []).length) {
                        getPrefilter(filterLocation);
                    }
                } else {
                    while (filterLocation) {
                        getPrefilter(filterLocation);
                        filterLocation = filterLocation.Parent;
                    }
                }
            }

            if (currentHierarchyType === hierarchy.Global || (prefiltered.Users || []).length) {
                if (Tools.contains(allowedTypes, 0)) {
                    $.each(datasource.users || {}, function (_, user) {
                        var userRoles = getUserRoles(user.OID);

                        if (!(filters || {}).ShowLockedUsers && user.IsLocked) {
                            return;
                        }

                        if (!(filters || {}).ShowSystemUsers && (user.IsSystemUser || user.IsExternalUser)) {
                            return;
                        }

                        if (typeof (filters || {}).users === 'function' && !filters.users(user)) {
                            return;
                        }

                        if ((prefiltered || {}).hasOwnProperty('Users') &&
                            !Tools.contains(prefiltered.Users, user.OID)) {
                            return;
                        }

                        if ((filters || {}).hasOwnProperty('Users') &&
                            !Tools.contains(filters.Users, user.OID)) {
                            return;
                        }

                        if ((filters || {}).hasOwnProperty('Roles') && !Tools.any(userRoles, filters.Roles)) {
                            return;
                        }

                        selectableUsers.push(user);
                    });

                    $userPicker.find('.header-item[data-mode="users"]').removeAttr('disabled');
                } else {
                    $userPicker.find('.header-item[data-mode="users"]').attr('disabled', 'disabled');
                }
            }

            if (currentHierarchyType === hierarchy.Global || (prefiltered.Teams || []).length) {
                if (Tools.contains(allowedTypes, 1)) {
                    $.each(datasource.teams || {}, function (_, team) {
                        if (typeof (filters || {}).teams === 'function' && !filters.teams(team)) {
                            return;
                        }

                        if ((prefiltered.Teams || []).length &&
                            !Tools.contains(prefiltered.Teams, team.OID)) {
                            return;
                        }

                        if ((filters || {}).hasOwnProperty('Teams') &&
                            !Tools.contains(filters.Teams, team.OID)) {
                            return;
                        }

                        selectableTeams.push(team);
                    });

                    $userPicker.find('.header-item[data-mode="teams"]').removeAttr('disabled');
                } else {
                    $userPicker.find('.header-item[data-mode="teams"]').attr('disabled', 'disabled');
                }
            }
        }

        function pickerGenerateUserView(closeSearch) {
            var html = [];
            var users = [];
            var tmpSelectedUsers = [];
            var tmpSelectedTeams = [];
            var selectedTeam = teamOID ? datasource.teams[teamOID] : null;
            var regEx;

            if (!teamOID) {
                $currentType
                    .removeClass('teams')
                    .addClass('users')
                    .text(i18next.t('userPicker.users'));
                $userPicker.find('.header-item[data-mode="users"]')
                    .addClass('hide')
                    .siblings()
                    .removeClass('hide');
            }

            if (closeSearch) {
                $nav.addClass('hide');
                $search.addClass('hide').val('');
                searchText = null;
            }

            regEx = searchText ? new RegExp(Tools.escRegExp(searchText), 'ig') : null;

            if (selectedTeam) {
                $nav.removeClass('hide');

                if ($search.hasClass('hide')) {
                    $navTitle.html(Tools.escapeHtml(selectedTeam.Title)).show();
                } else {
                    $navTitle.html(Tools.escapeHtml(selectedTeam.Title)).hide();
                    $search.removeClass('hide').css({ top: -4, width: 270 });
                }

                $navPrev.show();

                $.each(selectedTeam.Users || [], function (_, teamUser) {
                    var userOID = teamUser.OID;

                    if (!Tools.contains(selectableUsers, userOID, 'OID')) {
                        return;
                    }

                    if (datasource.users.hasOwnProperty(userOID) &&
                        !Tools.contains(selectedUsers, userOID)) {
                        users.push(datasource.users[userOID]);
                    }
                });
            } else {
                $.each(selectableUsers || [], function (_, user) {
                    if (!Tools.contains(selectedUsers, user.OID)) {
                        users.push(user);
                    }
                });
            }

            $.each(selectedTeams || [], function (_, oid) {
                if (datasource.teams.hasOwnProperty(oid)) {
                    tmpSelectedTeams.push(datasource.teams[oid]);
                }
            });

            $.each(selectedUsers || [], function (_, oid) {
                if (datasource.users.hasOwnProperty(oid)) {
                    tmpSelectedUsers.push(datasource.users[oid]);
                }
            });

            users.sort(Tools.SortByFirstnameThenLastname);
            tmpSelectedTeams.sort(Tools.SortByTitle);
            tmpSelectedUsers.sort(Tools.SortByTitle);

            $.each(users || [], function (_, user) {
                var title = user.Title;
                var icon = Files[user.ImageOID];

                if (regEx) {
                    if (regEx.test(user.Title)) {
                        title = title.replace(regEx, function (match) {
                            return '<span class="matched">{0}</span>'.format(Tools.escapeHtml(match));
                        });
                    } else {
                        return;
                    }
                }

                var iconPath = icon ? '{0}images/s/{1}'.format(Config.BaseUri, icon.Filename) : './img/user.svg';

                html.push('<li class="item" data-oid="{0}">'.format(user.OID));
                html.push('<div class="icon" style="background-image: url({0});"></div>'.format(iconPath));
                html.push('<span class="title">{0}{1}</span>'.format(title, user.IsLocked
                    ? '<img src="./img/locked.svg" class="user-locked">'
                    : ''));

                if (!readOnly) {
                    html.push('<div class="add"></div>');
                }

                html.push('</li>');
            });

            if (!html.length) {
                $selectAllItemsRow.addClass('hide');

                html.push('<li class="info-row">');
                html.push(i18next.t('userPicker.noUserAvailable'));
                html.push('</li>');
            } else if (!readOnly) {
                $selectAllItemsRow.removeClass('hide');
            }

            $items
                .html(html.join(''))
                .removeClass('hide');

            html = [];
            $.each(tmpSelectedTeams || [], function (_, team) {
                var icon = Files[team.ImageOID];
                var iconPath = icon ? '{0}images/s/{1}'.format(Config.BaseUri, icon.Filename) : './img/team.svg';

                html.push('<li class="item" data-oid="{0}" data-type="team">'.format(team.OID));
                html.push('<div class="icon" style="background-image: url({0});"></div>'.format(iconPath));
                html.push('<span class="title">{0}</span>'.format(Tools.escapeHtml(team.Title)));
                if (!readOnly) {
                    html.push('<div class="remove"></div>');
                }
                html.push('</li>');
            });

            $.each(tmpSelectedUsers || [], function (_, user) {
                var icon = Files[user.ImageOID];
                var iconPath = icon ? '{0}images/s/{1}'.format(Config.BaseUri, icon.Filename) : './img/user.svg';

                html.push('<li class="item" data-oid="{0}" data-type="user">'.format(user.OID));
                html.push('<div class="icon" style="background-image: url({0});"></div>'.format(iconPath));
                html.push('<span class="title">{0}{1}</span>'.format(Tools.escapeHtml(user.Title), user.IsLocked
                    ? '<img src="./img/locked.svg" class="user-locked">'
                    : ''));

                if (user.IsLocked) {
                    html.push();
                }

                if (!readOnly) {
                    html.push('<div class="remove"></div>');
                }

                html.push('</li>');
            });

            if (!html.length) {
                $selectedItems.addClass('hide');
                $unselectAllItemsRow.addClass('hide');
                $items.css('border-bottom', 'none');
            } else {
                $selectedItems.html(html.join(''));
                $selectedItems.removeClass('hide');

                if (!readOnly) {
                    $unselectAllItemsRow.removeClass('hide');
                }

                $items.css('border-bottom', 'none');
            }

            var navHeight = $nav.hasClass('hide') ? 0 : $nav.outerHeight(false);
            var selectAllItemsRowHeight = $selectAllItemsRow.hasClass('hide') ? 0 : $selectAllItemsRow.outerHeight();
            var unselectAllItemsRowHeight = $unselectAllItemsRow.hasClass('hide') ? 0 : $unselectAllItemsRow.outerHeight();

            if (!$items.hasClass('hide') && !$selectedItems.hasClass('hide')) {
                $selectedItems.css({
                    'max-height': 225 - unselectAllItemsRowHeight,
                    bottom: unselectAllItemsRowHeight ? 65 : 41
                });

                $items.css('height', 397 + (unselectAllItemsRowHeight ? 0 : 22) - selectAllItemsRowHeight - $selectedItems.height() - 3 - navHeight);
            } else if ($selectedItems.hasClass('hide')) {
                $items.css('height', 419 - selectAllItemsRowHeight - navHeight);
            } else if ($items.hasClass('hide')) {
                $selectedItems.css({
                    'max-height': 392 - unselectAllItemsRowHeight - navHeight,
                    bottom: unselectAllItemsRowHeight ? 65 : 41
                });
            }
        }

        function pickerGenerateTeamView(type, closeSearch) {
            var html = [];
            var teams = [];
            var tmpSelectedUsers = [];
            var tmpSelectedTeams = [];
            var regEx;

            teamOID = null;

            if (closeSearch) {
                $nav.addClass('hide');
                $('.nav input', $userPicker).val('');
                searchText = null;
            }

            regEx = searchText ? new RegExp(Tools.escRegExp(searchText), 'ig') : null;

            if (type === 0) {
                $currentType
                    .removeClass('users')
                    .addClass('teams')
                    .text(i18next.t('userPicker.teams'));

                $userPicker.find('.header-item[data-mode="teams"]')
                    .addClass('hide')
                    .siblings()
                    .removeClass('hide');

                $.each(selectableTeams || [], function (_, team) {
                    if (!team.hasOwnProperty('ParentOID')) {
                        return;
                    }

                    if (!Tools.contains(selectedTeams, team.OID)) {
                        teams.push(team);
                    }
                });
            }

            $.each(selectedUsers || [], function (_, oid) {
                if (datasource.users.hasOwnProperty(oid)) {
                    tmpSelectedUsers.push(datasource.users[oid]);
                }
            });

            $.each(selectedTeams || [], function (_, oid) {
                if (datasource.teams.hasOwnProperty(oid)) {
                    tmpSelectedTeams.push(datasource.teams[oid]);
                }
            });

            teams.sort(Tools.SortByTitle);
            tmpSelectedTeams.sort(Tools.SortByTitle);
            tmpSelectedUsers.sort(Tools.SortByTitle);

            $.each(teams || [], function (_, team) {
                var title = team.Title;
                var icon = Files[team.ImageOID];
                var iconPath;

                if (regEx) {
                    if (regEx.test(team.Title)) {
                        title = title.replace(regEx, function (match) {
                            return '<span class="matched">{0}</span>'.format(Tools.escapeHtml(match));
                        });
                    } else {
                        return;
                    }
                }

                iconPath = icon ? '{0}images/s/{1}'.format(Config.BaseUri, icon.Filename) : './img/team.svg';

                html.push('<li class="item" data-oid="{0}">'.format(team.OID));
                html.push('<div class="icon" style="background-image: url({0});"></div>'.format(iconPath));

                if (Tools.contains(allowedTypes, 0)) {
                    html.push('<div class="stepinto"></div>');
                }

                html.push('<span class="title">{0}</span>'.format(title));
                if (!readOnly) {
                    html.push('<div class="add"></div>');
                }
                html.push('</li>');
            });

            if (!html.length) {
                $selectAllItemsRow.addClass('hide');

                html.push('<li class="info-row">');
                html.push(i18next.t('userPicker.noTeamAvailable'));
                html.push('</li>');
            } else if (!readOnly) {
                $selectAllItemsRow.removeClass('hide');
            }

            $items
                .html(html.join(''))
                .removeClass('hide');

            html = [];
            $.each(tmpSelectedTeams || [], function (_, team) {
                var icon = Files[team.ImageOID];
                var iconPath = icon ? '{0}images/s/{1}'.format(Config.BaseUri, icon.Filename) : './img/team.svg';

                html.push('<li class="item" data-oid="{0}" data-type="team">'.format(team.OID));
                html.push('<div class="icon" style="background-image: url({0});"></div>'.format(iconPath));
                html.push('<span class="title">{0}</span>'.format(team.Title));
                if (!readOnly) {
                    html.push('<div class="remove"></div>');
                }
                html.push('</li>');
            });

            $.each(tmpSelectedUsers || [], function (_, user) {
                var icon = Files[user.ImageOID];
                var iconPath = icon
                    ? '{0}images/s/{1}'.format(Config.BaseUri, icon.Filename)
                    : './img/user.svg';

                html.push('<li class="item" data-oid="{0}" data-type="user">'.format(user.OID));
                html.push('<div class="icon" style="background-image: url({0});"></div>'.format(iconPath));
                html.push('<span class="title">{0}{1}</span>'.format(Tools.escapeHtml(user.Title), user.IsLocked
                    ? '<img src="./img/locked.svg" class="user-locked">'
                    : ''));

                if (!readOnly) {
                    html.push('<div class="remove"></div>');
                }

                html.push('</li>');
            });

            if (!html.length) {
                $selectedItems.addClass('hide');
                $unselectAllItemsRow.addClass('hide');
                $items.css('border-bottom', 'none');
            } else {
                $selectedItems.html(html.join(''));
                $selectedItems.removeClass('hide');

                if (!readOnly) {
                    $unselectAllItemsRow.removeClass('hide');
                }

                $items.css('border-bottom', 'none');
            }

            var navHeight = $nav.hasClass('hide') ? 0 : $nav.outerHeight(false);
            var selectAllItemsRowHeight = $selectAllItemsRow.hasClass('hide') ? 0 : $selectAllItemsRow.outerHeight();
            var unselectAllItemsRowHeight = $unselectAllItemsRow.hasClass('hide') ? 0 : $unselectAllItemsRow.outerHeight();

            if (!$items.hasClass('hide') && !$selectedItems.hasClass('hide')) {
                $selectedItems.css({
                    'max-height': 225 - unselectAllItemsRowHeight,
                    bottom: unselectAllItemsRowHeight ? 65 : 41
                });

                $items.css('height', 397 + (unselectAllItemsRowHeight ? 0 : 22) - selectAllItemsRowHeight - $selectedItems.height() - navHeight - 3);
            } else if ($selectedItems.hasClass('hide')) {
                $items.css('height', 419 - selectAllItemsRowHeight - navHeight);
            } else if ($items.hasClass('hide')) {
                $selectedItems.css({
                    'max-height': 392 - unselectAllItemsRowHeight - navHeight,
                    bottom: unselectAllItemsRowHeight ? 65 : 41
                });
            }
        }

        $userPicker.on('click', '.add', function () {
            if (selectedUsers.length + selectedTeams.length >= maximumSelectionCount) {
                return;
            }

            var oid = $(this).parent().data('oid');

            if (mode === 'users' || teamOID) {
                selectedUsers.push(oid);
                pickerGenerateUserView();
            } else if (mode === 'teams') {
                selectedTeams.push(oid);
                pickerGenerateTeamView(0);
            }
        });

        $userPicker.on('click', '.stepinto', function () {
            if (mode === 'teams') {
                searchText = null;
                teamOID = $(this).parent().data('oid');
                pickerGenerateUserView(true);
            }
        });

        $userPicker.on('click', '.remove', function () {
            var $this = $(this);
            var oid = $this.parent().data('oid');
            var type = $this.parent().data('type');

            if (type === 'user') {
                selectedUsers.splice($.inArray(oid, selectedUsers), 1);
            } else if (type === 'team') {
                selectedTeams.splice($.inArray(oid, selectedTeams), 1);
            }

            if (mode === 'users' || teamOID) {
                pickerGenerateUserView();
            } else if (mode === 'teams') {
                pickerGenerateTeamView(0);
            }
        });

        $userPicker.on('click', '.prev', function () {
            if (datasource.teams[teamOID]) {
                pickerGenerateTeamView(0, true);
            }

            teamOID = null;
        });

        $userPicker.on('click', '.header-item[data-mode]', function () {
            var $this = $(this);

            if ($this.attr('disabled')) {
                return;
            }

            searchText = null;

            switch ($this.data('mode')) {
                case 'users':
                    $nav.addClass('hide');

                    teamOID = null;
                    mode = 'users';

                    pickerGenerateUserView(true);
                    break;
                case 'teams':
                    $nav.addClass('hide');

                    teamOID = null;
                    mode = 'teams';

                    pickerGenerateTeamView(0, true);
                    break;
                case 'search':
                    if (!teamOID) {
                        $nav.toggleClass('hide');

                        if (!$nav.hasClass('hide')) {
                            $search
                                .removeClass('hide')
                                .css({ top: 0, width: 300 });
                            $search.focus();
                        } else {
                            $search.addClass('hide');
                        }

                        $navTitle.hide();
                        $navPrev.hide();
                    } else {
                        if ($search.hasClass('hide')) {
                            $search
                                .removeClass('hide')
                                .css({ top: -4, width: 270 });
                            $search.focus();
                            $navTitle.hide();
                        } else {
                            $search.addClass('hide');
                            $navTitle.show();
                        }
                    }

                    var navHeight = $nav.hasClass('hide') ? 0 : $nav.outerHeight(false);
                    var selectAllItemsRowHeight = $selectAllItemsRow.hasClass('hide') ? 0 : $selectAllItemsRow.outerHeight();
                    var unselectAllItemsRowHeight = $unselectAllItemsRow.hasClass('hide') ? 0 : $unselectAllItemsRow.outerHeight();

                    if (!$items.hasClass('hide') && !$selectedItems.hasClass('hide')) {
                        $items.css('height', 397 - $selectedItems.height() - selectAllItemsRowHeight - 3 - navHeight);
                        $selectedItems.css('max-height', 225 - unselectAllItemsRowHeight);
                    } else if ($selectedItems.hasClass('hide')) {
                        $items.css('height', 419 - selectAllItemsRowHeight - navHeight);
                    } else if ($items.hasClass('hide')) {
                        $selectedItems.css('max-height', 391 - unselectAllItemsRowHeight - navHeight);
                    }
                    break;
                case 'hierarchy':
                    switch (currentHierarchyType) {
                        case hierarchy.Global:
                            currentHierarchyType = hierarchy.Current;

                            $userPicker.find('.header-item[data-mode="hierarchy"]')
                                .removeClass('global root')
                                .addClass('current');
                            break;
                        case hierarchy.Current:
                            currentHierarchyType = hierarchy.Root;

                            $userPicker.find('.header-item[data-mode="hierarchy"]')
                                .removeClass('global current')
                                .addClass('root');
                            break;
                        case hierarchy.Root:
                            currentHierarchyType = Client.Settings.ShowGlobalUsersAndTeams ? hierarchy.Global : hierarchy.Current;

                            $userPicker.find('.header-item[data-mode="hierarchy"]')
                                .removeClass('global current root')
                                .addClass(Client.Settings.ShowGlobalUsersAndTeams ? 'global' : 'current');
                            break;
                    }

                    initSelectableItems();

                    if (mode === 'users' || teamOID) {
                        pickerGenerateUserView();
                    } else if (mode === 'teams') {
                        pickerGenerateTeamView(0);
                    }
                    break;
            }
        });

        $userPicker.on('keyup', 'input[type=text]', function () {
            var val = $.trim($(this).val());

            if (val.length) {
                searchText = Tools.escapeHtml(val);
            } else {
                searchText = null;
            }

            if (mode === 'users' || teamOID) {
                pickerGenerateUserView();
            } else if (mode === 'teams') {
                pickerGenerateTeamView(0);
            }
        });

        return { Show: show, Close: close };
    })();

    tools.EstimatedEffortEditor = (function () {
        var $estimatedEffortEditor = $('#estimatedEffortEditor');
        var $overflow = $('#overflowTools');
        var $close = $estimatedEffortEditor.find('.close');
        var $btnStandardEfforts = $estimatedEffortEditor.find('.default-efforts li > div');
        var $effortCreator = $estimatedEffortEditor.find('.effort-creator');
        var $cbConsecutiveNumbers = $('#cbConsecutiveNumbers');
        var $btnAbort = $estimatedEffortEditor.find('.btn-abort');
        var $btnApply = $estimatedEffortEditor.find('.btn-apply');
        var $consecutiveNumbers = $effortCreator.find('.consecutiveNumbers');
        var $customNumbers = $effortCreator.find('.customNumbers');
        var $unitPickerBtn = $estimatedEffortEditor.find('.unitPicker > .button-steal');
        var $startInput = $('#estimatedEffortEditor .consecutiveNumbers .start');
        var $endInput = $('#estimatedEffortEditor .consecutiveNumbers .end');
        var $clearListBtn = $('#estimatedEffortEditor .clear-list');
        var selectedUnit, onApply;
        var estimatedEffortSettings;
        var customItem =
            '<li class="custom-item">' +
            '<input type="number" class="customNumberInput">' +
            '<div class="add-delete-buttons">' +
            '<span class="btn-add"></span>' +
            '<span class="btn-delete"></span>' +
            '</div>' +
            '</li>';

        function initLanguage(saveButtonEnabled) {
            $unitPickerBtn.text(i18next.t('changeMode.panels.properties.estimatedEffortEditor.selectUnit'));
            $estimatedEffortEditor.find('h1 > .title').text(i18next.t('changeMode.panels.properties.estimatedEffortEditor.defaultTitle'));
            $estimatedEffortEditor.find('.default-efforts > .content-title').text(i18next.t('changeMode.panels.properties.estimatedEffortEditor.standardList'));
            $estimatedEffortEditor.find('.effort-creator > .content-title').text(i18next.t('changeMode.panels.properties.estimatedEffortEditor.createEffort'));
            $effortCreator.find('.start').attr('placeholder', i18next.t('changeMode.panels.properties.from'));
            $effortCreator.find('.end').attr('placeholder', i18next.t('changeMode.panels.properties.to'));
            $effortCreator.find('.cbTitle').text(i18next.t('changeMode.panels.properties.estimatedEffortEditor.withConsecutiveNumber'));
            $effortCreator.find('.customNumberInput').attr('placeholder', i18next.t('changeMode.panels.properties.number'));
            $clearListBtn.text(i18next.t('changeMode.panels.properties.estimatedEffortEditor.clearList'));

            if (saveButtonEnabled) {
                $btnAbort.text(i18next.t('treePicker.abort'));
                $btnApply.text(i18next.t('treePicker.apply'));
            } else {
                $btnAbort.text(i18next.t('treePicker.close'));
            }
        }

        function unbindEvents() {
            $close.off('click');
            $btnAbort.off('click');
            $btnApply.off('click');
            $unitPickerBtn.off('click');
            $cbConsecutiveNumbers.off('change');
            $endInput.off('keyup.valueChanged');
            $clearListBtn.off('click.clearList');
            $startInput.off('keyup.valueChanged');
            $btnStandardEfforts.off('click.applyStandardEfforts');
            $customNumbers.find('input').off('keyup.valueChanged');
        }

        function bindEvents() {
            $close.on('click', hide);
            $btnAbort.on('click', hide);
            $btnApply.on('click', validateInputsOnApply);
            $unitPickerBtn.on('click', openUnitPicker);
            $clearListBtn.on('click.clearList', onClearListClick);
            $cbConsecutiveNumbers.on('change', onCheckboxChanged);
            $endInput.on('keyup.valueChanged', onInputValueChange);
            $startInput.on('keyup.valueChanged', onInputValueChange);
            $btnStandardEfforts.on('click.applyStandardEfforts', onStandardEffortsClick);
        }

        function show(settings) {
            onApply = settings.onApply;
            estimatedEffortSettings = settings.estimatedEffortSettings;
            var isFunction = onApply instanceof Function;

            initLanguage(isFunction);

            unbindEvents();
            bindEvents();

            if (isFunction) {
                $btnAbort.css('margin-right', '');
                $btnAbort.parent().css('justify-content', '');
                $btnApply.removeClass('hide');
            } else {
                $btnAbort.css('margin-right', 0);
                $btnAbort.parent().css('justify-content', 'flex-end');
                $btnApply.addClass('hide');
            }

            buildEstimatedEffortEditor();

            $estimatedEffortEditor.removeClass('hide');
            $overflow.removeClass('hide');
            $overflow.on('click.hideTreePicker', hide);
        }

        function hide() {
            $estimatedEffortEditor.addClass('hide');
            $overflow.addClass('hide');

            $overflow.off('click.hideTreePicker');
        }

        function buildEstimatedEffortEditor() {
            if (!estimatedEffortSettings) {
                estimatedEffortSettings = content.CurrentEntity.Settings.EstimatedEffortSettings || changemode.Client.Settings.EstimatedEffortSettings;

                if (!estimatedEffortSettings) {
                    clearInputs();
                    resetUnit();
                    return;
                }
            }

            var range = estimatedEffortSettings.Range || [];
            var rangeLength = range.length;

            selectedUnit = changemode.Properties[estimatedEffortSettings.UnitOID];

            if (selectedUnit) {
                $unitPickerBtn.html(i18next.t('misc.unit') + ': ' + selectedUnit.Title);
                $unitPickerBtn.data('oid', estimatedEffortSettings.UnitOID);
            }

            clearInputs();

            if (estimatedEffortSettings && rangeLength > 0) {
                if (estimatedEffortSettings.ConsecutiveNumbers) {
                    changeConsecutiveNumbersCheckboxValue(true);

                    $startInput.val(range[0]);

                    if (rangeLength > 1) {
                        $endInput.val(range[rangeLength - 1]);
                    }
                } else if (estimatedEffortSettings.CustomItems) {
                    changeConsecutiveNumbersCheckboxValue(false);

                    $customNumbers.empty();

                    for (var i = 0; i < rangeLength; i++) {
                        addEstimatedEffort(range[i]);
                    }
                }
            }

            var $customNumberInputs = $customNumbers.find('li');
            var customNumberInputsLength = $customNumberInputs.length;

            if (customNumberInputsLength > 1 && customNumberInputsHasValues()) {
                for (var i = 0; i < customNumberInputsLength; i++) {
                    var $listItem = $customNumberInputs.eq(i);

                    if (!$listItem.find('input').val()) {
                        $customNumberInputs.eq(i).remove();
                    }
                }
            }

            if (estimatedEffortSettings.CustomItems) {
                changeConsecutiveNumbersCheckboxValue(false);
            }
        }

        function onClearListClick() {
            clearInputs();
        }

        function changeConsecutiveNumbersCheckboxValue(value) {
            $cbConsecutiveNumbers.prop('checked', value);
            $cbConsecutiveNumbers.trigger('change');
        }

        function onStandardEffortsClick() {
            var $btn = $(this);
            var effortID = $btn.data('id');
            var effort = changemode.Client.EstimatedEffortList[effortID];
            var defaultRangeString = (effort.Title || '').replace(/\s/g, "");
            var range = defaultRangeString.split(/[-;]/);

            for (var i = 0; i < (range || []).length; i++) {
                range[i] = parseInt(range[i], 10);
            }

            clearInputs();

            if (range.length === 2) {
                changeConsecutiveNumbersCheckboxValue(true);

                $startInput.val(range[0]);
                $endInput.val(range[1]);

                return;
            }

            $customNumbers.empty();
            changeConsecutiveNumbersCheckboxValue(false);

            for (var j = 0; j < (range || []).length; j++) {
                addEstimatedEffort(range[j]);
            }
        }

        function clearInputs() {
            $startInput.val('');
            $endInput.val('');
            $startInput.removeClass("input-required");
            $endInput.removeClass("input-required");
            $customNumbers.empty();
            addEstimatedEffort();
        }

        function validateInputsOnApply() {
            if (!$cbConsecutiveNumbers.prop('checked')) {
                validateCustomNumbers();
                return;
            }

            var startValue = $startInput.val();
            var endValue = $endInput.val();

            $startInput.toggleClass('input-required', !startValue);
            $endInput.toggleClass('input-required', !endValue);

            if (endValue && startValue) {
                onApply();
            }
        }

        function validateCustomNumbers() {
            var listHasValues = false;

            if ($customNumbers.find('li input').length >= 1) {
                listHasValues = customNumberInputsHasValues();
            }

            var $customNumber = $customNumbers.find('li input');

            if (!listHasValues) {
                $customNumber.addClass('input-required');
                return;
            }

            $customNumber.removeClass('input-required');

            onApply();
        }

        function customNumberInputsHasValues() {
            var listLength = $customNumbers.find('li input').length;
            var value, hasInputs = false;

            for (var i = 0; i < listLength; i++) {
                value = $customNumbers.find('li input').eq(i).val();

                if (!value) {
                    continue;
                }

                hasInputs = true;
            }

            return hasInputs;
        }

        function addEstimatedEffort(value, that) {
            var $this = $(that);

            if ($this.hasClass('customNumberInput')) {
                $this.parent().after(customItem);
                $this.parent().next().find('input').focus();
            } else if ($(this).hasClass('btn-add')) {
                $(this).parent().parent().after(customItem);
            } else {
                $customNumbers.append(customItem);

                if (value || value === 0) {
                    $customNumbers.find('li').last().find('input').val(value);
                }
            }

            $customNumbers.find('.btn-delete').toggleClass('hide', $customNumbers.find('li').length === 1);

            $customNumbers.find('input').off('keyup.enterPressed');
            $customNumbers.find('input').off('keyup.valueChanged');
            $customNumbers.find('.btn-add').off('click.addItem');
            $customNumbers.find('.btn-delete').off('click.deleteItem');
            $customNumbers.find('input').on('keyup.valueChanged', onInputValueChange);
            $customNumbers.find('.btn-add').on('click.addItem', addEstimatedEffort);
            $customNumbers.find('.btn-delete').on('click.deleteItem', deleteEstimatedEffortItem);
            $customNumbers.find('input').on('keyup.enterPressed', onAfterEnterPressed);
            $effortCreator.find('.customNumberInput').attr('placeholder', i18next.t('changeMode.panels.properties.number'));
        }

        function onAfterEnterPressed(evt) {
            var $input = $(this);
            var $nextInput = $input.parent().next().find('.customNumberInput');

            if ($nextInput.length > 0 && !$nextInput.val()) {
                return;
            }

            if ($input.val() && evt.keyCode === 13) {
                addEstimatedEffort('', this);
            } else {
                $input.removeClass('input-required');
            }
        }

        function onInputValueChange() {
            $(this).removeClass('input-required');
        }

        function deleteEstimatedEffortItem() {
            var $btn = $(this);
            var $item = $btn.parent().parent();

            if ($item.siblings().length === 0) {
                return;
            } else if ($item.siblings().length === 1) {
                $item.siblings().find('.btn-delete').addClass('hide');
            }

            $item.remove();
        }

        function openUnitPicker() {
            var $btn = $(this);
            var propertyOID = $btn.data('oid');
            var options;

            options = {
                title: i18next.t('changeMode.panels.properties.estimatedEffortEditor.selectUnit'),
                selectMultiple: false,
                onSelect: function (propertyOID) {
                    var unit = changemode.Properties[propertyOID] || {};
                    var unitTitle = unit.Title || '';

                    $unitPickerBtn.html(i18next.t('misc.unit') + ': ' + unitTitle);
                    $unitPickerBtn.data('oid', propertyOID);
                    selectedUnit = unit;
                },
                treeOptions: {
                    schema: { id: 'OID', children: 'Children', text: 'Title'},
                    opened: true,
                    selectable: true,
                    selected: propertyOID
                }
            };

            options.customActionButton = {
                title: i18next.t('changeMode.panels.properties.estimatedEffortEditor.noUnit'),
                onClick: function () {
                    resetUnit();

                    TreePicker.Close();
                }
            };

            TreePicker.Show(changemode.Units, options);
        }

        function resetUnit() {
            $unitPickerBtn.text(i18next.t('changeMode.panels.properties.estimatedEffortEditor.selectUnit'));
            $unitPickerBtn.data('oid', '');
            selectedUnit = null;
        }

        function onCheckboxChanged() {
            var cb = $(this);

            if (cb == null) {
                return;
            }

            var isChecked = cb.prop('checked');

            $consecutiveNumbers.toggleClass('hide', !isChecked);
            $customNumbers.toggleClass('hide', isChecked);
        }

        function getUnit() {
            return selectedUnit;
        }

        function getEstimatedEffort() {
            var withConsecutiveNumbers = !$consecutiveNumbers.hasClass('hide');
            var withCustomNumbers = !$customNumbers.hasClass('hide');
            var $inputs, inputValue, values = [];
            var unit = getUnit();
            var unitOID = (unit || {}).OID;

            function iterateInputs() {
                for (var i = 0; i < $inputs.length; i++) {
                    inputValue = parseInt($inputs.eq(i).val(), 10);

                    if (isNaN(inputValue)) {
                        continue;
                    }

                    if (Tools.contains(values, inputValue)) {
                        continue;
                    }

                    values.push(inputValue);
                }
            }

            if (withConsecutiveNumbers) {
                $inputs = $consecutiveNumbers.find('input');

                iterateInputs();

                return {
                    Range: values,
                    ConsecutiveNumbers: withConsecutiveNumbers,
                    UnitOID: unitOID
                };
            }

            if (withCustomNumbers) {
                $inputs = $customNumbers.find('input');

                iterateInputs();

                return {
                    Range: values,
                    CustomItems: withCustomNumbers,
                    UnitOID: unitOID
                };
            }
        }

        return {
            Show: show,
            Hide: hide,
            GetUnit: getUnit,
            GetCustomEstimatedEffort: getEstimatedEffort
        };
    })();
})(window.Tools || (window.Tools = {}));