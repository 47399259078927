/**
 * @require ./_base.js
 */
(function (global) {
    /**
     * @constructor
     */
    const FontLoader = function () {};

    FontLoader.prototype.constructor = FontLoader;

    /**
     * Lädt die angeforderten Daten vom Server und cached diese.
     */
    FontLoader.prototype.LoadAll = function () {
        const deferred = $.Deferred();

        Tools.http.get('pdf-templates/fonts', fonts => {
            this.Data = {
                Timestamp: new Date(),
                Data: fonts
            };

            deferred.resolve(createResponse.call(this, this.Data, true));
        }, xhr => {
            this.Data = {
                Timestamp: new Date(),
                Data: null
            };

            deferred.reject(xhr);
        });

        return deferred.promise();
    };

    /**
     * Prüft, ob die Daten bereits geladen wurden und greift ggf. auf den eigenen Cache zurück.
     */
    FontLoader.prototype.GetAll = function () {
        const deferred = $.Deferred();

        if (this.Data != null) {
            deferred.resolve(createResponse.call(this, this.Data, false));
        } else {
            this.LoadAll()
                .then(deferred.resolve, deferred.reject);
        }

        return deferred.promise();
    };

    /**
     * Entfernt die gecachten Daten des DataLoaders.
     */
    FontLoader.prototype.Reset = function () {
        delete this.Data;
    };

    /**
     * @param {object} data
     * @param {boolean} loadedFromServer
     * @returns {{Data: Object[], Timestamp: Date, LoadedFromServer: Boolean}|null}
     */
    function createResponse(data, loadedFromServer) {
        if (!data) {
            return null;
        }

        const response = {
            Data: data.Data,
            Timestamp: data.Timestamp,
            LoadedFromServer: loadedFromServer
        };

        if (data.Root) {
            response.Root = data.Root;
        }

        return response;
    }

    global.FontLoader = FontLoader;
})(window.Tools || (window.Tools = {}));