/**
 * @require ./tools.js
 */
(function (tools) {
    tools.values = (function () {
        var recItm;
        var element;

        function render(recorditem, settings) {
            settings = settings || {};

            if (settings.escapeValue == null) {
                settings.escapeValue = true;
            }

            recItm = recorditem;
            if (recItm) {
                if (recItm.Element) {
                    element = recItm.Element;
                } else if (settings.elements && recItm.ElementOID) {
                    element = settings.elements[recItm.ElementOID];
                }
            }

            var html = [];

            html.push('<div class="recitemValue"{0}{1}>'
                .format(
                    settings.alignLeft ? ' style="text-align: left;"' : '',
                    settings.addElementType && element ? ' element-type="' + element.Type + '"' : ''
                )
            );

            if (recItm) {
                if (settings.getRawString) {
                    return (renderValue(settings) || '-/-').toString();
                } else {
                    if (!settings.suppressCategory) {
                        html.push(renderCategory(settings));
                    }

                    html.push(renderValue(settings));

                    if (!settings.suppressInfos) {
                        html.push(renderInfos(settings));
                    }
                }
            } else {
                if (settings.getRawString) {
                    return '-/-';
                } else {
                    html.push('-/-');
                }
            }

            html.push('</div>');

            return html.join('');
        }

        function renderValue(settings) {
            var value = (recItm || {}).Value;
            var unit;
            var result;
            var structure;
            var tmp;
            var users;
            var teams;

            if (!element || (typeof value === 'undefined' && element.Type !== Enums.elementType.Checkbox)) {
                return '-/-';
            }

            switch (element.Type) {
                case Enums.elementType.Checkbox:
                    if (/true/i.test(value)) {
                        result = settings.getRawString ? i18next.t('misc.yes') : '<img src="./img/checked.svg" style="width:{0}px; height:{0}px;" />'
                            .format(settings.isPrint || settings.scaledDown ? 25 : 50);
                    } else if (/false/i.test(value)) {
                        result = settings.getRawString ? i18next.t('misc.no') : '<img src="./img/crosschecked.svg" style="width:{0}px; height:{0}px;" />'
                            .format(settings.isPrint || settings.scaledDown ? 25 : 50);
                    } else {
                        result = settings.getRawString ? '-/-' : '<img src="./img/unchecked.svg" style="width:{0}px; height:{0}px;" />'
                            .format(settings.isPrint || settings.scaledDown ? 25 : 50);
                    }
                    break;
                case Enums.elementType.Number:
                    if (settings.IsSurveyValue) {
                        switch (parseInt(value, 10)) {
                            case 0:
                                result = '-/-';
                                break;
                            case 1:
                                result = i18next.t('formsOfRepresentation.veryBad');
                                break;
                            case 2:
                                result = i18next.t('formsOfRepresentation.bad');
                                break;
                            case 3:
                                result = i18next.t('formsOfRepresentation.satisfactory');
                                break;
                            case 4:
                                result = i18next.t('formsOfRepresentation.good');
                                break;
                            case 5:
                                result = i18next.t('formsOfRepresentation.veryGood');
                                break;
                        }
                    } else {
                        result = value;

                        if (!/(^[<>][+-]?\d+(\.\d+)?$)|(^[<>]?[+-]?\d+(\.\d+)?e[+-]?\d+(\.\d+)?$)|(^n{2}$)|(^ni$)|(^n\.(n\.|i\.)$)/.test(result)) {
                            result = parseFloat(result).toFixed(element.Decimals || 0);
                            result = result.toString().replace('.', ',');
                        }

                        unit = element.Unit || Properties[element.UnitOID];

                        if (unit) {
                            result += ' {0}'.format(unit.Description || unit.Title);
                        }
                    }
                    break;
                case Enums.elementType.Date:
                    result = Tools.dateTime.dateToString(new Date(value));
                    break;
                case Enums.elementType.Time:
                    result = Tools.dateTime.timeToString(new Date(value));
                    break;
                case Enums.elementType.Photo:
                case Enums.elementType.Signature:
                {
                    if (settings.getRawString) {
                        result = value;
                    } else {
                        var html = [
                            '<img {2}src="{0}images/s/{1}" {3} {4}/>'.format(
                                Config.BaseUri,
                                value,
                                settings.lazyLoadImage ? 'data-' : '',
                                recItm.AdditionalValueInfo && recItm.AdditionalValueInfo.Marks ? 'onload="Tools.FixMarksSize(this)"' : '',
                                settings.addFilenameToTag ? ' data-filename="' + value + '"' : ''
                            )
                        ];

                        if (recItm.AdditionalValueInfo && recItm.AdditionalValueInfo.Marks) {
                            html.unshift('<div class="image-container">')

                            var fixedSVG = Tools.FixSVGMetrics(recItm.AdditionalValueInfo.Marks);

                            html.push(fixedSVG);
                            html.push('</div>');
                        }

                        result = html.join('');
                    }
                }
                    break;
                case Enums.elementType.LocationCode:
                    if (Tools.IsValidGuid(value) && DataManager.OrganizationUnitLoader.Data.DataMap[value]) {
                        result = DataManager.OrganizationUnitLoader.Data.DataMap[value].Title;
                    }
                    break;
                case Enums.elementType.ListBox:
                    structure = element.Structure;

                    if (!Object.keys(structure || {}).length || !(result = structure[parseInt(value, 10)])) {
                        return '-/-';
                    }

                    if (element.hasOwnProperty('AdditionalSettings')
                        && element.AdditionalSettings.MapStructureToImages
                        && !settings.getRawString)
                    {
                        tmp = Files[result];

                        if (tmp) {
                            result = '<img {3}src="{0}images/s/{1}"{2}/>'.format(
                                Config.BaseUri,
                                tmp.Filename,
                                settings.addFilenameToTag ? ' data-filename="' + tmp.Filename + '"' : '',
                                settings.lazyLoadImage ? 'data-' : '');
                        }
                    } else {
                        result = settings.escapeValue ? Tools.escapeHtml(result) : result;
                    }
                    break;
                case Enums.elementType.MultiListBox:
                    structure = element.Structure;
                    result = [];

                    if (!Object.keys(structure || {}).length || !(value || []).length || !(value instanceof Array)) {
                        return '-/-';
                    }

                    value.sort(function (a,b) {
                        return a - b;
                    });

                    $.each(value || [], function (idx, val) {
                        var res;

                        if (structure.hasOwnProperty(val)) {
                            res = structure[val];

                            if (element.hasOwnProperty('AdditionalSettings')
                                && element.AdditionalSettings.MapStructureToImages
                                && !settings.getRawString)
                            {
                                tmp = Files[res];

                                if (tmp) {
                                    res = '<img {3}src="{0}images/s/{1}"{2} />'.format(Config.BaseUri
                                        , tmp.Filename
                                        , settings.addFilenameToTag ? ' data-filename="' + tmp.Filename + '"' : ''
                                        , settings.lazyLoadImage ? 'data-' : '');
                                    result.push(res);
                                }
                            } else {
                                result.push(settings.escapeValue ? Tools.escapeHtml(res) : res);
                            }
                        }
                    });

                    if (!(result || []).length) {
                        return '-/-';
                    }

                    result = result.join('<br>');
                    break;
                case Enums.elementType.UsersAndTeams:
                    tmp = typeof value === 'string' ? JSON.parse(value) : value;
                    result = [];

                    users = $.map(tmp.Users || [], function (oid) {
                        if (Users.hasOwnProperty(oid) &&
                            Users[oid].Title) {
                            return Users[oid].Title;
                        }
                    });

                    teams = $.map(tmp.Teams || [], function (oid) {
                        if (Teams.hasOwnProperty(oid)) {
                            return Teams[oid].Title;
                        }
                    });

                    if (users.length) {
                        if (settings.valuesAsList) {
                            result.push(users.join('<br>'));
                        } else {
                            result.push('{0}: '.format(i18next.t('misc.user_plural')));

                            if (users.length > 1) {
                                result.push(users.slice(0, -1).join(', '));
                                result.push(' {0} '.format(i18next.t('misc.and')));
                            }

                            result.push(users[users.length - 1]);
                        }

                        if (teams.length) {
                            result.push('<br />');
                        }
                    }

                    if (teams.length) {
                        result.push('{0}: '.format(i18next.t('misc.team_plural')));

                        if (settings.valuesAsList) {
                            result.push(teams.join('<br>'));
                        } else {
                            if (teams.length > 1) {
                                result.push(teams.slice(0, -1).join(', '));
                                result.push(' {0} '.format(i18next.t('misc.and')));
                            }

                            result.push(teams[teams.length -1]);
                        }

                    }

                    if (!(result || []).length) {
                        return i18next.t('misc.notRecorded');
                    }

                    result = result.join('');

                    break;
                case Enums.elementType.IndividualData:
                    result = [];

                    if (Object.keys(value).length) {
                        for (var key in value) {
                            tmp = IndividualData.GetSchema(key);

                            if (!tmp) {
                                continue;
                            }

                            var valueStrings = $.map(value[key], function (v) {
                                return IndividualData.GetEntityTitle(tmp, v);
                            });

                            if (settings.valuesAsList) {
                                result.push(valueStrings.join('<br>'));
                            } else {
                                result.push(valueStrings.join(', '));
                            }
                        }
                    }

                    if (!result.length) {
                        return '-/-';
                    }

                    result = result.join('<br />').replace(/\\n/ig, '<br />');

                    break;
                case Enums.elementType.EMailAddresses:
                    try {
                        tmp = value instanceof Array ? value : JSON.parse(value);
                    }
                    catch (ex) {
                        tmp = null;
                    }

                    if (!(tmp instanceof Array) || tmp.length === 0) {
                        return '-/-';
                    }

                    result = tmp.join(', ');

                    break;
                case Enums.elementType.Files:
                    if (settings.getRawString) {
                        return value instanceof Array ? JSON.stringify(value) : null;
                    }

                    result = value instanceof Array && value.length ? value
                        .sort(Tools.SortByTitle)
                        .map(function (file) {
                            var markup = [
                                '<div class="file-recorditem-row" data-filename="', file.Filename, '" data-mimetype="', file.MimeType, '">',
                                    '<div class="file-recorditem-file-title">', DOMPurify.sanitize(file.Title), '</div>',
                            ];

                            if (!!file.Description) {
                                markup.push(
                                    '<div class="file-recorditem-file-description">',
                                        DOMPurify.sanitize(Tools.escapeHtml(file.Description)),
                                    '</div>'
                                );
                            }

                            markup.push('</div>');

                            return markup.join('');
                        }).join('') : null;
                    break;
                default:
                    if (typeof value !== 'string') {
                        return '-/-';
                    }

                    if (settings.escapeValue) {
                        value = value.replace(/<br( \/)?>/gm, '\n');
                    }

                    result = settings.escapeValue ? Tools.escapeHtml(value) : value;
            }

            return result;
        }

        function renderCategory(settings) {
            var category = recItm.Category || Properties[recItm.CategoryOID];
            var html = [];

            if (!category) {
                return '';
            }

            if (!settings.categoryInline) {
                html.push('<div class="category">');
            }

            if (settings.isPrint) {
                html.push('<div style="height: 8px; width: 8px; padding: 0; display: inline-block;">');
                html.push('<div style="border: 4px solid {0};"></div>'.format(category.Color));
                html.push('</div>');
            } else {
                if (!settings.categoryInline) {
                    html.push('<div class="color" style="background-color: {0};"></div>'.format(category.Color));
                } else {
                    html.push('<span class="color" style="background-color: {0}; vertical-align: top;"></span>'.format(category.Color));
                }
            }

            if (!settings.categoryInline) {
                html.push('</div>');
            }

            return html.join('');
        }

        function renderInfos(settings) {
            if (!recItm) {
                return '';
            }

            var html = [];
            var hasWorkflow = Boolean(recItm.WorkflowInformation) || recItm.HasCorrectiveActions;

            if (recItm.HasAdditionalFiles || recItm.HasComments || recItm.AdditionalText || hasWorkflow || recItm.Revision > 1) {
                html.push('<div class="infos"', (settings.alignLeft ? ' style="text-align: left;"' : ''), ' >');

                if (recItm.Revision > 1) {
                    if (settings.isPrint) {
                        html.push('<img src="./img/pencil.svg" class="icon" /> {0}'.format(recItm.Revision - 1));
                    } else {
                        html.push('<div class="icon" style="background-image: url(./img/pencil.svg);"></div> {0}'.format(recItm.Revision - 1));
                    }
                }

                if (recItm.HasAdditionalFiles) {
                    if (settings.isPrint) {
                        html.push('<img src="./img/attachment-icon.svg" class="icon" />');
                    } else {
                        html.push('<div class="icon" style="background-image: url(./img/attachment-icon.svg);"></div>');
                    }
                }

                if (recItm.HasComments || recItm.AdditionalText) {
                    if (settings.isPrint) {
                        html.push('<img src="./img/chat-bubble-dots.svg" class="icon" />');
                    } else {
                        html.push('<div class="icon" style="background-image: url(./img/chat-bubble-dots.svg);"></div>');
                    }
                }

                if (hasWorkflow) {
                    if (settings.isPrint) {
                        html.push('<img src="./img/hammer.svg" class="icon" />');
                    } else {
                        html.push('<div class="icon" style="background-image: url(./img/hammer.svg);"></div>');
                    }
                }

                html.push('</div>');
            }

            return html.join('');
        }

        return {
            render: render
        };
    })();
})(window.Tools || (window.Tools = {}));