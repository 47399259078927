/**
 * @require ./tools.js
 */
(function (global) {
    var Spinner = {};

    var _$spinner = $('#spinner');
    var _timeout;

    Spinner.show = function (delay) {
        if (_timeout) {
            window.clearTimeout(_timeout);
        }

        _$spinner.find('.spinner-text').text(i18next.t('spinner.pleaseWait'));

        if (delay && window.focused) {
            _timeout = window.setTimeout(function () {
                _$spinner.removeClass('hide');
            }, delay);
        } else {
            _$spinner.removeClass('hide');
        }
    };

    Spinner.hide = function () {
        if (_timeout) {
            window.clearTimeout(_timeout);
        }

        _$spinner.addClass('hide');
    };

    Spinner.updateText = function (newText) {
        if (!newText || !_$spinner.is(':visible')) {
            return;
        }

        _$spinner.find('.spinner-text').html(newText);
    };

    return (global.Spinner = Spinner);
})(window.Tools || (window.Tools = {}));