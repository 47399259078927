/**
 * @require ./tools.js
 */
(function (tools) {
    function isImage(mimeType) {
        if (!mimeType) {
            return false;
        }

        return mimeType.startsWith('image/') && mimeType !== 'image/svg';
    }

    function isAudio(mimeType) {
        if (!mimeType) {
            return false;
        }

        return mimeType.startsWith('audio/');
    }

    function isVideo(mimeType) {
        if (!mimeType) {
            return false;
        }

        return mimeType.startsWith('video/');
    }

    function isPdf(mimeType) {
        if (!mimeType) {
            return false;
        }

        return mimeType.contains('pdf');
    }

    function isText(mimeType) {
        if (!mimeType) {
            return false;
        }

        return mimeType === 'text/plain' ||
            mimeType.contains('msword') || mimeType.contains('wordprocessingml') ||
            mimeType.startsWith('application/vnd.oasis.opendocument.text');
    }

    function isSpreadsheet(mimeType) {
        if (!mimeType) {
            return false;
        }

        return mimeType === 'text/csv' ||
            mimeType.startsWith('application/vnd.ms-excel') ||
            mimeType.contains('spreadsheetml') ||
            mimeType.startsWith('application/vnd.oasis.opendocument.spreadsheet');
    }

    function isPresentation(mimeType) {
        if (!mimeType) {
            return false;
        }

        return mimeType.contains('ms-powerpoint') || mimeType.contains('presentationml') ||
            mimeType.startsWith('application/vnd.oasis.opendocument.presentation');
    }

    function isArchive(mimeType) {
        if (!mimeType) {
            return false;
        }

        return mimeType === 'application/zip' ||
            mimeType === 'application/x-zip-compressed' ||
            mimeType === 'application/x-7z-compressed' ||
            mimeType === 'application/vnd.rar' ||
            mimeType === 'application/tar' ||
            mimeType === 'application/x-tar' ||
            mimeType === 'application/gzip' ||
            mimeType.startsWith('application/x-bzip');
    }

    function loadUsageInformation(fileIdentifiers) {
        if (!(fileIdentifiers instanceof Array) ||
            !fileIdentifiers.length) {
            return $.Deferred().reject('no file identifiers given').promise();
        }

        var payload = {
            Identifiers: fileIdentifiers,
            EntityType: 3 // TODO Enum
        };

        return Tools.http.post('masterdata/usage-information', payload);
    }

    function deleteFile(oid) {
        if (!oid) {
            return $.Deferred().reject('no identifier given').promise();
        }

        // TODO Prüfung auf API umstellen

        var file = changemode.Files[oid];
        var elementAssignments = [];
        var userAssignments = [];
        var teamAssignments = [];
        var customMenuItemsAssignments = [];
        var mailTemplateAssignments = [];
        var cannotBeDeleted = false;
        var options = {};
        var filenameRegex;

        if (!file) {
            return $.Deferred().reject('file not found').promise();
        }

        function onDelete() {
            function deleteFile() {
                Tools.http.del('files/' + file.OID, function () {
                    var folder = changemode.Folders[file.FolderOID];

                    if (folder) {
                        folder.Files = Tools.remove(folder.Files, file.OID, 'OID');

                        folder.Files.sort(function (a, b) {
                            return (a.Position || 0) - (b.Position || 0);
                        });
                    }

                    file.ModificationType = Enums.ModificationType.Deleted;

                    Commit.setChanged(true);
                    content.views.createFileTable();
                    setChangeModeTabCounter();

                    Tools.Spinner.hide();
                }, function (xhr) {
                    Tools.Spinner.hide();
                    Tools.handleHttpError(Enums.HttpActionType.Delete, xhr);
                });
            }

            function updateElements() {
                var updatedElements = [];

                elementAssignments.forEach(function (element) {
                    if (element.ImageOID === file.OID) {
                        element.ImageOID = null;
                    }

                    element.Files = Tools.remove(element.Files, file.OID, 'OID');

                    updatedElements.push(element);
                });

                return Tools.ForEachDeferredAction(updatedElements, putElement,
                    {Visible: true, Text: i18next.t('progressbar.updatingLinkedElements'), ScreenBlock: true});
            }

            function updateUsers() {
                var updatedUsers = [];

                userAssignments.forEach(function (user) {
                    user.ImageOID = null;

                    updatedUsers.push(user);
                });

                return Tools.ForEachDeferredAction(updatedUsers, ressources.users.put,
                    {Visible: true, Text: i18next.t('progressbar.updatingUsers'), ScreenBlock: true});
            }

            function updateTeams() {
                var updatedTeams = [];

                teamAssignments.forEach(function (team) {
                    team.ImageOID = null;

                    updatedTeams.push(team);
                });

                return Tools.ForEachDeferredAction(updatedTeams, teams.put,
                    {Visible: true, Text: i18next.t('progressbar.updatingTeams'), ScreenBlock: true});
            }

            function updateCustomMenuItems() {
                var updatedCustomMenuItems = [];

                customMenuItemsAssignments.forEach(function (customMenuItem) {
                    if (customMenuItem.IconOID === file.OID) {
                        customMenuItem.IconOID = null;
                    }

                    if (customMenuItem.OfficeIconOID === file.OID) {
                        customMenuItem.OfficeIconOID = null;
                    }

                    updatedCustomMenuItems.push(customMenuItem);
                });

                return Tools.ForEachDeferredAction(updatedCustomMenuItems, CustomMenuItems.SaveMenuItem,
                    {Visible: true, Text: i18next.t('progressbar.updatingCustomMenuItems'), ScreenBlock: true});
            }

            function updateMailTemplates() {
                var updatedMailTemplates = [];

                mailTemplateAssignments.forEach(function (mailTemplate) {
                    mailTemplate.AttachmentFiles = Tools.remove(mailTemplate.AttachmentFiles, file.OID);

                    updatedMailTemplates.push(mailTemplate);
                });

                return Tools.ForEachDeferredAction(updatedMailTemplates, MailTemplates.Save,
                    {Visible: true, Text: i18next.t('progressbar.updatingMailTemplates'), ScreenBlock: true});
            }

            Tools.Spinner.show();

            updateElements()
                .then(updateUsers)
                .then(updateTeams)
                .then(updateCustomMenuItems)
                .then(updateMailTemplates)
                .then(deleteFile);
        }

        function propertyContainsImage(property) {
            return property.Type === Enums.additionalPropertyType.Image &&
                property.Value === file.OID;
        }

        if (file.Type === Enums.FileType.File) {
            filenameRegex = new RegExp(file.Filename, 'ig');
        }

        for (var elementIdentifier in changemode.Elements) {
            var element = changemode.Elements[elementIdentifier];

            if (element.ModificationType === Enums.ModificationType.Deleted) {
                continue;
            }

            if (Tools.contains(element.AdditionalProperties, propertyContainsImage)) {
                elementAssignments.push(element);
                cannotBeDeleted = true;
                continue;
            }

            if ((element.AdditionalSettings || {}).MapStructureToImages &&
                Object.keys(element.Structure || {}).length &&
                Tools.contains($.map(element.Structure || {}, function (v) {
                    return v;
                }), elementIdentifier)) {
                elementAssignments.push(element);
                cannotBeDeleted = true;
                continue;
            }

            if (file.Type === Enums.FileType.File &&
                element.hasOwnProperty('Layout') &&
                filenameRegex.test(element.Layout)) {
                elementAssignments.push(element);
                cannotBeDeleted = true;
                continue;
            }

            if (element.ImageOID === file.OID) {
                elementAssignments.push(element);
                continue;
            }

            if (Tools.contains(element.Files, file.OID, 'OID')) {
                elementAssignments.push(element);
            }
        }

        for (var userIdentifier in changemode.Users) {
            var user = changemode.Users[userIdentifier];

            if (user.ModificationType === Enums.ModificationType.Deleted) {
                continue;
            }

            if (user.ImageOID === file.OID) {
                userAssignments.push(user);
            }
        }

        for (var teamIdentifier in changemode.Teams) {
            var team = changemode.Teams[teamIdentifier];

            if (team.ModificationType === Enums.ModificationType.Deleted) {
                continue;
            }

            if (team.ImageOID === file.OID) {
                teamAssignments.push(team);
            }
        }

        for (var menuItemIdentifier in changemode.CustomMenuItems) {
            var customMenuItem = changemode.CustomMenuItems[menuItemIdentifier];

            if (customMenuItem.ModificationType === Enums.ModificationType.Deleted) {
                continue;
            }

            if (customMenuItem.IconOID === file.OID ||
                customMenuItem.OfficeIconOID === file.OID) {
                customMenuItemsAssignments.push(customMenuItem);
            }
        }

        for (var mailTemplateIdentifier in changemode.MailTemplates) {
            var mailTemplate = changemode.MailTemplates[mailTemplateIdentifier];

            if (mailTemplate.ModificationType === Enums.ModificationType.Deleted) {
                continue;
            }

            if (Tools.indexOf(mailTemplate.AttachmentFiles, oid) > -1) {
                mailTemplateAssignments.push(mailTemplate);
            }
        }

        var assignments = teamAssignments
            .map(function (team) {
                return team.Title;
            })
            .concat(userAssignments.map(function (user) {
                return user.Title;
            }))
            .concat(elementAssignments.map(function (element) {
                return element.Title;
            }))
            .concat(customMenuItemsAssignments.map(function (menuItem) {
                return menuItem.Title;
            }))
            .concat(mailTemplateAssignments.map(function (mailTemplate) {
                return mailTemplate.Title;
            }));

        if (cannotBeDeleted) {
            options.title = i18next.t('changeMode.messages.deleteFile.title');
            options.text = i18next.t('changeMode.messages.deleteFile.text', {
                file: file.Title,
                context: 'cannotBeDeleted'
            });
            options.assignments = assignments;
            options.ok = true;
        } else if (assignments.length) {
            options.title = i18next.t('changeMode.messages.deleteFile.title');
            options.text = i18next.t('changeMode.messages.deleteFile.text', {
                file: file.Title,
                context: 'withAssignments'
            });
            options.assignments = assignments;
            options.abort = true;
            options.onDelete = onDelete;
        } else {
            options.title = i18next.t('changeMode.messages.deleteFile.title');
            options.text = i18next.t('changeMode.messages.deleteFile.text', {file: file.Title});
            options.abort = true;
            options.onDelete = onDelete;
        }

        Tools.Message.Show(options);
    }

    function getFileTypeText(file) {
        if (!file) {
            return null;
        }

        switch (file.Type) {
            case Enums.FileType.Link:
                return i18next.t('misc.fileTypes.link');
            case Enums.FileType.YoutubeVideo:
                return i18next.t('misc.fileTypes.youtube');
            case Enums.FileType.VimeoPrivateVideo:
            case Enums.FileType.VimeoPublicVideo:
                return i18next.t('misc.fileTypes.vimeo');
        }

        file.MimeType = file.MimeType || '';

        if (Tools.Files.isImage(file.MimeType)) {
            return i18next.t('misc.fileTypes.image');
        }

        if (Tools.Files.isAudio(file.MimeType)) {
            return i18next.t('misc.fileTypes.audio');
        }

        if (Tools.Files.isVideo(file.MimeType)) {
            return i18next.t('misc.fileTypes.video');
        }

        if (Tools.Files.isText(file.MimeType)) {
            return i18next.t('misc.fileTypes.text');
        }

        if (Tools.Files.isSpreadsheet(file.MimeType)) {
            return i18next.t('misc.fileTypes.spreadsheet');
        }

        if (Tools.Files.isPresentation(file.MimeType)) {
            return i18next.t('misc.fileTypes.presentation');
        }

        if (Tools.Files.isPdf(file.MimeType)) {
            return i18next.t('misc.fileTypes.pdf');
        }

        if (Tools.Files.isArchive(file.MimeType)) {
            return i18next.t('misc.fileTypes.archive');
        }

        return i18next.t('misc.fileTypes.file');
    }

    function getFileIconForMimeType(mimeType) {
        if (Tools.Files.isPdf(mimeType)) {
            return 'file-pdf';
        }

        if (Tools.Files.isText(mimeType)) {
            return mimeType === 'text/plain' ? 'file-text' : 'file-word';
        }

        if (Tools.Files.isSpreadsheet(mimeType)) {
            return 'file-excel';
        }

        if (Tools.Files.isPresentation(mimeType)) {
            return 'file-powerpoint';
        }

        if (Tools.Files.isVideo(mimeType)) {
            return 'file-video';
        }

        if (Tools.Files.isAudio(mimeType)) {
            return 'file-audio';
        }

        if (Tools.Files.isArchive(mimeType)) {
            return 'file-archive';
        }

        return 'file-unknown';
    }

    function getSanitizedFilename(filename) {
        if (!filename) {
            return null;
        }

        return filename.replace(/[/\\?%*:|"<>&]/g, '-');
    }

    function getFileExtension(filename) {
        if (!filename) {
            return '';
        }

        var fileNameParts = filename.split('.');

        if (fileNameParts.length === 1) {
            return '';
        }

        return ('.' + fileNameParts.pop());
    }

    function createFilename(filename, fileTitle) {
        if (!fileTitle) {
            return filename;
        }

        fileTitle = getSanitizedFilename(fileTitle);

        var fileExtension = getFileExtension(filename);

        if (!fileExtension || fileTitle.endsWith(fileExtension)) {
            return fileTitle;
        }

        return (fileTitle + fileExtension);
    }

    function renderFilesTable(files) {
        return [
            '<table class="table files-table">',
                '<colgroup>',
                    '<col style="width: 10%;" />',
                    '<col style="width: 40%;" />',
                    '<col style="width: 15%;" />',
                    '<col style="width: 15%;" />',
                    '<col style="width: 20%;" />',
                '</colgroup>',
                '<thead>',
                    '<tr>',
                        '<th></th>',
                        '<th title="{0}">{0}</th>'.format(i18next.t('misc.fileTable.title')),
                        '<th title="{0}">{0}</th>'.format(i18next.t('misc.fileTable.type')),
                        '<th title="{0}">{0}</th>'.format(i18next.t('misc.fileTable.onlyAvailableOnline')),
                        '<th></th>',
                    '</tr>',
                '</thead>',
                '<tbody>',
                    renderFilesTableBody(files),
                '</tbody>',
            '</table>',
        ].join('');
    }

    function renderFilesTableBody(files) {
        if (!(files instanceof Array) || !files.length) {
            return '<tr class="no-data-available"><td colspan="5">{0}</td></tr>'
                .format(i18next.t('changeMode.panels.fileAssignments.noFilesAssigned'));
        }

        return files.map(renderFileListItem).join('');
    }

    function renderFileListItem(file) {
        if (!file) {
            return '';
        }

        var html = [];

        html.push(
            '<tr data-oid="{0}" draggable="true" class="{1}">'.format(file.OID, file.IsDerived ? 'template' : ''),
            '<td class="preview"><div class="file-type-icons '
        );

        if (file.Type === Enums.FileType.Link) {
            html.push('file-link"');
        } else if (file.Type === Enums.FileType.YoutubeVideo) {
            html.push('youtube-video"');
        } else if (file.Type === Enums.FileType.VimeoPrivateVideo || file.Type === Enums.FileType.VimeoPublicVideo) {
            html.push('vimeo-video"');
        } else if (file.MimeType && file.MimeType.substr(0, 6) === 'image/' && file.Metrics) {
            html.push(
                'file-image" title="',
                file.MimeType || '',
                '"'
            );
        } else {
            html.push(
                Tools.Files.getFileIconForMimeType(file.MimeType),
                '"',
                ' title="',
                file.MimeType || '',
                '"'
            );
        }

        html.push(
            '></div></td>',
            '<td>',
                '<span class="title" title="{0}">{0}</span>'.format(file.Title),
                '<p class="hierarchy">{0}</p>'.format(Tools.folders.getFolderPathForModifiedData(file.FolderOID, true)),
            '</td>'
        );

        var fileTypeText = Tools.Files.getFileTypeText(file);

        html.push('<td title="{0}">{0}</td>'.format(fileTypeText));

        html.push('<td>{0}</td>'
            .format(
                file.IsAvailableOffline
                    ? i18next.t('changeMode.panels.fileAssignments.no')
                    : i18next.t('changeMode.panels.fileAssignments.yes')
            )
        );

        html.push('<td><div class="table-button button-steal red btn-remove">{0}</div></td>'
            .format(i18next.t('changeMode.panels.fileAssignments.remove')));

        html.push('</tr>');

        return html.join('');
    }

    tools.Files = {
        isImage: isImage,
        isAudio: isAudio,
        isVideo: isVideo,
        isPdf: isPdf,
        isText: isText,
        isSpreadsheet: isSpreadsheet,
        isPresentation: isPresentation,
        isArchive: isArchive,
        loadUsageInformation: loadUsageInformation,
        deleteFile: deleteFile,
        getFileTypeText: getFileTypeText,
        getFileIconForMimeType: getFileIconForMimeType,
        getSanitizedFilename: getSanitizedFilename,
        getFileExtension: getFileExtension,
        createFilename: createFilename,
        getSanitizedFilename: getSanitizedFilename,
        renderFilesTable: renderFilesTable,
        renderFilesTableBody: renderFilesTableBody,
        renderFilesTableBodyRow: renderFileListItem
    };
})(window.Tools || (window.Tools = {}));