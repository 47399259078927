/**
 * @require ../tools.js
 */
(function (global) {
    /**
     * @typedef ContextOptions
     * @property {object} issue
     * @property {object} form
     * @property {string} ouIdentifier
     * @property {object} additionalInformation
     */

    /**
     * @param {ContextOptions} options
     * @constructor
     */
    var FormulaFunctions = function (options) {
        if (!options) {
            throw new Error('options missing');
        }

        this.Context = {};
        this.UpdateContext(options);
    };

    /**** Öffentliche Methoden ****/

    /**
     * @param {ContextOptions} options
     */
    FormulaFunctions.prototype.UpdateContext = function (options) {
        if (!options) {
            return;
        }

        if (options.issue) {
            this.Context.Issue = options.issue;
        }

        if (options.form) {
            this.Context.Form = options.form;
        }

        if (!!options.ouIdentifier) {
            this.Context.OuIdentifier = options.ouIdentifier;
        }

        if (options.additionalInformation) {
            this.Context.AdditionalInformation = options.additionalInformation;
        }
    };

    FormulaFunctions.prototype.GetFunction = function (key) {
        var me = this;

        if (!this.keyMap) {
            this.keyMap = Tools.reduce(Object.keys(this), function (key, dict) {
                dict[key.toLowerCase()] = me[key];

                return dict;
            }, {});
        }

        return this.keyMap[key.toLowerCase()];
    };

    /**** Helper-Methoden ****/

    var filenameRegex = /[~"#%&*:<>?\/\\{|}]/gi;

    function getResubmissionitemForElement(oid, subsamplenumber) {
        if (!this.Context.Issue || !(this.Context.Issue.Resubmissionitems instanceof Array)) {
            return null;
        }

        if (Tools.isNumber(subsamplenumber) && subsamplenumber > 1) {
            return Tools.getFirst(this.Context.Issue.Resubmissionitems, function (resubmissionitem) {
                return resubmissionitem.ElementOID === oid && (resubmissionitem.Row || 1) === subsamplenumber;
            });
        }

        return Tools.getFirst(this.Context.Issue.Resubmissionitems, oid, 'ElementOID');
    }

    function getCurrentOrganizationUnit() {
        if (!!this.Context.OuIdentifier) {
            return DataManager.OrganizationUnitLoader.Data.DataMap[this.Context.OuIdentifier];
        }

        var currentIssue = this.Context.Issue;
        var isAdhoc = currentIssue == null ||
            currentIssue.AssignedElementOID == null ||
            currentIssue.Type === Enums.IssueType.Scheduling;

        if (!isAdhoc && !currentIssue) {
            return null;
        }

        return DataManager.OrganizationUnitLoader.Data.DataMap[isAdhoc ?
            CurrentEntity.OID :
            currentIssue.AssignedElementOID
        ];
    }

    /**** Implementierte Formelfunktionen ****/

    FormulaFunctions.prototype.Functions = {};

    FormulaFunctions.prototype.Functions.Value = function () {
        var resubmissionitem = (this.Context.AdditionalInformation || {}).Resubmissionitem;

        if (!resubmissionitem) {
            return null;
        }

        var element = resubmissionitem.Element;

        if (!element) {
            return null;
        }

        var recorditem = getRecorditemForResubmissionitem(resubmissionitem.OID);

        if (!recorditem) {
            return null;
        }

        if (element.Type === Enums.elementType.ListBox) {
            return element.Structure && element.Structure.hasOwnProperty(recorditem.Value) ?
                element.Structure[recorditem.Value] :
                null;
        }

        return recorditem ? recorditem.Value : null;
    };

    FormulaFunctions.prototype.Functions.LastValue = function (oid) {
        throw 'Function not supported.';
    };

    FormulaFunctions.prototype.Functions.SubsampleValue = function (oid, subsamplenumber) {
        var resubmissionitem = getResubmissionitemForElement.call(this, oid, subsamplenumber);

        if (!resubmissionitem) {
            throw 'Element does not exist.';
        }

        var recorditem = getRecorditemForResubmissionitem(resubmissionitem.OID);

        return recorditem ? recorditem.Value : null;
    };

    FormulaFunctions.prototype.Functions.Subsamplenumber = function () {
        return (this.Context.AdditionalInformation || {}).Subsamplenumber || 1;
    };

    FormulaFunctions.prototype.Functions.MasterdataValue = function (groupTitle, cpTitle, locationIdentifier) {
        if (!groupTitle || !cpTitle) {
            return null;
        }

        var masterdataLocationIdentifier = !!locationIdentifier ? locationIdentifier : this.Context.OuIdentifier;
        var masterdataLocation = this.Context.AdditionalInformation && this.Context.AdditionalInformation.OusWithMasterdata ?
            this.Context.AdditionalInformation.OusWithMasterdata[masterdataLocationIdentifier] :
            null;

        if (masterdataLocation == null) {
            return null;
        }

        if (!(masterdataLocation.Parametergroups || []).length) {
            return null;
        }

        groupTitle = groupTitle.toLowerCase();
        cpTitle = cpTitle.toLowerCase();

        for (var gCnt = 0, gLen = masterdataLocation.Parametergroups.length; gCnt < gLen; gCnt++) {
            var group = masterdataLocation.Parametergroups[gCnt];

            if (group.Type !== Enums.elementType.MasterdataGroup) {
                continue;
            }

            if (!(group.Parameters || []).length) {
                continue;
            }

            if (group.Title.toLowerCase() !== groupTitle) {
                continue;
            }

            for (var pCnt = 0, pLen = group.Parameters.length; pCnt < pLen; pCnt++) {
                var cp = group.Parameters[pCnt];

                if (cp.Title.toLowerCase() !== cpTitle) {
                    continue;
                }

                return cp.LastRecorditem ? cp.LastRecorditem.Value : null;
            }
        }

        return null;
    };

    FormulaFunctions.prototype.Functions.ParentOrganizationUnit = function () {
        var currentOU = DataManager.OrganizationUnitLoader.Data.DataMap[this.Context.OuIdentifier];

        return currentOU && !!currentOU.ParentOID ? currentOU.ParentOID : null;
    };

    FormulaFunctions.prototype.Functions.DayDif = function (dateA, dateB) {
        if (!Tools.dateTime.isDate(dateA) || !Tools.dateTime.isDate(dateB)) {
            throw 'Invalid date.';
        }

        return Math.ceil((dateB - dateA) / 1000 / 60 / 60 / 24);
    };

    FormulaFunctions.prototype.Functions.MonthDif = function (dateA, dateB) {
        if (!Tools.dateTime.isDate(dateA) || !Tools.dateTime.isDate(dateB)) {
            throw 'Invalid date.';
        }

        var months = (dateB.getFullYear() - dateA.getFullYear()) * 12;
        months -= dateA.getMonth();
        months += dateB.getMonth();

        var dayA = dateA.getDate();
        var dayB = dateB.getDate();

        if (dateA < dateB && dayA > dayB) {
            months -= 1;
        } else if (dateA > dateB && dayA < dayB) {
            months += 1;
        }

        return months;
    };

    FormulaFunctions.prototype.Functions.YearDif = function (dateA, dateB) {
        if (!Tools.dateTime.isDate(dateA) || !Tools.dateTime.isDate(dateB)) {
            throw 'Invalid date.';
        }

        var years = dateB.getFullYear() - dateA.getFullYear();
        var monthA = dateA.getMonth();
        var monthB = dateB.getMonth();
        var dayA = dateA.getDate();
        var dayB = dateB.getDate();

        if (dateA < dateB && (monthA > monthB || monthA === monthB && dayA > dayB)) {
            years -= 1;
        } else if (dateA > dateB && (monthA < monthB || monthA === monthB && dayA < dayB)) {
            years += 1;
        }

        return years;
    };

    FormulaFunctions.prototype.Functions.DayAdd = function (date, days) {
        if (!Tools.dateTime.isDate(date)) {
            throw 'Invalid date.';
        }

        if (!Tools.isNumber(days)) {
            throw 'Invalid number.';
        }

        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
    };

    FormulaFunctions.prototype.Functions.MonthAdd = function (date, months) {
        if (!Tools.dateTime.isDate(date)) {
            throw 'Invalid date.';
        }

        if (!Tools.isNumber(months)) {
            throw 'Invalid number.';
        }

        return new Date(date.getFullYear(), date.getMonth() + months, date.getDate());
    };

    FormulaFunctions.prototype.Functions.YearAdd = function (date, years) {
        if (!Tools.dateTime.isDate(date)) {
            throw 'Invalid date.';
        }

        if (!Tools.isNumber(years)) {
            throw 'Invalid number.';
        }

        return new Date(date.getFullYear() + years, date.getMonth(), date.getDate());
    };

    FormulaFunctions.prototype.Functions.EndOfMonth = function (date) {
        if (!Tools.dateTime.isDate(date)) {
            throw 'Invalid date.';
        }

        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    };

    FormulaFunctions.prototype.Functions.Date = function (year, month, day) {
        if (!Tools.isNumber(year) ||
            !Tools.isNumber(month) ||
            !Tools.isNumber(day)) {
            throw 'Invalid number.';
        }

        return new Date(year, month - 1, day);
    };

    FormulaFunctions.prototype.Functions.Today = function () {
        var now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    };

    FormulaFunctions.prototype.Functions.Day = function (date) {
        if (!Tools.dateTime.isDate(date)) {
            throw 'Invalid date.';
        }

        return date.getDate();
    };

    FormulaFunctions.prototype.Functions.Month = function (date) {
        if (!Tools.dateTime.isDate(date)) {
            throw 'Invalid date.';
        }

        return date.getMonth() + 1;
    };

    FormulaFunctions.prototype.Functions.Year = function (date) {
        if (!Tools.dateTime.isDate(date)) {
            throw 'Invalid date.';
        }

        return date.getFullYear();
    };

    FormulaFunctions.prototype.Functions.Time = function (hour, minute) {
        if (!Tools.isNumber(hour) ||
            !Tools.isNumber(minute)) {
            throw 'Invalid number.';
        }

        var date = new Date();
        date.setHours(hour);
        date.setMinutes(minute);

        return date.toLocaleTimeString().slice(0, -3);
    };

    FormulaFunctions.prototype.Functions.Now = function () {
        return new Date();
    };

    FormulaFunctions.prototype.Functions.Hour = function (date) {
        if (!Tools.dateTime.isDate(date)) {
            throw 'Invalid date.';
        }

        return date.getHours();
    };

    FormulaFunctions.prototype.Functions.Minute = function (date) {
        if (!Tools.dateTime.isDate(date)) {
            throw 'Invalid date.';
        }

        return date.getMinutes();
    };

    FormulaFunctions.prototype.Functions.MinuteDif = function (dateA, dateB) {
        if (!Tools.dateTime.isDate(dateA) ||
            !Tools.dateTime.isDate(dateB)) {
            throw 'Invalid date.';
        }

        var hourA = dateA.getHours();
        var hourB = dateB.getHours();
        var minuteA = dateA.getMinutes();
        var minuteB = dateB.getMinutes();

        var minutes = (hourB - hourA) * 60;
        minutes += (minuteB - minuteA);

        return minutes >= 0 ? minutes : minutes + 24 * 60;
    };

    FormulaFunctions.prototype.Functions.HourDif = function (dateA, dateB) {
        if (!Tools.dateTime.isDate(dateA) ||
            !Tools.dateTime.isDate(dateB)) {
            throw 'Invalid date.';
        }

        var hourA = dateA.getHours();
        var hourB = dateB.getHours();
        var minuteA = dateA.getMinutes();
        var minuteB = dateB.getMinutes();
        var hours = hourB - hourA;

        if (minuteA > minuteB) {
            hours -= 1;
        }

        return hours >= 0 ? hours : hours + 24;
    };

    FormulaFunctions.prototype.Functions.MinuteAdd = function (date, minutes) {
        if (!Tools.dateTime.isDate(date)) {
            throw 'Invalid date.';
        }

        if (!Tools.isNumber(minutes)) {
            throw 'Invalid number.';
        }

        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes() + minutes
        );
    };

    FormulaFunctions.prototype.Functions.HourAdd = function (date, hours) {
        if (!Tools.dateTime.isDate(date)) {
            throw 'Invalid date.';
        }

        if (!Tools.isNumber(hours)) {
            throw 'Invalid number.';
        }

        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours() + hours,
            date.getMinutes()
        );
    };

    FormulaFunctions.prototype.Functions.True = function () {
        return true;
    };

    FormulaFunctions.prototype.Functions.False = function () {
        return false;
    };

    FormulaFunctions.prototype.Functions.If = function (condition, trueValue, falseValue) {
        if (!Tools.isBool(condition)) {
            throw 'Invalid boolean.';
        }

        return condition ? trueValue : falseValue;
    };

    FormulaFunctions.prototype.Functions.IsBlank = function (value) {
        return value === null || value === '';
    };

    FormulaFunctions.prototype.Functions.IfBlank = function (value, defaultValue) {
        return value === null || value === '' ? defaultValue : value;
    };

    FormulaFunctions.prototype.Functions.Abs = function (value) {
        if (!Tools.isNumber(value)) {
            throw 'Invalid number.';
        }

        return Math.abs(number);
    };

    FormulaFunctions.prototype.Functions.Sqrt = function (value) {
        if (!Tools.isNumber(value) || value < 0) {
            throw 'Invalid number.';
        }

        return Math.sqrt(value);
    };

    FormulaFunctions.prototype.Functions.Round = function (value, decimals) {
        if (!Tools.isNumber(value) ||
            !Tools.isNumber(decimals) ||
            decimals < 0) {
            throw 'Invalid number.';
        }

        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    };

    FormulaFunctions.prototype.Functions.Rounddown = function (value, decimals) {
        if (!Tools.isNumber(value) ||
            !Tools.isNumber(decimals) ||
            decimals < 0) {
            throw 'Invalid number.';
        }

        return Number(Math.floor(value + 'e' + decimals) + 'e-' + decimals);
    };

    FormulaFunctions.prototype.Functions.Roundup = function (value, decimals) {
        if (!Tools.isNumber(value) ||
            !Tools.isNumber(decimals) ||
            decimals < 0) {
            throw 'Invalid number.';
        }

        return Number(Math.ceil(value + 'e' + decimals) + 'e-' + decimals);
    };

    FormulaFunctions.prototype.Functions.FormTitle = function () {
        if (!Tools.IsSet(this.Context.Issue) || !Tools.IsSet(this.Context.Form)) {
            throw 'Issue or Form does not exist';
        }

        var title, issueForm;

        if (this.Context.Issue.AssignedFormOID && this.Context.Form.OID === this.Context.Issue.AssignedFormOID) {
            issueForm =  this.Context.Form;

            if (Tools.IsSet(issueForm)) {
                title = issueForm.Title || i18next.t('misc.untitled');
                title.replace(filenameRegex, ''); // Unerlaubte Zeichen entfernen
            }
        }

        return Tools.IsSet(title) ? title : null;
    };

    FormulaFunctions.prototype.Functions.IssueTypeAbbreviation = function () {
        if (!Tools.IsSet(this.Context.Issue)) {
            throw 'Issue does not exist';
        }

        return Tools.GetIssueAbbreviation(this.Context.Issue);
    };

    FormulaFunctions.prototype.Functions.IssueID = function () {
        if (!Tools.IsSet(this.Context.Issue)) {
            throw 'Issue does not exist';
        }

        return this.Context.Issue.ID;
    };

    FormulaFunctions.prototype.Functions.IssueRevision = function () {
        if (!Tools.IsSet(this.Context.Issue)) {
            throw 'Issue does not exist';
        }

        return this.Context.Issue.Revision;
    };

    FormulaFunctions.prototype.Functions.IssueCreationtimestamp = function () {
        if (!Tools.IsSet(this.Context.Issue)) {
            throw 'Issue does not exist';
        }

        var date = this.Context.Issue.CreationTimestamp;

        if (!Tools.dateTime.isDate(this.Context.Issue.CreationTimestamp)) {
            try {
                date = new Date(date);
            } catch (e) {
                throw 'Invalid date.';
            }
        }

        return date;
    };

    FormulaFunctions.prototype.Functions.IssueLastModificationtimestamp = function () {
        if (!Tools.IsSet(this.Context.Issue)) {
            throw 'Issue does not exist';
        }

        var date = this.Context.Issue.ModificationTimestamp;

        if (!Tools.dateTime.isDate(this.Context.Issue.ModificationTimestamp)) {
            try {
                date = new Date(date);
            } catch (e) {
                throw 'Invalid date.';
            }
        }

        return date;
    };

    FormulaFunctions.prototype.Functions.IssueTitle = function () {
        if (!Tools.IsSet(this.Context.Issue)) {
            throw 'Issue does not exist';
        }

        var title = this.Context.Issue.Title || i18next.t('misc.untitled');

        title = title.replace(filenameRegex, '');

        return Tools.IsSet(title) ? title : null;
    };

    FormulaFunctions.prototype.Functions.IndividualDataProperty = function (oid, propertyName) {
        var foreignValue = this.Value();

        if (!foreignValue) {
            return null;
        }

        var row = (this.Context.AdditionalInformation || {}).Row;
        var element = getResubmissionitemForElement.call(this, oid, row);

        if (!element) {
            return null;
        }

        var schemaName = ((element.AdditionalSettings || {}).Types || [])[0] || '';

        if (!foreignValue.hasOwnProperty(schemaName)) {
            return null;
        }

        var selectedDataRecord = (foreignValue[schemaName] || [])[0];
        var dataRecord = IndividualData.GetEntity(schemaName, selectedDataRecord);

        if (!dataRecord) {
            return null;
        }

        var schema = IndividualData.GetSchema(schemaName);
        var propertyDefinition = Tools.getFirst(schema.Properties || [], propertyName, 'Name');

        if (propertyDefinition && propertyDefinition.Type === Enums.IndividualdataSchemaPropertyType.RichText) {
            return null;
        }

        return dataRecord.hasOwnProperty(propertyName) ? dataRecord[propertyName] : null;
    };

    FormulaFunctions.prototype.Functions.ReplaceCharacter = function (data, char, replacement) {
        if (!Tools.IsSet(data) || char == null || replacement == null) {
            return;
        }

        var metaCharRegex = new RegExp(/[\[\^\$\.\?\*\+\(\)]/, '\gi');
        var replaceRegex = new RegExp(char, '\gi');

        if (char.match(metaCharRegex)) {
            replaceRegex = new RegExp("\\" + char, '\gi');
        }

        if (data instanceof Date) {
            var date = data;
            var isTimeNull = Tools.dateTime.isTimeOfDateNull(date);
            var dateString = Tools.dateTime.dateToString(date);

            data = isTimeNull ? dateString : (dateString + " " + date.toLocaleTimeString()).slice(0, -3);
        }

        if (!Tools.IsSet(replacement) || typeof replacement !== 'string') {
            replacement = '';
        }

        data = data.toString();
        data = data.replace(/\s+/gi, ' ');

        return data.replace(replaceRegex, Tools.IsSet(replacement) ? replacement : '');
    };

    FormulaFunctions.prototype.Functions.EntityProperty = function (propertyName, entity) {
        if (!propertyName || !entity) {
            return null;
        }

        return entity[propertyName];
    };

    FormulaFunctions.prototype.Functions.OuPropertyValue = function (groupTitle, propertyTitle) {
        if (!propertyTitle) {
            return null;
        }

        var currentLocation = getCurrentOrganizationUnit.call(this);

        if (currentLocation == null) {
            return null;
        }

        if (!Tools.IsSet(currentLocation.AdditionalProperties)) {
            return null;
        }

        var resubmissionitem = (this.Context.AdditionalInformation || {}).Resubmissionitem;
        var element = resubmissionitem ? resubmissionitem.Element : { Type: Enums.elementType.Line };

        if (!element) {
            return null;
        }

        var additionalProperties = currentLocation.AdditionalProperties || [];
        var group = {};

        if (groupTitle && typeof groupTitle === 'string') {
            group = Tools.getFirst(additionalProperties, function (property) {
                return property.Type === Enums.additionalPropertyType.Group &&
                    (property.Title || '').toLowerCase() === groupTitle.toLowerCase();
            });
        }

        var properties = group != null && group.hasOwnProperty('Children') ?
            (group.Children || []) :
            additionalProperties;

        var additionalProperty = Tools.getFirst(properties, function (property) {
            return property.Type !== Enums.additionalPropertyType.Group &&
                (property.Title || '').toLowerCase() === propertyTitle.toLowerCase();
        });

        if (additionalProperty == null ||
            additionalProperty.Value == null ||
            additionalProperty.Value === '-/-') {
            return null;
        }

        var rawValue = additionalProperty.Value;

        if (additionalProperty.Type === Enums.additionalPropertyType.IndividualData) {
            if (typeof rawValue === 'object' && rawValue.hasOwnProperty(additionalProperty.SubType)) {
                var selectedEntities = rawValue[additionalProperty.SubType];

                if (selectedEntities instanceof Array && selectedEntities.length) {
                    rawValue = selectedEntities
                        .filter(function (id) {
                            return IndividualData.GetEntity(additionalProperty.SubType, id) != null;
                        });

                    if (!rawValue.length) {
                        rawValue = null;
                    }
                } else {
                    rawValue = null;
                }
            } else {
                rawValue = null;
            }
        }

        switch (element.Type) {
            case Enums.elementType.Line:
            case Enums.elementType.Memo:
                if (rawValue !== null) {
                    if (additionalProperty.Type === Enums.additionalPropertyType.Text) {
                        rawValue = Tools.unescapeHtml(rawValue);
                    }

                    if (additionalProperty.Type === Enums.additionalPropertyType.IndividualData) {
                        var selectedIndividualdataEntities = $.map(rawValue, function (id) {
                            return IndividualData.GetEntity(additionalProperty.SubType, id);
                        }).filter(function (entity) {
                            return entity != null;
                        });

                        var entityTitles = {};

                        selectedIndividualdataEntities.sort(function (a, b) {
                            if (!entityTitles.hasOwnProperty(a.ID)) {
                                entityTitles[a.ID] = IndividualData.GetEntityTitle(additionalProperty.SubType, a.ID);
                            }

                            if (!entityTitles.hasOwnProperty(b.ID)) {
                                entityTitles[b.ID] = IndividualData.GetEntityTitle(additionalProperty.SubType, b.ID);
                            }

                            var titleA = entityTitles[a.ID].toLowerCase();
                            var titleB = entityTitles[b.ID].toLowerCase();

                            if (titleA === titleB) {
                                return 0;
                            }

                            return titleA > titleB ? 1 : -1;
                        });

                        selectedIndividualdataEntities = selectedIndividualdataEntities.map(function (item) {
                            return IndividualData.GetEntityTitle(additionalProperty.SubType, item.ID);
                        });

                        return selectedIndividualdataEntities.join(', ');
                    }
                }
                break;
            case Enums.elementType.Checkbox:
            case Enums.elementType.Time:
                return rawValue;
            case Enums.elementType.Date:
                if (rawValue instanceof Date) {
                    return rawValue;
                }

                if (typeof rawValue === 'string') {
                    return new Date(rawValue);
                }

                break;
            case Enums.elementType.Number:
                if (isNaN(rawValue)) {
                    return null;
                }
                break;
            case Enums.elementType.IndividualData:
                if (rawValue !== null && additionalProperty.Type === Enums.additionalPropertyType.IndividualData) {
                    var individualdataResult = {};

                    individualdataResult[additionalProperty.SubType] = rawValue.map(function (item) {
                        return item.ID;
                    });

                    return individualdataResult;
                }

                break;
            case Enums.elementType.EMailAddresses:
                var emailAddresses = typeof rawValue === 'string' ? rawValue.trim() : null;

                if (emailAddresses == null) {
                    return null;
                }

                emailAddresses = emailAddresses.split(/[;,]+/);

                for (var i = 0; i < emailAddresses.length; i++) {
                    emailAddresses[i] = emailAddresses[i].trim();
                }

                // doppelte Einträge und ungültige Email-Adressen entfernen
                emailAddresses = emailAddresses.filter(function (mail, index) {
                    return Tools.IsValidMailAddress(mail) && emailAddresses.indexOf(mail) === index;
                });

                if ((emailAddresses || []).length === 0) {
                    return null;
                }

                return emailAddresses;
        }

        return rawValue;
    };

    FormulaFunctions.prototype.Functions.OuIdentCode = function () {
        var currentLocation = getCurrentOrganizationUnit.call(this);
        var identcode = currentLocation ? currentLocation.Identcode : null;

        return identcode || null;
    };

    FormulaFunctions.prototype.Functions.OuInternalIdentifier = function () {
        var currentLocation = getCurrentOrganizationUnit.call(this);

        return currentLocation ? currentLocation.OID : null;
    };

    FormulaFunctions.prototype.Functions.Contains = function (collection, value) {
        if (!(collection instanceof Array) || collection.length === 0) {
            return false;
        }

        if (value == null || value === '') {
            return false;
        }

        return collection.indexOf(value) > -1;
    };

    FormulaFunctions.prototype.Functions.Length = function (value) {
        if (value == null || typeof value !== 'string') {
            return null;
        }

        return value.length;
    };

    FormulaFunctions.prototype.Functions.Substring = function (value, startIndex, length) {
        if (value == null || typeof value !== 'string') {
            return null;
        }

        if (isNaN(startIndex) || startIndex < 0 || startIndex > (value.length - 1)) {
            return null;
        }

        if (isNaN(length) || length < 1) {
            return null;
        }

        return value.substr(startIndex, length);
    };

    FormulaFunctions.prototype.Functions.Split = function (value, separator) {
        if (value == null || typeof value !== 'string') {
            return null;
        }

        if (separator == null || typeof separator !== 'string') {
            return value;
        }

        if (value.indexOf('\n') >= 0 || value.indexOf('\t') >= 0) {
            // Aufteilung mit RegEx Erweiterung durchführen für \n und \t Applikation

            // besondere Zeichen escapen
            var specialChars = '\\^$*+?.()|{}[]';
            var rxText = [];
            for (var i = 0; i < separator.length; i++) {
                var ch = separator[i];

                // prüfen auf \n und \t und diese behalten
                if (ch == '\\' && separator.length > i + 1) {
                    var nextCh = separator[i + 1];

                    switch (nextCh) {
                        case '\\':
                            // \ escape Character vorsetzen
                            rxText.push('\\');
                            // überspringe nächstes '\' Zeichen
                            i++;
                            break;
                        case 'n':
                            // optionales \r? für \r?\n vorsetzen
                            rxText.push('\\');
                            rxText.push('r');
                            rxText.push('?');
                            break;
                        case 't':
                            // keine weitere Aktion um \t beizubehalten
                            break;
                        default:
                            // \ escape Character vorsetzen
                            rxText.push('\\');
                    }
                } else if (specialChars.indexOf(ch) >= 0) {
                    // \ escape Character vorsetzen
                    rxText.push('\\');
                }

                rxText.push(ch);
            }

            // trennen mit RegEx
            return value.split(new RegExp(rxText.join('')));
        }

        // Text-Trennung ohne RegEx
        return value.split(separator);
    };

    FormulaFunctions.prototype.Functions.ItemFromArray = function (value, index) {
        if (!(value instanceof Array)) {
            return null;
        }

        if (isNaN(index) || index < 0 || index > (value.length - 1)) {
            return null;
        }

        return value[index];
    };

    FormulaFunctions.prototype.Functions.StructureValue = function (elementOID, valueIdentifier) {
        if (!Tools.IsValidGuid(elementOID)) {
            return null;
        }

        if (valueIdentifier == null) {
            return null;
        }

        var resubmissionitem = getResubmissionitemForElement.call(this, oid);

        if (!resubmissionitem) {
            throw 'Resubmissionitem does not exist.';
        }

        var element = resubmissionitem.Element;

        if (!element || !element.Structure) {
            return null;
        }

        if (valueIdentifier instanceof Array) {
            return valueIdentifier
                .map(function (identifier) {
                    return element.Structure.hasOwnProperty(identifier) ?
                        element.Structure[identifier] :
                        null;
                })
                .filter(function (v) {
                    return v != null;
                })
                .join(', ');
        }

        return element.Structure.hasOwnProperty(valueIdentifier) ?
            element.Structure[valueIdentifier] :
            null;
    };

    FormulaFunctions.prototype.Functions.RandomNumber = function (minimum, maximum) {
        if (isNaN(minimum) || isNaN(maximum)) {
            return null;
        }

        if (minimum >= maximum) {
            return null;
        }

        minimum = Math.ceil(minimum);
        maximum = Math.floor(maximum);

        return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    };

    FormulaFunctions.prototype.Functions.WeekDay = function (date, returnType) {
        if (!Tools.dateTime.isValidDate(date) || isNaN(returnType) || returnType < 1 || returnType > 2) {
            return null;
        }

        var weekDay = date.getDay();

        if (returnType === 1) {
            return weekDay;
        }

        return weekDay === 0 ? 7 : weekDay;
    };

    FormulaFunctions.prototype.Functions.ParseToNumber = function (value) {
        if (value == null) {
            return null;
        }

        value = value.toString().replace(',', '.');

        if (isNaN(value)) {
            return null;
        }

        var result = parseFloat(value);

        return isNaN(result) ? null : result;
    };

    return (global.FormulaFunctions = FormulaFunctions);
})(window.Tools || (window.Tools = {}));