(function(global) {
	var Comments = {};

	function get(id, types, type, skipRows, callback, errorHandler) {
		var typeParam = '?types={0}&AssignedElementType={1}'.format(types.join('|'), type);
		if(skipRows && skipRows > 0) {
			typeParam += '&skip={0}&take=1'.format(skip);
		}

		if (!errorHandler) {
			errorHandler = function (xhr) {
				Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
				Tools.Spinner.hide();
			}
		}

		Tools.http.get('comments/' + id + typeParam, function(msg) {
				if (callback instanceof Function) {
					callback(msg);
				}
			}, errorHandler);
	}

	function put(comment, callback, errorHandler) {
		if (!errorHandler) {
			errorHandler = function (xhr) {
				Tools.handleHttpError(Enums.HttpActionType.Write, xhr);
				Tools.Spinner.hide();
			}
		}

		var data = {
			Type: comment.Type,
			Text: comment.Text || null,
			AssignmentOID: comment.AssignmentOID || null,
			AssignmentID: comment.AssignmentID || null,
			CreationTimestamp: Tools.dateTime.toGMTString(comment.CreationTimestamp ? comment.CreationTimestamp : new Date()),
			ModificationTimestamp: Tools.dateTime.toGMTString(comment.ModificationTimestamp ? comment.ModificationTimestamp : new Date()),
			CreatorOID: comment.CreatorOID,
			EditorOID: comment.EditorOID
		};

		Tools.UnEscape.Comment(data);

		Tools.http.put('comments/' + comment.OID, data, function () {
			if (callback instanceof Function) {
				callback();
			}
		}, errorHandler);
	}

	function del(oid, callback, errorHandler) {
		if (!errorHandler) {
			errorHandler = function (xhr) {
				Tools.handleHttpError(Enums.HttpActionType.Delete, xhr);
				Tools.Spinner.hide();
			}
		}

		Tools.http.del('comments/' + oid, function() {
				if (callback instanceof Function) {
					callback();
				}
			}, errorHandler);
	}

	Comments.get = get;
	Comments.put = put;
	Comments.del = del;

	return (global.Comments = Comments);
})(window);