(function (global) {
    var Center = {};
    var $center = $('#center');
    var $centerTop = $('#center-top');
    var $search = $('#center-search');
    var $tree = $('#center-tree');
    var $focusCurrentEntity = $center.find('.focus-entity');
    var $actionDropdownButton = $center.find('.action-dropdown-button');
    var $depthChooser = $('#center-depth-chooser');
    var _tree, _search, _maxDepth, _currentDepth;

    var treeNodeChangedByCenterNavigation = false;

    function show() {
        initActionDropdownButton();
        initTree();
        initSearch();
        initDepthChooser();

        bindEvents();
        initLanguage();

        $center.removeClass('hide');

        return this;
    }

    function initActionDropdownButton() {
        var showButton = showActionDropdownButton();

        $centerTop.toggleClass('padding-right', !showButton);
        $actionDropdownButton.toggleClass('hide', !showButton);
    }

    function showActionDropdownButton() {
        if (Mode === 'individual-data') {
            var schema = IndividualData.GetCurrentSchema();

            return !schema.IsReadonly;
        }

        return true;
    }

    function select(entity) {
        _tree
            .openAncestorNodes(entity)
            .selectNode(entity);

        if (!treeNodeChangedByCenterNavigation) {
            _tree
                .scrollToNode(entity);
        }

        treeNodeChangedByCenterNavigation = false;

        return this;
    }

    function add(entity) {
        if (!_tree) {
            return;
        }

        _tree.add(entity);

        resetSearch();
        resetDepthChooser();

        return this;
    }

    function addTo(entity, parentEntity) {
        if (!_tree) {
            return;
        }

        _tree.addTo(entity, parentEntity);

        resetSearch();
        resetDepthChooser();

        return this;
    }

    function addBefore(entity, parentEntity) {
        if (!_tree) {
            return;
        }

        _tree.addBefore(entity, parentEntity);

        resetSearch();
        resetDepthChooser();

        return this;
    }

    function addAfter(entity, parentEntity) {
        if (!_tree) {
            return;
        }

        _tree.addAfter(entity, parentEntity);

        resetSearch();
        resetDepthChooser();

        return this;
    }

    function moveTo(entity, parentEntity) {
        if (!_tree) {
            return;
        }

        _tree.moveTo(entity, parentEntity);

        resetSearch();
        resetDepthChooser();

        return this;
    }

    function addClassToEntityNode(entity, cssClass) {
        if (!_tree || !entity) {
            return;
        }

        _tree.addClassToNode(entity, cssClass)
    }

    function removeClassFromEntityNode(entity, cssClass) {
        if (!_tree || !entity) {
            return;
        }

        _tree.removeClassFromNode(entity, cssClass)
    }

    function remove(entity) {
        if (!_tree) {
            return;
        }

        _tree.removeNode(entity);

        resetSearch();
        resetDepthChooser();

        return this;
    }

    function resetSearch() {
        _tree.search('');

        initSearch();

        return this;
    }

    function resetDepthChooser() {
        initDepthChooser();

        return this;
    }

    function initTree() {
        var entities = getTreeEntities();
        var options = getTreeOptions();

        _tree = $tree
            .tree(entities, options)
            .tree(true);

        _tree
            .openAncestorNodes(CurrentEntity)
            .selectNode(CurrentEntity)
            .scrollToNode(CurrentEntity);
    }

    function initSearch() {
        var options = {};

        switch (Mode) {
            case 'explorer':
                options.placeholder = i18next.t('explorer.search');
                break;
            case 'calendar':
                options.placeholder = i18next.t('calendar.search');
                break;
            case 'explorerchangemode':
                options.placeholder = i18next.t('changeMode.rooms.search');
                break;
            case 'forms':
                options.placeholder = i18next.t('changeMode.forms.search');
                break;
            case 'events':
                options.placeholder = i18next.t('changeMode.schedulings.search');
                break;
            case 'schedules':
                options.placeholder = i18next.t('changeMode.schedules.search');
                break;
            case 'items':
                options.placeholder = i18next.t('changeMode.items.search');
                break;
            case 'form-templates':
                options.placeholder = i18next.t('changeMode.formTemplates.search');
                break;
            case 'testgroup-templates':
                options.placeholder = i18next.t('changeMode.testgroups.search');
                break;
            case 'master-data-templates':
                options.placeholder = i18next.t('changeMode.masterData.search');
                break;
            case 'property-templates':
                options.placeholder = i18next.t('changeMode.properties.search');
                break;
            case 'categories':
                options.placeholder = i18next.t('changeMode.categories.search');
                break;
            case 'priorities':
                options.placeholder = i18next.t('changeMode.priorities.search');
                break;
            case 'keywords':
                options.placeholder = i18next.t('changeMode.keywords.search');
                break;
            case 'classifications':
                options.placeholder = i18next.t('changeMode.classifications.search');
                break;
            case 'status':
                options.placeholder = i18next.t('changeMode.status.search');
                break;
            case 'units':
                options.placeholder = i18next.t('changeMode.units.search');
                break;
            case 'teams':
                options.placeholder = i18next.t('changeMode.teams.search');
                break;
            case 'roles':
                options.placeholder = i18next.t('changeMode.roles.search');
                break;
            case 'contacts':
                options.placeholder = i18next.t('changeMode.contacts.search');
                break;
            case 'contact-groups':
                options.placeholder = i18next.t('changeMode.contactGroups.search');
                break;
            case 'custom-menu-items':
                options.placeholder = i18next.t('changeMode.customMenuItems.search');
                break;
            case 'users':
                options.placeholder = i18next.t('changeMode.users.search');
                break;
            case 'files':
                options.placeholder = i18next.t('changeMode.files.search');
                break;
            case 'individual-data':
                options.placeholder = i18next.t('changeMode.individualData.search');
                break;
            case 'mail-templates':
                options.placeholder = i18next.t('changeMode.mailTemplates.search');
                break;
            case 'element-codings':
                options.placeholder = i18next.t('changeMode.elementCodings.search');
                break;
        }

        _search = $search
            .search(options)
            .search(true);
    }

    function initDepthChooser() {
        var html = [];
        var i;

        _maxDepth = _tree.getMaxDepth() - 1;
        _currentDepth = _currentDepth || 1;
        _currentDepth = Math.min(_currentDepth, _maxDepth);

        if (_maxDepth) {
            html.push('<div class="depth-choice previous-depth">-</div>');

            for (i = 1; i <= _maxDepth && i <= 6; i++) {
                if (i === _currentDepth) {
                    html.push('<div class="depth-choice active" data-depth="{0}">{0}</div>'.format(i));
                } else {
                    html.push('<div class="depth-choice" data-depth="{0}">{0}</div>'.format(i));
                }
            }

            html.push('<div class="depth-choice next-depth">+</div>');
        }

        $depthChooser.html(html.join(''));
    }

    function bindEvents() {
        $tree.off('.tree');
        $search.off('.search');
        $depthChooser.off('click');
        $focusCurrentEntity.off('click');
        $actionDropdownButton.off('click');

        var isTree = !_tree.options.schema.isList;

        $tree
            .on('select-node.tree', onSelectNode)
            .on('move-node.tree', isTree ? onMoveTreeNode : moveListNode)
            .on('drop-node.tree', onDropTreeNode);

        $search
            .on('search.search', onSearch)
            .on('select-previous-result.search', onSelectPreviousSearchResult)
            .on('select-next-result.search', onSelectNextSearchResult);

        $depthChooser.on('click', '.depth-choice:not(.active)', onSelectDepth);

        $focusCurrentEntity.on('click', onFocusCurrentEntity);

        if ($actionDropdownButton.is(':visible')) {
            $actionDropdownButton.on('click', onActionDropdownButtonClick);
        }
    }

    function initLanguage() {
        $focusCurrentEntity.attr('title', i18next.t('treeNavigation.focusEntity'));
        $actionDropdownButton.attr('title', i18next.t('treeNavigation.showActions'));
    }

    function getTreeEntities() {
        switch (Mode) {
            case 'explorer':
                return DataManager.OrganizationUnitLoader.Data.Root;
            case 'calendar':
                return DataManager.OrganizationUnitLoader.Data.Root;
            case 'events':
                return changemode.RootScheduling;
            case 'schedules':
                return ChangeMode.Schedules.GetPrototypeSchedules();
            case 'roles':
                return Object
                    .keys(changemode.Roles)
                    .map(function (oid) {
                        return changemode.Roles[oid];
                    })
                    .sort(function (a, b) {
                        var titleA = a.Title.toLowerCase();
                        var titleB = b.Title.toLowerCase();

                        if (titleA === titleB) {
                            return 0;
                        }

                        return titleA > titleB ? 1 : -1;
                    });
            case 'contacts':
                return Object
                    .keys(changemode.Contacts)
                    .map(function (oid) {
                        return changemode.Contacts[oid];
                    })
                    .sort(function (a, b) {
                        var titleA = a.Fullname.toLowerCase();
                        var titleB = b.Fullname.toLowerCase();

                        if (titleA === titleB) {
                            return 0;
                        }

                        return titleA > titleB ? 1 : -1;
                    });
            case 'contact-groups':
                return Object
                    .keys(changemode.ContactGroups)
                    .map(function (oid) {
                        return changemode.ContactGroups[oid];
                    })
                    .sort(function (a, b) {
                        var fullnameA = a.Title.toLowerCase();
                        var fullnameB = b.Title.toLowerCase();

                        if (fullnameA === fullnameB) {
                            return 0;
                        }

                        return fullnameA > fullnameB ? 1 : -1;
                    });
            case 'users':
                return Object
                    .keys(changemode.Users)
                    .map(function (oid) {
                        return changemode.Users[oid];
                    })
                    .sort(Tools.SortByFullname);
            case 'individual-data':
                entities = IndividualData.GetCurrentEntities() || [];

                return entities.sort(function (a, b) {
                    var titleA = a.Title.toLowerCase();
                    var titleB = b.Title.toLowerCase();

                    if (titleA === titleB) {
                        return 0;
                    }

                    return titleA > titleB ? 1 : -1;
                });
            case 'explorerchangemode':
                return changemode.Root;
            case 'forms':
                return changemode.Forms;
            case 'items':
                return changemode.Items;
            case 'form-templates':
                return changemode.FormTemplates;
            case 'testgroup-templates':
                return changemode.Templates;
            case 'master-data-templates':
                return changemode.Masterdata;
            case 'property-templates':
                return changemode.PropertyTemplates;
            case 'categories':
                return changemode.Categories;
            case 'priorities':
                return changemode.Priorities;
            case 'keywords':
                return changemode.Keywords;
            case 'classifications':
                return changemode.Classifications;
            case 'status':
                return changemode.Status;
            case 'units':
                return changemode.Units;
            case 'teams':
                return changemode.RootTeam;
            case 'files':
                return changemode.RootFolder.Children[0];
            case 'custom-menu-items':
                return Object
                    .keys(changemode.CustomMenuItems)
                    .map(function (oid) {
                        return changemode.CustomMenuItems[oid];
                    })
                    .sort(function (a, b) {
                        return a.Position - b.Position;
                    });
            case 'mail-templates':
                return changemode.MailTemplatesRoot;
            case 'element-codings':
                return Object
                    .keys(changemode.ElementCodings)
                    .map(function (oid) {
                        return changemode.ElementCodings[oid];
                    })
                    .sort(function (a, b) {
                        return Tools.SortStringArray(a.Prefix, b.Prefix);
                    });
        }
    }

    function getTreeOptions() {
        var options = {
            schema: {
                id: 'OID',
                children: 'Children',
                text: 'Title',
                color: 'Color'
            },
            opened: 1,
            selectable: true,
            searchResultsRepresentation: Tools.GetOfficeSettingValue('CenterTreeSearchResultsRepresentation'),
            filter: function (entity) {
                return entity.ModificationType !== Enums.ModificationType.Deleted;
            }
        };

        switch (Mode) {
            case 'explorer':
                options.schema = function (entity) {
                    return {
                        id: entity.OID,
                        text: entity.Title,
                        color: entity.Color,
                        children: entity.Children,
                        classes: entity.Enabled ?
                            'jquery-tree-node-marker' :
                            'jquery-tree-node-marker jquery-tree-node-disabled'
                    };
                };

                options.onDetailedSearch = function (searchRegExp, entity) {
                    return onDetailedElementSearch(searchRegExp, entity, false);
                };

                if (Explorer.GetWithDeactivatedElements()) {
                    options.filter = function (entity) {
                        return entity.Type === Enums.elementType.Root ||
                            entity.Type === Enums.elementType.Location;
                    };
                } else {
                    options.filter = function (entity) {
                        return entity.Enabled &&
                            (entity.Type === Enums.elementType.Root ||
                                entity.Type === Enums.elementType.Location);
                    };
                }

                break;
            case 'calendar':
                options.onDetailedSearch = function (searchRegExp, entity) {
                    return onDetailedElementSearch(searchRegExp, entity, false);
                };

                options.filter = function (entity) {
                    return entity.Enabled &&
                        (entity.Type === Enums.elementType.Root ||
                            entity.Type === Enums.elementType.Location);
                };
                break;
            case 'events':
                options.moveable = {};
                options.moveable.moveBefore =
                    options.moveable.moveAfter =
                        function (targetNode) {
                            return targetNode.depth > 1;
                        };

                options.droppable = {
                    dropBefore: false,
                    dropAfter: false,
                    accept: function (data) {
                        return changemode.Schedules.hasOwnProperty(data.node.id);
                    }
                };
                break;
            case 'schedules':
                options.schema = {id: 'OID', text: 'Title'};
                break;
            case 'explorerchangemode':
                options.schema = function (entity) {
                    return {
                        id: entity.OID,
                        text: entity.Title,
                        color: entity.Color,
                        children: entity.Children,
                        classes: entity.Enabled ? null : 'jquery-tree-node-disabled'
                    };
                };

                options.onDetailedSearch = function (searchRegExp, entity) {
                    return onDetailedElementSearch(searchRegExp, entity, true);
                };

                options.filter = function (entity) {
                    if (entity.ModificationType === Enums.ModificationType.Deleted) {
                        return false;
                    }

                    return entity.Type === Enums.elementType.Root ||
                        entity.Type === Enums.elementType.Location;
                };

                options.moveable = {};
                options.moveable.moveBefore =
                    options.moveable.moveAfter =
                        function (targetNode) {
                            return targetNode.depth > 1;
                        };

                options.droppable = {};
                options.droppable.dropBefore =
                    options.droppable.dropAfter =
                        function (targetNode, data) {
                            var targetEntity = targetNode.entity;
                            var sourceEntity = data.node.entity;

                            if (targetEntity.Type === Enums.elementType.Root) {
                                return false;
                            }

                            return sourceEntity.Type === Enums.elementType.ObjectTemplate ||
                                sourceEntity.Type === Enums.elementType.Sensor ||
                                sourceEntity.Type === Enums.elementType.CashRegister;
                        };
                break;
            case 'forms':
                options.schema = function (entity) {
                    return {
                        id: entity.OID,
                        text: entity.Title,
                        color: entity.Color,
                        children: entity.Children,
                        classes: entity.Enabled ? null : 'jquery-tree-node-disabled'
                    };
                };

                options.onDetailedSearch = function (searchRegExp, entity) {
                    return onDetailedElementSearch(searchRegExp, entity, true);
                };

                options.moveable = {};
                options.moveable.moveBefore =
                    options.moveable.moveAfter =
                        function (targetNode) {
                            return targetNode.depth > 1;
                        };

                options.droppable = {};
                options.droppable.dropBefore =
                    options.droppable.dropAfter =
                        function (targetNode, data) {
                            var targetEntity = targetNode.entity;
                            var sourceEntity = data.node.entity;

                            return targetEntity.OID !== changemode.Forms.OID &&
                                sourceEntity.Type === Enums.elementType.FormTemplate;
                        };
                break;
            case 'items':
                options.schema = function (entity) {
                    return {
                        id: entity.OID,
                        text: entity.Title,
                        color: entity.Color,
                        children: entity.Children,
                        classes: entity.Enabled ? null : 'jquery-tree-node-disabled'
                    };
                };

                options.onDetailedSearch = function (searchRegExp, entity) {
                    return onDetailedElementSearch(searchRegExp, entity, false);
                };

                options.moveable = {};
                options.moveable.moveBefore =
                    options.moveable.moveAfter =
                        function (targetNode) {
                            return targetNode.depth > 1;
                        };

                options.droppable = {dropBefore: false, dropAfter: false};
                break;
            case 'form-templates':
                options.schema = function (entity) {
                    return {
                        id: entity.OID,
                        text: entity.Title,
                        color: entity.Color,
                        children: entity.Children,
                        classes: entity.Enabled ? null : 'jquery-tree-node-disabled'
                    };
                };

                options.onDetailedSearch = function (searchRegExp, entity) {
                    return onDetailedElementSearch(searchRegExp, entity, false);
                };

                options.moveable = {};
                options.moveable.moveBefore =
                    options.moveable.moveAfter =
                        function (targetNode) {
                            return targetNode.depth > 1;
                        };

                options.droppable = {dropBefore: false, dropAfter: false};
                break;
            case 'testgroup-templates':
            case 'master-data-templates':
            case 'property-templates':
                options.schema = function (entity) {
                    return {
                        id: entity.OID,
                        text: entity.Title,
                        color: entity.Color,
                        children: entity.Children,
                        classes: entity.Enabled ? null : 'jquery-tree-node-disabled'
                    };
                };

                options.onDetailedSearch = function (searchRegExp, entity) {
                    return onDetailedElementSearch(searchRegExp, entity, false);
                };

                options.moveable = {};
                options.moveable.moveBefore =
                    options.moveable.moveAfter =
                        function (targetNode) {
                            return targetNode.depth > 1;
                        };
                break;
            case 'categories':
            case 'priorities':
            case 'status':
            case 'teams':
                options.moveable = {};
                options.moveable.moveBefore =
                    options.moveable.moveAfter =
                        function (targetNode) {
                            return targetNode.depth > 1;
                        };
                break;
            case 'custom-menu-items':
                options.schema.isList = true;
                options.schema.children = null;
                options.moveable = {};
                options.moveable.moveBefore =
                    options.moveable.moveAfter =
                        function (targetNode) {
                            return targetNode.depth === 1;
                        };
                options.moveable.moveTo = false;

                options.droppable = {dropBefore: true, dropAfter: true, dropTo: false};
                break;
            case 'keywords':
            case 'classifications':
                delete options.schema.color;

                options.moveable = {};
                options.moveable.moveBefore =
                    options.moveable.moveAfter =
                        function (targetNode) {
                            return targetNode.depth > 1;
                        };
                break;
            case 'units':
                options.schema = function (entity) {
                    var text = entity.Description ?
                        entity.Title + ' (' + entity.Description + ')' :
                        entity.Title;

                    return {
                        id: entity.OID,
                        text: text,
                        children: entity.Children
                    };
                };

                options.moveable = {};
                options.moveable.moveBefore =
                    options.moveable.moveAfter =
                        function (targetNode) {
                            return targetNode.depth > 1;
                        };
                break;
            case 'files':
                options.icon = 'jquery-tree-node-icon-folder';

                options.moveable = {};
                options.moveable.moveBefore =
                    options.moveable.moveAfter =
                        function (targetNode) {
                            return targetNode.depth > 1;
                        };
                break;
            case 'users':
                options.schema = function (entity) {
                    return {
                        id: entity.OID,
                        text: entity.Fullname,
                        classes: entity.IsLocked ? 'jquery-tree-node-disabled' : null
                    };
                };
                break;
            case 'contacts':
                options.schema.text = 'Fullname'
                break;
            case 'individual-data':
                options.schema = {id: 'ID', text: 'SingleLineTitle'};
                break;
            case 'mail-templates':
                options.moveable = {};
                options.moveable.moveBefore =
                    options.moveable.moveAfter =
                        function (targetNode) {
                            return targetNode.depth > 1;
                        };
                break;
            case 'element-codings':
                options.schema.text = 'Prefix';
                break;
        }

        return options;
    }

    function onDetailedElementSearch(searchRegExp, entity, isChangemode) {
        if (!searchRegExp) {
            return false;
        }

        if (!entity) {
            return false;
        }

        if (!(entity.Properties || []).length && !(entity.AdditionalProperties || []).length) {
            return false;
        }

        var keywordsMatch = (entity.Properties || []).some(function (identifier) {
            var property = isChangemode ? changemode.Properties[identifier] : Properties[identifier];

            if (!property) {
                return false;
            }

            return searchRegExp.test(property.Title);
        });

        if (keywordsMatch) {
            return true;
        }

        var additionalPropertiesMatch = (entity.AdditionalProperties || []).some(function (property) {
            if (!property.IsSearchable) {
                return false;
            }

            if (property.Value == null) {
                return false;
            }

            var searchableValue;

            switch (property.Type) {
                case Enums.additionalPropertyType.Number:
                    searchableValue = property.Value.toString();
                    break;
                case Enums.additionalPropertyType.Text:
                case Enums.additionalPropertyType.Info:
                case Enums.additionalPropertyType.PhoneNumber:
                    searchableValue = property.Value;
                    break;
                case Enums.additionalPropertyType.Date:
                    var date = property.Value instanceof Date ? property.Value : new Date(property.Value);
                    searchableValue = Tools.dateTime.dateToString(date);
                    break;
                case Enums.additionalPropertyType.Time:
                    var time = property.Value instanceof Date ? property.Value : new Date(property.Value);
                    searchableValue = Tools.dateTime.timeToString(time);
                    break;
                case Enums.additionalPropertyType.IndividualData:
                    var selectedEntities = typeof property.Value === 'object' ?
                        property.Value[property.SubType] || [] :
                        [];

                    searchableValue = $.map(selectedEntities, function (id) {
                        return IndividualData.GetEntityTitle(property.SubType, id);
                    }).filter(function (title) {
                        return title != null;
                    });

                    break;
            }

            return searchRegExp.test(searchableValue);
        });

        return additionalPropertiesMatch;
    }

    function onSelectNode(_event, data) {
        treeNodeChangedByCenterNavigation = true;

        if (Mode === 'individual-data') {
            location.hash = '#{0}/{1}'.format(IndividualData.GetCurrentMode(), data.node.id);
        } else {
            var locationHash = '#{0}/{1}'.format(Mode, data.node.id);

            if (Mode === 'explorer') {
                var currentTab = Explorer.GetCurrentTab();

                if (currentTab) {
                    locationHash = '#{0}/{1}/{2}'.format(Mode, currentTab.OID, data.node.id);
                }
            }

            location.hash = locationHash;
        }
    }

    function onMoveTreeNode(_event, data) {
        var entity = data.node.entity;
        var oldParentEntity = entity.Parent;
        var newParentEntity = data.parentNode.entity;
        var previousEntity = data.previousNode ? data.previousNode.entity : null;
        var nextEntity = data.nextNode ? data.nextNode.entity : null;
        var updateList = [entity];
        var idx, pos, oldLayout, oldAncestor, newAncestor, isDescendantOf;


        // zu neuen Parent hinzufügen
        entity.ParentOID = newParentEntity.OID;
        entity.Parent = newParentEntity;
        oldParentEntity.Children = Tools.remove(oldParentEntity.Children, entity.OID, 'OID');
        newParentEntity.Children = newParentEntity.Children || [];

        if (previousEntity) {
            idx = Tools.indexOf(newParentEntity.Children, previousEntity.OID, 'OID');
            idx = !!~idx ? idx + 1 : newParentEntity.Children.length;
        } else if (nextEntity) {
            idx = Tools.indexOf(newParentEntity.Children, nextEntity.OID, 'OID');
            idx = !!~idx ? idx : newParentEntity.Children.length;
        } else {
            idx = newParentEntity.Children.length;
        }

        newParentEntity.Children.splice(idx, 0, entity);


        // ggf. Position anpassen
        newParentEntity.Children.forEach(function (entity) {
            if (entity.ModificationType === Enums.ModificationType.Deleted) {
                return;
            }

            if (entity.Position <= pos) {
                entity.Position = pos + 1;
                updateList = Tools.addUnique(updateList, entity);
            }

            pos = entity.Position;
        });


        // ggf. deaktivieren
        if (!newParentEntity.Enabled &&
            Tools.contains(['forms', 'items', 'explorerchangemode', 'form-templates', 'testgroup-templates', 'master-data-templates'], Mode)) {
            (function traverse(entity) {
                if (entity.ModificationType === Enums.ModificationType.Deleted || !entity.Enabled) {
                    return;
                }

                entity.Enabled = false;

                updateList = Tools.addUnique(updateList, entity);

                (entity.Children || []).forEach(traverse);
                (entity.Parametergroups || []).forEach(traverse);
                (entity.Parameters || []).forEach(traverse);
            })(entity);
        }


        // ggf. Layout anpassen
        if (Mode === 'explorerchangemode') {
            if (oldParentEntity !== newParentEntity) {
                oldLayout = oldParentEntity.Layout;
                oldAncestor = oldParentEntity;

                if (!oldLayout) {
                    while ((oldAncestor = oldAncestor.Parent)) {
                        if (oldAncestor.Layout) {
                            oldLayout = oldAncestor.Layout;
                            break;
                        }
                    }
                }

                if (oldLayout) {
                    isDescendantOf = false;
                    newAncestor = entity;

                    while (((newAncestor = newAncestor.Parent))) {
                        if (newAncestor === oldAncestor) {
                            isDescendantOf = true;
                            break;
                        }
                    }

                    if (!isDescendantOf) {
                        (function walk(entity) {
                            if (entity.ModificationType === Enums.ModificationType.Deleted) {
                                return;
                            }

                            oldAncestor.Layout = FloorPlan.DeleteLocationFromLayout(oldAncestor.Layout, entity.OID);

                            (entity.Children || []).forEach(walk);
                        }(entity));

                        if (oldAncestor.Layout !== oldLayout) {
                            updateList = Tools.addUnique(updateList, oldAncestor);
                        }
                    }
                }
            }
        }


        switch (Mode) {
            case 'events':
                Tools.Progressbar.Start(updateList.length, i18next.t('progressbar.movingScheduling'), null, true);

                (function loop() {
                    var entity = updateList.pop();

                    if (entity) {
                        Tools.Progressbar.StartProgress();

                        scheduling.put(entity).then(function () {
                            loop();

                            Tools.Progressbar.StopProgress();
                        }, function () {
                            Tools.Progressbar.StopProgress();
                        });
                    }
                }());
                break;
            case 'forms':
            case 'items':
            case 'explorerchangemode':
            case 'form-templates':
            case 'testgroup-templates':
            case 'master-data-templates':
            case 'property-templates':
                Tools.Progressbar.Start(updateList.length, i18next.t('progressbar.movingElement'), null, true);

                (function loop() {
                    var entity = updateList.pop();

                    if (entity) {
                        Tools.Progressbar.StartProgress();

                        putElement(entity, true).then(function () {
                            loop();

                            Tools.Progressbar.StopProgress();
                        }, function () {
                            Tools.Progressbar.StopProgress();
                        });
                    }
                }());
                break;
            case 'categories':
            case 'priorities':
            case 'keywords':
            case 'classifications':
            case 'status':
            case 'units':
                Tools.Progressbar.Start(updateList.length, i18next.t('progressbar.movingProperty'), null, true);

                (function loop() {
                    var entity = updateList.pop();

                    if (entity) {
                        Tools.Progressbar.StartProgress();

                        putProperty(entity, true).then(function () {
                            loop();

                            Tools.Progressbar.StopProgress();
                        }, function () {
                            Tools.Progressbar.StopProgress();
                        });
                    }
                }());
                break;
            case 'teams':
                Tools.Progressbar.Start(updateList.length, i18next.t('progressbar.movingTeam'), null, true);

                (function loop() {
                    var entity = updateList.pop();

                    if (entity) {
                        Tools.Progressbar.StartProgress();

                        teams.put(entity).then(function () {
                            loop();

                            Tools.Progressbar.StopProgress();
                        }, function () {
                            Tools.Progressbar.StopProgress();
                        });
                    }
                }());
                break;
            case 'files':
                Tools.Progressbar.Start(updateList.length, i18next.t('progressbar.movingFolder'), null, true);

                (function loop() {
                    var entity = updateList.pop();

                    if (entity) {
                        Tools.Progressbar.StartProgress();

                        putFolder(entity, true).then(function () {
                            loop();

                            Tools.Progressbar.StopProgress();
                        }, function () {
                            Tools.Progressbar.StopProgress();
                        });
                    }
                }());
                break;
            case 'mail-templates':
                Tools.Progressbar.Start(updateList.length, i18next.t('progressbar.movingMailTemplate'), null, true);

                (function loop() {
                    var entity = updateList.pop();

                    if (entity) {
                        Tools.Progressbar.StartProgress();

                        MailTemplates.Save(entity).then(function () {
                            loop();

                            Tools.Progressbar.StopProgress();
                        }, function (xhr) {
                            Tools.Progressbar.StopProgress();
                            return Tools.handleHttpError(Enums.HttpActionType.Write, xhr);
                        });
                    }
                }());
        }


        resetSearch();
        resetDepthChooser();
    }

    function moveListNode(_event, data) {
        if (!data) {
            return;
        }

        var entity = data.node.entity;
        var newParentEntityChildren = data.parentNode.children;
        var menuItem;

        switch (Mode) {
            case 'custom-menu-items':
                var newMenuitemPosition = Tools.indexOf(newParentEntityChildren, function (node) {
                    if (node.entity.OID === entity.OID) {
                        return true;
                    }
                });

                if (newMenuitemPosition === -1) {
                    break;
                }

                for (var i = newMenuitemPosition; i < (newParentEntityChildren || []).length; i++) {
                    if (!entity.OID) {
                        continue;
                    }

                    menuItem = newParentEntityChildren[i].entity;

                    if (menuItem == null) {
                        continue;
                    }

                    menuItemOID = menuItem.OID;

                    if (changemode.CustomMenuItems[menuItemOID] == null) {
                        continue;
                    }

                    changemode.CustomMenuItems[menuItemOID].Position = i;

                    CustomMenuItems.SaveMenuItem(changemode.CustomMenuItems[menuItemOID], true);
                }

                break;
        }

    }

    function onDropTreeNode(_event, data) {
        var entity = data.node.entity;
        var parentEntity = data.parentNode.entity;
        var previousEntity = data.previousNode ? data.previousNode.entity : null;
        var nextEntity = data.nextNode ? data.nextNode.entity : null;
        var prototype, parent, ancestor, previous, next, selectedOID, selected, addFn;

        if (Mode === 'events') {
            prototype = changemode.Schedules[entity.OID];
            parent = changemode.Scheduling[parentEntity.OID];

            ChangeMode.Schedules
                .AddPrototype(prototype, parent)
                .done(function () {
                    Commit.setChanged(true);

                    scheduling.createScheduleList();
                    setChangeModeTabCounter();
                });
        } else {
            switch (entity.Type) {
                case Enums.elementType.ObjectTemplate:
                case Enums.elementType.FormTemplate:
                    prototype = changemode.Elements[entity.OID];
                    parent = changemode.Elements[parentEntity.OID];
                    previous = previousEntity ? changemode.Elements[previousEntity.OID] : null;
                    next = nextEntity ? changemode.Elements[nextEntity.OID] : null;
                    addFn = entity.Type === Enums.elementType.ObjectTemplate ? ChangeMode.Elements.AddItem : ChangeMode.Elements.AddFormTemplate;

                    addFn(prototype, parent, previous, next, function (entity) {
                        if (previous) {
                            addAfter(entity, previous);
                        } else if (next) {
                            addBefore(entity, next);
                        } else {
                            addTo(entity, parent);
                        }
                    });
                    break;
                case Enums.elementType.Sensor:
                case Enums.elementType.CashRegister:
                    prototype = changemode.Elements[entity.OID];
                    parent = changemode.Elements[parentEntity.OID];
                    previous = previousEntity ? changemode.Elements[previousEntity.OID] : null;
                    next = nextEntity ? changemode.Elements[nextEntity.OID] : null;

                    ChangeMode.Elements.AddExternalDataSource(prototype, parent, previous, next, function (entity) {
                        ChangeMode.Catalog.Remove(prototype);

                        if (previous) {
                            addAfter(entity, previous);
                        } else if (next) {
                            addBefore(entity, next);
                        } else {
                            addTo(entity, parent);
                        }
                    });
                    break;
                case Enums.elementType.PropertyTemplate:
                    prototype = changemode.Elements[entity.OID];
                    parent = changemode.Elements[parentEntity.OID];

                    if (parent === CurrentEntity && content.Mode === 'properties') {
                        AdditionalProperties.AddPropertyFromCatalog(prototype);
                    } else {
                        ChangeMode.Elements.AddPropertyTemplate(prototype, parent, function () {
                            if (parent === CurrentEntity) {
                                if (content.Mode === 'properties') {
                                    AdditionalProperties.Init();
                                } else {
                                    $('#change-mode-tab-menu .tab-view-tab[data-tab-id="properties"]').trigger('click');
                                }
                            }
                        });
                    }
                    break;
                case Enums.elementType.CheckpointGroupTemplate:
                    prototype = changemode.Elements[entity.OID];
                    parent = changemode.Elements[parentEntity.OID];

                    if (content.Mode === 'checkpoints' && content.parameters.$domTree) {
                        selectedOID = content.parameters.$domTree.find('.selected').data('oid');
                        selected = changemode.Elements[selectedOID];

                        if (selected && Tools.contains(Enums.elementType.AllGroups, selected.Type)) {
                            previous = selected;
                        } else if (selected && selected.Parent && Tools.contains(Enums.elementType.AllGroups, selected.Parent.Type)) {
                            previous = selected.Parent;
                        }
                    }

                    ChangeMode.Elements.AddGroupTemplate(prototype, parent, previous, next, function () {
                        if (CurrentEntity.OID === parentEntity.OID) {
                            if (content.Mode !== 'checkpoints') {
                                $('#change-mode-tab-menu .tab-view-tab[data-tab-id="checkpoints"]').trigger('click');
                            } else {
                                content.parameters.createList(parent, 1);
                            }
                        }

                        setChangeModeTabCounter();
                    });
                    break;
                case Enums.elementType.MasterdataTemplate:
                    prototype = changemode.Elements[entity.OID];
                    parent = changemode.Elements[parentEntity.OID];

                    if (content.Mode === 'info' && content.parameters.$domTree) {
                        selectedOID = content.parameters.$domTree.find('.selected').data('oid');
                        selected = changemode.Elements[selectedOID];

                        if (selected && selected.Type === Enums.elementType.MasterdataGroup) {
                            previous = selected;
                        } else if (selected && selected.Parent && selected.Parent.Type === Enums.elementType.MasterdataGroup) {
                            previous = selected.Parent;
                        }
                    }

                    ChangeMode.Elements.AddMasterdataTemplate(prototype, parent, previous, next, function () {
                        var html;

                        if (CurrentEntity.OID === parentEntity.OID) {
                            if (content.Mode !== 'info') {
                                $('#change-mode-tab-menu .tab-view-tab[data-tab-id="info"]').trigger('click');
                            } else if ($('#tbl-content-masterdata').length) {
                                content.parameters.createList(parent, 2);
                            } else {
                                html = content.views.InfoTab.masterdata.show(CurrentEntity);
                                $('#showInfoMasterdata').html(html);
                            }
                        }

                        setChangeModeTabCounter();
                    });
                    break;
                default:
                    prototype = changemode.Elements[entity.OID];

                    if (prototype.Parent.Type === Enums.elementType.CheckpointGroupTemplate) {
                        if (content.Mode === 'checkpoints' && content.parameters.$domTree) {
                            selectedOID = content.parameters.$domTree.find('.selected').data('oid');
                            selected = changemode.Elements[selectedOID];

                            if (selected && Tools.contains(Enums.elementType.AllGroups, selected.Type)) {
                                ancestor = selected;
                            } else if (selected && selected.Parent && Tools.contains(Enums.elementType.AllGroups, selected.Parent.Type)) {
                                ancestor = selected.Parent;
                                previous = selected;
                            }
                        }

                        ancestor = ancestor || changemode.Elements[parentEntity.OID];

                        ChangeMode.Elements.AddGroupParameterTemplate(prototype, ancestor, previous, next, function () {
                            if (CurrentEntity.OID === parentEntity.OID) {
                                if (content.Mode !== 'checkpoints') {
                                    $('#change-mode-tab-menu .tab-view-tab[data-tab-id="checkpoints"]').trigger('click');
                                } else {
                                    content.parameters.createList(parent, 1);
                                }
                            }

                            setChangeModeTabCounter();
                        });
                    } else if (prototype.Parent.Type === Enums.elementType.MasterdataTemplate) {
                        if (content.Mode === 'info' && content.parameters.$domTree) {
                            selectedOID = content.parameters.$domTree.find('.selected').data('oid');
                            selected = changemode.Elements[selectedOID];

                            if (selected && selected.Type === Enums.elementType.MasterdataGroup) {
                                ancestor = selected;
                            } else if (selected && selected.Parent && selected.Parent.Type === Enums.elementType.MasterdataGroup) {
                                ancestor = selected.Parent;
                                previous = selected;
                            }
                        }

                        ancestor = ancestor || changemode.Elements[parentEntity.OID];

                        ChangeMode.Elements.AddMasterdataParameterTemplate(prototype, ancestor, previous, next, function () {
                            var html;

                            if (CurrentEntity.OID === parentEntity.OID) {
                                if (content.Mode !== 'info') {
                                    $('#change-mode-tab-menu .tab-view-tab[data-tab-id="info"]').trigger('click');
                                } else if ($('#tbl-content-masterdata').length) {
                                    content.parameters.createList(parent, 2);
                                } else {
                                    html = content.views.InfoTab.masterdata.show(CurrentEntity);
                                    $('#showInfoMasterdata').html(html);
                                }
                            }

                            setChangeModeTabCounter();
                        });
                    }
                    break;
            }
        }
    }

    function onSearch(_event, data) {
        var resultCount = _tree
            .search(Tools.escapeHtml(data.input))
            .getSearchResultCount();

        _search.setResultCount(resultCount);
    }

    function onSelectPreviousSearchResult() {
        _tree.selectPreviousSearchResult();
    }

    function onSelectNextSearchResult() {
        _tree.selectNextSearchResult();
    }

    function onSelectDepth() {
        var $this = $(this);

        if ($this.hasClass('previous-depth')) {
            if (_currentDepth <= 1) {
                return;
            }

            _currentDepth--;
        } else if ($this.hasClass('next-depth')) {
            if (_currentDepth >= _maxDepth) {
                return;
            }

            _currentDepth++;
        } else {
            _currentDepth = $this.data('depth');
        }

        $depthChooser
            .children('.active')
            .removeClass('active');

        $depthChooser
            .children('.depth-choice[data-depth="{0}"]'.format(_currentDepth))
            .addClass('active');

        _tree.openDepth(_currentDepth);
    }

    function onFocusCurrentEntity() {
        select(CurrentEntity);
    }

    function onActionDropdownButtonClick(evt) {
        evt.stopPropagation();

        var $this = $(this);

        $this.toggleClass('active');

        if ($this.hasClass('active')) {
            Tools.createActionDropdown(getActionDropdownItems(), $this);

            $('body').on('click.closeActionWindow', function () {
                $this.removeClass('active');
                $this
                    .find('.action-dropdown')
                    .removeClass('is-visible')
                    .css('max-height', 0);

                $actionDropdownButton.attr('title', i18next.t('treeNavigation.showActions'));
            });

            initDataImport($this);
            $actionDropdownButton.attr('title', i18next.t('treeNavigation.hideActions'));
        } else {
            $this
                .find('.action-dropdown')
                .removeClass('is-visible')
                .css('max-height', 0);

            $actionDropdownButton.attr('title', i18next.t('treeNavigation.showActions'));
        }
    }

    function getActionDropdownItems() {
        if (Mode === 'explorer') {
            return getExplorerActionDropdownItems();
        } else if (Mode === 'calendar') {
            return getGenericTreeDropdownItems();
        } else if (Mode === 'individual-data') {
            var schema = IndividualData.GetCurrentSchema();

            if (schema && schema.IsReadonly) {
                return getGenericTreeDropdownItems();
            }
        }

        var items = [{
            Caption: getAddEntityCaption(),
            ID: 'add-room',
            Icon: getActionDropdownAddEntityIconPath(),
            OnClick: function () {
                content.add(content.CurrentEntity);
            }
        }];

        items = items.concat(getGenericTreeDropdownItems());

        if (showCatalogButton()) {
            items.push({
                Caption: i18next.t('changeMode.openCatalog'),
                ID: 'open-catalog',
                Icon: './img/menu/katalog.svg',
                OnClick: ChangeMode.Catalog.Show
            });
        }

        if (showExportButtons()) {
            if (Mode !== 'users') {
                items.push({
                    Caption: i18next.t('changeMode.export.all'),
                    ID: 'export-all',
                    Icon: './img/menu/alle-exportieren.svg',
                    OnClick: function () {
                        onExportClick(false);
                    }
                }, {
                    Caption: i18next.t('changeMode.export.onlyActive'),
                    ID: 'export-active',
                    Icon: './img/menu/nur-aktive-exportieren.svg',
                    OnClick: function () {
                        onExportClick(true);
                    }
                });
            } else {
                items.push({
                   Caption: i18next.t('changeMode.export.exampleFile'),
                   ID: 'export-user-example',
                   Icon: './img/menu/alle-exportieren.svg',
                   OnClick: onUserExampleExportClick
                });
            }

            if ((Client.Settings || {}).ShowTUEVAuditAnalysis && (CurrentEntity.AdditionalSettings || {}).IsStoreRegion) {
                items.push({
                    Caption: i18next.t('changeMode.export.storeRegion'),
                    ID: 'export-store-region',
                    Icon: './img/menu/alle-exportieren.svg',
                    OnClick: onTUEVStoreExportClick
                });
            }
        }

        if (showImportButton()) {
            items.push({
                Caption: i18next.t('changeMode.import'),
                ID: 'import',
                Icon: './img/menu/importieren.svg',
                FileInput: {
                    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            });
        }

        return items;
    }

    function getGenericTreeDropdownItems() {
        var centerTreeSearchResultsRepresentation = Tools.GetOfficeSettingValue('CenterTreeSearchResultsRepresentation');

        return [{
            Caption: i18next.t('treeNavigation.searchResultsRepresentation.nodeCaption'),
            ID: 'search-results-settings',
            Icon: './img/magnifier.svg',
            Children: [{
                Caption: i18next.t('treeNavigation.searchResultsRepresentation.tree'),
                Icon: './img/hierarchy.svg',
                ID: 'show-search-results-as-tree',
                Checked: !centerTreeSearchResultsRepresentation || centerTreeSearchResultsRepresentation === Enums.CenterTreeSearchResultsRepresentation.Tree,
                OnClick: function () {
                    var representationType = Enums.CenterTreeSearchResultsRepresentation.Tree;

                    _tree
                        .setSearchResultsRepresentation(representationType)
                        .refresh();

                    Tools.UpdateOfficeUserSettings('CenterTreeSearchResultsRepresentation', representationType);
                }
            }, {
                Caption: i18next.t('treeNavigation.searchResultsRepresentation.resultsOnlyList'),
                Icon: './img/list.svg',
                ID: 'show-search-results-as-list',
                Checked: centerTreeSearchResultsRepresentation === Enums.CenterTreeSearchResultsRepresentation.ResultsOnlyList,
                OnClick: function () {
                    var representationType = Enums.CenterTreeSearchResultsRepresentation.ResultsOnlyList;

                    _tree
                        .setSearchResultsRepresentation(representationType)
                        .refresh();

                    Tools.UpdateOfficeUserSettings('CenterTreeSearchResultsRepresentation', representationType);
                }
            }]
        }];
    }

    function getExplorerActionDropdownItems() {
        var items = getGenericTreeDropdownItems();

        if (!(ressources.users.hasRight('EXMD') && showExportButtons())) {
            return items;
        }

        return items.concat([{
            Caption: i18next.t('explorer.export.masterdata.nodeCaption'),
            Icon: './img/menu/alle-exportieren.svg',
            ID: 'masterdata-export-actions',
            Children: [{
                Caption: i18next.t('explorer.export.masterdata.completeHierarchy'),
                ID: 'export-masterdata-all',
                Icon: './img/menu/alle-exportieren.svg',
                OnClick: onExportMasterdataOfCompleteHierarchyClick
            }, {
                Caption: i18next.t('explorer.export.masterdata.currentRoom'),
                ID: 'export-masterdata-current-room',
                Icon: './img/menu/alle-exportieren.svg',
                OnClick: onExportMasterdataOfCurrentLocationClick
            }]
        }]);
    }

    function getAddEntityCaption() {
        switch (Mode) {
            case 'forms':
                return i18next.t('changeMode.forms.newForm');
            case 'schedules':
                return i18next.t('changeMode.schedules.newSchedule');
            case 'events':
                return i18next.t('changeMode.schedulings.newScheduling');
            case 'files':
                return i18next.t('changeMode.files.newFolder');
            case 'items':
                return i18next.t('changeMode.items.newItem');
            case 'custom-menu-items':
                return i18next.t('changeMode.customMenuItems.newCustomMenuItem');
            case 'form-templates':
                return i18next.t('changeMode.formTemplates.newFormTemplate');
            case 'explorerchangemode':
                return i18next.t('changeMode.rooms.newRoom');
            case 'keywords':
                return i18next.t('changeMode.keywords.newKeyword');
            case 'classifications':
                return i18next.t('changeMode.classifications.newClassification');
            case 'priorities':
                return i18next.t('changeMode.priorities.newPriority');
            case 'categories':
                return i18next.t('changeMode.categories.newCategory');
            case 'status':
                return i18next.t('changeMode.status.newStatus');
            case 'units':
                return i18next.t('changeMode.units.newUnit');
            case 'roles':
                return i18next.t('changeMode.roles.newRole');
            case 'contacts':
                return i18next.t('changeMode.contacts.newContact');
            case 'contact-groups':
                return i18next.t('changeMode.contactGroups.newContactGroup');
            case 'users':
                return i18next.t('changeMode.users.newUser');
            case 'teams':
                return i18next.t('changeMode.teams.newTeam');
            case 'testgroup-templates':
                return i18next.t('changeMode.testgroups.newTestgroup');
            case 'master-data-templates':
                return i18next.t('changeMode.masterData.newMasterDataTemplate');
            case 'property-templates':
                return i18next.t('changeMode.properties.newPropertyTemplate');
            case 'mail-templates':
                return i18next.t('changeMode.mailTemplates.newMailTemplate');
            case 'element-codings':
                return i18next.t('changeMode.elementCodings.newElementCoding');
            default:
                var urlHash = window.location.hash;

                if (!!urlHash && urlHash.contains('-modifications')) {
                    var schemaLength = urlHash.indexOf('-modifications');
                    var schemaName = urlHash.substr(1, schemaLength - 1);
                    var schema = IndividualData.GetSchema(schemaName);

                    if (schema) {
                        return '+ ' + schema.NameSingular;
                    }
                }
        }
    }

    function showCatalogButton() {
        if (!(Client.Licenses && Client.Licenses.Catalog)) {
            return false;
        }

        var availableInModes = ['explorerchangemode', 'forms', 'items', 'form-templates'];

        return Tools.indexOf(availableInModes, Mode) > -1;
    }

    function showExportButtons() {
        if (!(Client.Licenses && Client.Licenses.AllowExcelExport && CurrentEntity)) {
            return false;
        }

        var availableInModes = ['explorer', 'explorerchangemode', 'forms', 'items', 'form-templates', 'users'];

        return Tools.indexOf(availableInModes, Mode) > -1;
    }

    function showImportButton() {
        var availableInModes = ['explorerchangemode', 'forms', 'items', 'form-templates', 'users'];

        return Tools.indexOf(availableInModes, Mode) > -1;
    }

    function onExportClick(onlyActive) {
        var exportType;

        switch (Mode) {
            case 'explorerchangemode':
                exportType = 'Locations';
                break;
            case 'items':
                exportType = 'Items';
                break;
            case 'forms':
                exportType = 'Forms';
                break;
            case 'form-templates':
                exportType = 'FormTemplates';
                break;
            default:
                return;
        }

        exportElements({
            ElementOID: CurrentEntity.OID,
            ExportType: exportType,
            OnlyActive: !!onlyActive
        });
    }

    function onTUEVStoreExportClick() {
        if (!Client || !Client.Settings || !Client.Settings.ShowTUEVAuditAnalysis ||
            !CurrentEntity || !CurrentEntity.AdditionalSettings || !CurrentEntity.AdditionalSettings.IsStoreRegion) {
            return;
        }

        exportElements({
            ElementOID: CurrentEntity.OID,
            ExportType: 'StoreRegion',
            OnlyActive: false
        });
    }

    function exportElements(config) {
        if (!config) {
            return;
        }

        Tools.Spinner.show();

        Tools.http.post('export/elements/', config)
            .then(onExportSuccess)
            .fail(function (xhr) {
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                Tools.Spinner.hide();
            });
    }

    function onExportMasterdataOfCompleteHierarchyClick() {
        if (!DataManager.OrganizationUnitLoader.Data.Root) {
            return;
        }

        exportMasterdata({
            LocationOID: DataManager.OrganizationUnitLoader.Data.Root.OID,
            WithChildLocations: true,
            OnlyActive: Explorer.GetWithDeactivatedElements() === false
        });
    }

    function onExportMasterdataOfCurrentLocationClick() {
        if (!CurrentEntity) {
            return;
        }

        exportMasterdata({
            LocationOID: CurrentEntity.OID,
            WithChildLocations: false,
            OnlyActive: Explorer.GetWithDeactivatedElements() === false
        });
    }

    function exportMasterdata(config) {
        if (!config) {
            return;
        }

        Tools.Spinner.show();

        Tools.http.post('export/masterdata/', config)
            .then(onExportSuccess)
            .fail(function (xhr) {
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                Tools.Spinner.hide();
            });
    }

    function onUserExampleExportClick() {
        Tools.Spinner.show();

        var data = {
            InvalidRows: [],
            ExportType: 'Example'
        };

        Tools.http.post('export/users', data)
            .then(function (filename) {
                return onExportSuccess(filename, 'user-import-example');
            })
            .fail(function (xhr) {
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                Tools.Spinner.hide();
            });
    }

    function onExportSuccess(filename, type) {
        var friendlyDownloadName;

        switch (type) {
            case 'user-import-example':
                friendlyDownloadName = i18next.t('userImport.filenames.exampleFile') + '.xlsx';
                break;
            case 'user-import-errors':
                friendlyDownloadName = i18next.t('userImport.filenames.errorFile') + '.xlsx';
                break;
        }

        var url = '{0}export/users/{1}'.format(Config.BaseUri, filename);

        if (!!friendlyDownloadName) {
            url += '?friendlyDownloadName=' + friendlyDownloadName;
        }

        window.location = url;

        Tools.Spinner.hide();
    }

    function getActionDropdownAddEntityIconPath() {
        switch (Mode) {
            case 'forms':
                return './img/menu/formulare.svg';
            case 'schedules':
                return './img/menu/zeitplaene.svg';
            case 'events':
                return './img/menu/plaene.svg';
            case 'files':
                return './img/menu/ordner.svg';
            case 'items':
                return './img/menu/objekte.svg';
            case 'custom-menu-items':
                return './img/menu/menuepunkte-app.svg';
            case 'form-templates':
                return './img/menu/formularvorlagen.svg';
            case 'explorerchangemode':
                return './img/menu/raeume.svg';
            case 'keywords':
                return './img/menu/schlagwoerter.svg';
            case 'classifications':
                return './img/menu/klassifizierungen.svg';
            case 'priorities':
                return './img/menu/prioritaeten.svg';
            case 'categories':
                return './img/menu/werteklassen.svg';
            case 'status':
                return './img/menu/status.svg';
            case 'units':
                return './img/menu/einheiten.svg';
            case 'roles':
                return './img/menu/rollen.svg';
            case 'contacts':
                return './img/menu/kontakte.svg';
            case 'contact-groups':
                return './img/menu/kontaktgruppen.svg';
            case 'users':
                return './img/menu/benutzer.svg';
            case 'teams':
                return './img/menu/teams.svg';
            case 'testgroup-templates':
                return './img/menu/pruefgruppen.svg';
            case 'master-data-templates':
                return './img/menu/stammdaten.svg';
            case 'property-templates':
                return './img/menu/eigenschaften.svg';
            case 'mail-templates':
                return './img/menu/mail-templates.svg';
            case 'element-codings':
                return './img/menu/codierungen.svg';
            default:
                var urlHash = window.location.hash;

                if (!!urlHash && urlHash.contains('-modifications')) {
                    var schemaLength = urlHash.indexOf('-modifications');
                    var schemaName = urlHash.substr(1, schemaLength - 1);
                    var schema = IndividualData.GetSchema(schemaName);

                    if (schema) {
                        return './img/menu/allgemein.svg';
                    }
                }
        }
    }

    function initDataImport($container) {
        var usersMode = Mode === 'users';

        var options = {
            url: Config.BaseUri + 'import/' + (usersMode ? 'users' : 'elements'),
            params: function () {
                if (!usersMode) {
                    return { ImportType: 'Items' };
                }
            }
        };

        var $upload = $container.find('.file-upload');

        $upload.upload(options);

        $upload
            .on('start.upload', Tools.Spinner.show)
            .on('uploaded.upload', usersMode ? onUserExcelImportUploaded : onElementExcelImportUploaded)
            .on('error.upload', Tools.OnUploadError);
    }

    function onUserExcelImportUploaded(_event, data) {
        // Überprüfen, ob überhaupt Lizenzen frei sind
        var assignedLicenses = Tools.GetAssignedUserLicensesCount();

        var userLicenses = Client.Licenses && Client.Licenses.UserLicenses ?
            Client.Licenses.UserLicenses :
            null;

        if (!userLicenses || (userLicenses.FullLicenses <= assignedLicenses.FullLicensesAssigned &&
            userLicenses.LimitedLicenses <= assignedLicenses.LimitedLicensesAssigned &&
            userLicenses.ViewRightLicenses <= assignedLicenses.ViewRightLicensesAssigned)) {
            Tools.Spinner.hide();
            Tools.Message.Show({
                title: i18next.t('changeMode.messages.noLicensesAvailable.title'),
                text: i18next.t('changeMode.messages.noLicensesAvailable.text'),
                ok: true
            });
            return;
        }

        var newUsers = {}; // Benutzer die importiert werden sollen

        var modifiedElements = [];
        var modifiedRoles = [];
        var modifiedTeams = [];

        // für die Überprüfüng, ob der Benutzername/Emailadresse mehrfach in der Datei vorkommt
        var usernames = [];
        var emailAddresses = [];

        var invalidRows = {}; // Key: userOID Value: fehlerhafte Zeile
        var validRows = {}; // Key: userOID Value: { User, Role, Team, Element, Item (Daten der Zeile) }

        Tools.Spinner.hide();

        var result = JSON.parse(data.response);

        if (result.Error === 'Invalid file format') {
            Tools.Message.Show({
                title: i18next.t('changeMode.messages.invalidFileFormat.title'),
                text: i18next.t('changeMode.messages.invalidFileFormat.text'),
                ok: true
            });
        } else if (result.Error) {
            Tools.Message.Show({
                title: i18next.t('changeMode.messages.importError.title'),
                text: i18next.t('changeMode.messages.importError.text'),
                ok: true
            });
        } else if ((result.Data || []).length) {
            Tools.Spinner.show();

            var teamParent = changemode.RootTeam;

            result.Data.forEach(function (rowData) {
                if (rowData == null) {
                    return;
                }

                var existingRole = null;
                var existingTeam = null;
                var existingOU = null;
                var licenseAndRoleCombinationIsInvalid = false;
                var newTeam = null;
                var licenseType = null;

                var item = Tools.Escape.ExcelImportUserItem(rowData);

                if (item.Errors == null) {
                    item.Errors = {};
                }

                var newUser = {
                    OID: uuid(),
                    EnforcePasswordPolicy: true,
                    ChangePassword: false,
                    Language: User.Language,
                    ModificationType: Enums.ModificationType.Added
                };

                if (item.Username) {
                    var trimmedUsername = item.Username.trim();
                    var usernameIsInvalid = (trimmedUsername.match(new RegExp('[:<>"]', 'g')) || []).length > 0;
                    var usernameExistsInClient = Tools.contains(Object.values(changemode.Users), function(user) {
                        return !user.Deleted &&
                            user.ModificationType !== Enums.ModificationType.Deleted &&
                            user.Username === trimmedUsername;
                    });

                    if (usernameIsInvalid) {
                        item.Errors[Enums.UserImport.Username] = Enums.UserImport.Errors.ContainsInvalidLetters;
                    } else if (usernameExistsInClient) {
                        item.Errors[Enums.UserImport.Username] = Enums.UserImport.Errors.AlreadyExist;
                    } else if (Tools.contains(usernames, trimmedUsername)) {
                        item.Errors[Enums.UserImport.Username] = Enums.UserImport.Errors.AlreadyExistsInFile;
                    } else {
                        usernames.push(trimmedUsername);
                        newUser.Username = trimmedUsername;
                    }
                }

                if (item.Password) {
                    newUser.Password = item.Password;
                }

                if (item.FirstName) {
                    newUser.Firstname = item.FirstName.trim();
                }

                if (item.LastName) {
                    newUser.Lastname = item.LastName.trim();
                }

                newUser.Fullname = newUser.Lastname && newUser.Firstname ?
                    newUser.Lastname + ', ' + newUser.Firstname :
                    newUser.Lastname || newUser.Firstname || newUser.Username;

                if (item.EmailAddress) {
                    var emailExistsInClient = Tools.contains(Object.values(changemode.Users), function(user) {
                        return !user.Deleted &&
                            user.ModificationType !== Enums.ModificationType.Deleted &&
                            user.EmailAddress &&
                            user.EmailAddress === item.EmailAddress;
                    });

                    if (emailExistsInClient) {
                        item.Errors[Enums.UserImport.EmailAddress] = Enums.UserImport.Errors.AlreadyExist;
                    } else if (Tools.contains(emailAddresses, item.EmailAddress)) {
                        item.Errors[Enums.UserImport.EmailAddress] = Enums.UserImport.Errors.AlreadyExistsInFile;
                    } else {
                        emailAddresses.push(item.EmailAddress);
                        newUser.EmailAddress = item.EmailAddress;
                    }
                }

                if (item.LicenseType) {
                    switch (item.LicenseType.trim().toLowerCase()) {
                        case 'volllizenz':
                            licenseType = Enums.LicenseType.Full;
                            break;
                        case 'eingeschränkt':
                            licenseType = Enums.LicenseType.Limited;
                            break;
                        case 'sichtrecht':
                            licenseType = Enums.LicenseType.ViewRight;
                            break;
                        default:
                            item.Errors[Enums.UserImport.UserLicenseType] = Enums.UserImport.Errors.IsInvalid;
                            break;
                    }
                }

                if (licenseType >= 0) {
                    newUser.LicenseType = licenseType;
                }

                if (item.Role) {
                    existingRole = Tools.getFirst(Object.values(changemode.Roles), function(role) {
                        return role.ModificationType !== Enums.ModificationType.Deleted && role.Title === item.Role;
                    });

                    if (existingRole == null) {
                        item.Errors[Enums.UserImport.Role] = Enums.UserImport.Errors.DoesNotExist;
                    } else if (licenseType != null) {
                        // Volllizenz-Rollen dürfen nicht mit eingeschränkte und Sichtrechtbenutzer verknüpft werden
                        switch (licenseType) {
                            case Enums.LicenseType.Full:
                                break;
                            case Enums.LicenseType.Limited:
                                if (existingRole.LicenseType === Enums.LicenseType.Full) {
                                    licenseAndRoleCombinationIsInvalid = true;
                                }
                                break;
                            case Enums.LicenseType.ViewRight:
                                if (existingRole.LicenseType !== Enums.LicenseType.ViewRight) {
                                    licenseAndRoleCombinationIsInvalid = true;
                                }
                                break;
                            default:
                                licenseAndRoleCombinationIsInvalid = true;
                                break;
                        }
                    }
                }

                if (licenseAndRoleCombinationIsInvalid) {
                    item.Errors[Enums.UserImport.LicenseType] = Enums.UserImport.Errors.WrongLicenseCombination;
                }

                if (existingRole && !licenseAndRoleCombinationIsInvalid) {
                    modifiedRoles = Tools.addUnique(modifiedRoles, existingRole, 'OID');
                }

                if (item.Team) {
                    existingTeam = Tools.getFirst(Object.values(changemode.Teams), function(team) {
                        return team.ModificationType !== Enums.ModificationType.Deleted && team.Title === item.Team;
                    });

                    if (existingRole != null && !licenseAndRoleCombinationIsInvalid) {
                        if (existingTeam) {
                            modifiedTeams.push(existingTeam);
                        } else if ((newTeam = Tools.getFirst(modifiedTeams, item.Team, 'Title')) == null) { // damit ein Team nicht mehrfach erstellt wird
                            newTeam = {
                                OID: uuid(),
                                Color: '#7E1210',
                                ParentOID: teamParent.OID,
                                Parent: teamParent,
                                Title: item.Team,
                                Position: getMaxPosition(teamParent.Children) + 1
                            };

                            modifiedTeams.push(newTeam);
                        }
                    }
                }

                if (item.OrganisationUnit) {
                    existingOU = Tools.getFirst(Object.values(changemode.Elements), function(elem) {
                        return elem.ModificationType !== 3 &&
                            (elem.Type === Enums.elementType.Location || elem.Type === Enums.elementType.Root) &&
                            elem.Title === item.OrganisationUnit;
                    });

                    if (existingOU == null) {
                        item.Errors[Enums.UserImport.OrganisationUnit] = Enums.UserImport.Errors.DoesNotExist;
                    } else {
                        var elementIndex = Tools.indexOf(modifiedElements, existingOU, 'OID');

                        if (elementIndex >= 0) {
                            existingOU = modifiedElements[elementIndex];
                        }

                        var teamOID = (existingTeam || newTeam || {}).OID;
                        if (teamOID && !Tools.contains(existingOU.Teams, teamOID, 'OID')) {
                            if (existingOU.Teams == null) {
                                existingOU.Teams = [];
                            }

                            existingOU.Teams.push({ OID: teamOID });
                        }

                        modifiedElements = Tools.addUnique(modifiedElements, existingOU, 'OID');
                    }
                }

                if (Tools.hasProperties(item.Errors)) {
                    invalidRows[newUser.OID] = item;
                } else {
                    newUsers[newUser.OID] = newUser;

                    validRows[newUser.OID] = {
                        Role: existingRole,
                        Team: Tools.Escape.Team(existingTeam || newTeam),
                        User: Tools.Escape.User(newUser),
                        Element: existingOU,
                        Item: item
                    };
                }
            });

            var invalidRowsArray = Object.values(invalidRows || {});

            if (!Tools.hasProperties(newUsers)) {
                if (invalidRowsArray.length === 0) {
                    showUserImportIsEmptyMessage();
                    Tools.Spinner.hide();
                    return;
                }

                showUserImportErrors(invalidRowsArray);

                Tools.Spinner.hide();
                return;
            }

            var newUsersList = Object.values(newUsers);

            var newFullLicensesCount = newUsersList.filter(function(newUser) {
                return newUser.LicenseType === Enums.LicenseType.Full;
            }).length + assignedLicenses.FullLicensesAssigned;

            var newLimitedLicensesCount = newUsersList.filter(function(newUser) {
                return newUser.LicenseType === Enums.LicenseType.Limited;
            }).length + assignedLicenses.LimitedLicensesAssigned;

            var newViewRightLicensesCount = newUsersList.filter(function(newUser) {
                return newUser.LicenseType === Enums.LicenseType.ViewRight;
            }).length + assignedLicenses.ViewRightLicensesAssigned;

            var hasEnoughFullLicenses = userLicenses.FullLicenses == null
                ? true
                : userLicenses.FullLicenses >= newFullLicensesCount;
            var hasEnoughLimitedLicenses = userLicenses.LimitedLicenses == null
                ? true
                : userLicenses.LimitedLicenses >= newLimitedLicensesCount;
            var hasEnoughViewRightLicenses = userLicenses.ViewRightLicenses == null
                ? true
                : userLicenses.ViewRightLicenses >= newViewRightLicensesCount;

            var licenses = [];

            if (!hasEnoughFullLicenses) {
                licenses.push(i18next.t('changeMode.panels.properties.fullLicense'));
            }

            if (!hasEnoughLimitedLicenses) {
                licenses.push(i18next.t('changeMode.panels.properties.limitedLicense'));
            }

            if (!hasEnoughViewRightLicenses) {
                licenses.push(i18next.t('changeMode.panels.properties.viewRightLicense'));
            }

            if (licenses.length > 0) {
                Tools.Spinner.hide();
                Tools.Message.Show({
                    title: i18next.t('changeMode.messages.notEnoughLicensesAvailable.title'),
                    text: i18next.t('changeMode.messages.notEnoughLicensesAvailable.text', { licenses: licenses.join(', ')}),
                    ok: true
                });
                return;
            }

            checkUsernameAndEmailAddressAvailability(newUsers, validRows, invalidRows)
                .then(function (validRows, invalidRows) {
                    var validRowsList = Object.values(validRows);

                    if (validRowsList.length === 0) {
                        if (Object.keys(invalidRows).length === 0) {
                            Tools.Spinner.hide();
                            showUserImportIsEmptyMessage();

                            return $.Deferred().reject();
                        }

                        return $.Deferred().resolve(invalidRows);
                    }

                    return importNewUsers(validRowsList);
                })
                .then(function (){
                    if (invalidRows) {
                        showUserImportErrors(invalidRowsArray, Object.values(newUsers));
                    }
                })
                .always(Tools.Spinner.hide);
        } else {
            Tools.Message.Show({
                title: i18next.t('changeMode.messages.importNoData.title'),
                text: i18next.t('changeMode.messages.importNoData.text'),
                ok: true
            });
        }
    }

    function showUserImportIsEmptyMessage() {
        Tools.Message.Show({
            title: i18next.t('changeMode.messages.importNoData.title'),
            text: i18next.t('changeMode.messages.importNoData.text'),
            ok: true
        });
    }

    function checkUsernameAndEmailAddressAvailability(newUsers, validRows, invalidRows) {
        var newUsersArray = Object.values(newUsers);

        function availabilityCheckForUser(user) {
            var availabilityDeferred = $.Deferred();
            var doNotImport = false;

            if (user == null) {
                return availabilityDeferred.resolve().promise();
            }

            function usernameIsNotAvailable() {
                var row = validRows[user.OID];

                if (row && row.Item) {
                    if (row.Item.Errors == null) {
                        row.Item.Errors = {};
                    }

                    row.Item.Errors[Enums.UserImport.Username] = Enums.UserImport.Errors.AlreadyExist;
                }

                doNotImport = true;

                return checkEmailAddressAvailability();
            }

            function checkEmailAddressAvailability() {
                var emailAddressCheckDeferred = $.Deferred();

                function onFail() {
                    emailIsNotAvailable();
                    emailAddressCheckDeferred.resolve();
                }

                if (user.EmailAddress) {
                    Tools.CheckAvailability(
                        user.EmailAddress,
                        { email: user.EmailAddress, type: 2 },
                        function () {
                            if (doNotImport) {
                                removeFromImport();
                            }

                            emailAddressCheckDeferred.resolve();
                        },
                        onFail
                    );
                } else {
                    removeFromImport();
                    emailAddressCheckDeferred.resolve();
                }

                return emailAddressCheckDeferred.promise()
                    .then(function() {
                        availabilityDeferred.resolve();
                    });
            }

            function emailIsNotAvailable() {
                var row = validRows[user.OID];

                if (row && row.Item) {
                    if (row.Item.Errors == null) {
                        row.Item.Errors = {};
                    }

                    row.Item.Errors[Enums.UserImport.EmailAddress] = Enums.UserImport.Errors.AlreadyExist;
                }

                doNotImport = true;
                removeFromImport();
            }

            function removeFromImport() {
                if (!doNotImport) {
                    return;
                }

                var importItem = validRows[user.OID].Item;

                delete validRows[user.OID];
                delete newUsers[user.OID];

                invalidRows[user.OID] = importItem;
            }

            Tools.CheckAvailability(
                user.Username,
                { username: user.Username, type: 1 },
                checkEmailAddressAvailability,
                usernameIsNotAvailable
            );

            return availabilityDeferred.promise();
        }

        return Tools.ForEachDeferredAction(
            newUsersArray,
            availabilityCheckForUser,
            {
                Visible: true,
                Text: i18next.t('progressbar.checkingUserAndEmailAvailability'),
                ScreenBlock: true
            })
            .then(function() {
                return $.Deferred().resolve(validRows, invalidRows);
            });
    }

    function importNewUsers(validRowsList) {
        function createNewUser(userRow) {
            var userImportDeferred = $.Deferred();

            ressources.users.put(userRow.User, function () {
                changemode.Users[userRow.User.OID] = userRow.User;
                add(userRow.User);

                if (userRow.Team.Users == null) {
                    userRow.Team.Users = [];
                }

                var teamUser = Tools.getFirst(userRow.Team.Users, userRow.User.OID, 'OID');

                if (teamUser) {
                    teamUser.Roles = Tools.addUnique(teamUser.Roles, userRow.Role.OID);
                } else {
                    userRow.Team.Users.push(
                        {OID: userRow.User.OID, Roles: [userRow.Role.OID]}
                    );
                }

                teams.put(userRow.Team)
                    .then(function () {
                        changemode.Teams[userRow.Team.OID] = userRow.Team;

                        var teamParent = changemode.RootTeam;

                        if (teamParent) {
                            teamParent.Children = teamParent.Children || [];
                            teamParent.Children.push(userRow.Team);
                            teamParent.Children.sort(Tools.SortByPosition);
                        }

                        if (userRow.Element.Teams == null) {
                            userRow.Element.Teams = [];
                        }

                        // Teamzuordnung zur OE
                        if (!Tools.contains(userRow.Element.Teams, userRow.Team.OID, 'OID')) {
                            userRow.Element.Teams.push({ OID: userRow.Team.OID });
                        }

                        return putElement(userRow.Element)
                            .then(userImportDeferred.resolve)
                            .fail(userImportDeferred.reject);
                    }).fail(userImportDeferred.reject);
            }).fail(userImportDeferred.reject);

            return userImportDeferred.promise();
        }

        return Tools.ForEachDeferredAction(
            validRowsList,
            createNewUser,
            { Visible: true, Text: i18next.t('progressbar.addingUsers'), ScreenBlock: true }
        );
    }

    function showUserImportErrors(errorRows, newUsers) {
        if ((errorRows || []).length === 0) {
            return;
        }

        var options = {
            NewUsers: newUsers,
            InvalidRows: errorRows,
            Buttons: {
                CustomAction: {
                    Caption: i18next.t('userImport.popup.downloadFile'),
                    OnClick: downloadErrorRowsExcel,
                    Data: errorRows
                },
                Close: {
                    Caption: i18next.t('misc.close'),
                    OnClick: $.noop
                }
            }
        };

        new Tools.Popups.UserImportErrors(options).Show();
    }

    function downloadErrorRowsExcel(invalidRows) {
        var data = {
            InvalidRows: Object.values(invalidRows),
            ExportType: 'HighlightErrors'
        };

        Tools.Spinner.show();

        Tools.http.post('export/users', data)
            .then(function (filename) {
                return onExportSuccess(filename, 'user-import-errors');
            })
            .fail(function (xhr) {
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                Tools.Spinner.hide();
            });
    }

    function onElementExcelImportUploaded(_event, data) {
        var newElements = [];
        var modifiedElements = [];
        var licensesAssigned, result, progressbarText;

        function createElements(item, parent, idx) {
            var newElement;

            item = Tools.Escape.ExcelImportElementItem(item);

            newElement = {
                OID: uuid(),
                ParentOID: parent.OID,
                Title: item.Title,
                Color: '#7E1210',
                Enabled: parent.Enabled,
                Required: true,
                Position: getMaxPosition(parent.Children) + idx + 1
            };

            switch (Mode) {
                case 'explorerchangemode':
                    newElement.Type = Enums.elementType.Location;
                    newElement.Attribute = 1;
                    newElement.Address = {};
                    break;
                case 'items':
                    newElement.Type = Enums.elementType.ObjectTemplate;
                    newElement.Attribute = 2;
                    break;
                case 'forms':
                    newElement.Type = Enums.elementType.Form;
                    break;
                case 'form-templates':
                    newElement.Type = Enums.elementType.FormTemplate;
                    break;
            }

            modifiedElements.push(newElement);

            (item.Children || []).forEach(function (item, idx) {
                createElements(item, newElement, idx);
            });

            return newElement;
        }

        Tools.Spinner.hide();

        result = JSON.parse(data.response);

        if (result.Error === 'Invalid file format') {
            Tools.Message.Show({
                title: i18next.t('changeMode.messages.invalidFileFormat.title'),
                text: i18next.t('changeMode.messages.invalidFileFormat.text'),
                ok: true
            });
        } else if (result.Error) {
            Tools.Message.Show({
                title: i18next.t('changeMode.messages.importError.title'),
                text: i18next.t('changeMode.messages.importError.text'),
                ok: true
            });
        } else if ((result.Data || []).length) {
            result.Data.forEach(function (item, idx) {
                newElements.push(createElements(item, CurrentEntity, idx));
            });

            if (!newElements.length) {
                return;
            }

            switch (Mode) {
                case 'explorerchangemode':
                    if (Client.Licenses && Tools.isNumber(Client.Licenses.Rooms)) {
                        licensesAssigned = Object
                            .keys(changemode.Elements)
                            .map(function (oid) {
                                return changemode.Elements[oid];
                            })
                            .filter(function (element) {
                                return (element.Type === Enums.elementType.Root ||
                                    element.Type === Enums.elementType.Location) &&
                                    element.ModificationType !== Enums.ModificationType.Deleted;
                            })
                            .length;

                        if (licensesAssigned + modifiedElements.length > Client.Licenses.Rooms) {
                            Tools.Message.Show({
                                title: i18next.t('changeMode.messages.noLicensesAvailable.title'),
                                text: i18next.t('changeMode.messages.noLicensesAvailable.text'),
                                ok: true
                            });

                            return;
                        }
                    }

                    progressbarText = i18next.t('progressbar.addingRoom_plural');
                    break;
                case 'items':
                    progressbarText = i18next.t('progressbar.addingItem_plural');
                    break;
                case 'forms':
                    if (Client.Licenses && Tools.isNumber(Client.Licenses.Forms)) {
                        licensesAssigned = Object
                            .keys(changemode.Elements)
                            .map(function (oid) {
                                return changemode.Elements[oid];
                            })
                            .filter(function (element) {
                                return element.Type === Enums.elementType.Form &&
                                    element.ModificationType !== Enums.ModificationType.Deleted;
                            })
                            .length;

                        if (licensesAssigned + modifiedElements.length > Client.Licenses.Forms) {
                            Tools.Message.Show({
                                title: i18next.t('changeMode.messages.noLicensesAvailable.title'),
                                text: i18next.t('changeMode.messages.noLicensesAvailable.text'),
                                ok: true
                            });

                            return;
                        }
                    }

                    progressbarText = i18next.t('progressbar.addingForm_plural');
                    break;
                case 'form-templates':
                    progressbarText = i18next.t('progressbar.addingFormTemplate_plural');
                    break;
            }

            Tools.Progressbar.Start(modifiedElements.length, progressbarText, function () {
                newElements.forEach(function (newElement) {
                    Center.AddTo(newElement, CurrentEntity);
                });
            }, true);

            (function loop() {
                var element = modifiedElements.shift();

                if (!element) {
                    return;
                }

                Tools.Progressbar.StartProgress();

                putElement(element)
                    .done(function () {
                        element.Parent = changemode.Elements[element.ParentOID];
                        element.Parent.Children = element.Parent.Children || [];
                        element.Parent.Children.push(element);

                        changemode.Elements[element.OID] = element;

                        loop();
                        Tools.Progressbar.StopProgress();
                    })
                    .fail(function (rsp) {
                        Tools.Progressbar.Stop();

                        if (rsp.status === 409 && rsp.responseText === 'No licenses available') {
                            Tools.Message.Show({
                                title: i18next.t('changeMode.messages.noLicensesAvailable.title'),
                                text: i18next.t('changeMode.messages.noLicensesAvailable.text'),
                                ok: true
                            });
                        }
                    });
            }());
        } else {
            Tools.Message.Show({
                title: i18next.t('changeMode.messages.importNoData.title'),
                text: i18next.t('changeMode.messages.importNoData.text'),
                ok: true
            });
        }
    }

    function isVisible() {
        return $center.is(':visible') && !$center.hasClass('center-resized');
    }

    Center.Show = show;
    Center.IsVisible = isVisible;
    Center.Select = select;
    Center.Add = add;
    Center.AddTo = addTo;
    Center.AddBefore = addBefore;
    Center.AddAfter = addAfter;
    Center.MoveTo = moveTo;
    Center.Remove = remove;
    Center.ResetSearch = resetSearch;
    Center.ResetDepthChooser = resetDepthChooser;
    Center.AddClassToEntityNode = addClassToEntityNode;
    Center.RemoveClassFromEntityNode = removeClassFromEntityNode;

    return (global.Center = Center);
})(window);