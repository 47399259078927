/**
 * @require ./tools.js
 */
(function (tools) {
    function getLocationData(settings) {
        // Settings ------------
        // {
        //      location (object) (optional),
        //      changemode (boolean) (optional)
        // }

        settings = settings || {};

        var files = settings.changemode ? changemode.Files : Files;
        var location = settings.location;
        var logo, address;

        (function traverse(elem) {
            if (elem) {
                if (!logo && elem.ImageOID && files[elem.ImageOID]) {
                    logo = files[elem.ImageOID];
                }
                if (!address && elem.Address) {
                    address = elem.Address;
                }
                if (!logo || !address) {
                    traverse(elem.Parent);
                }
            }
        })(location);

        return {
            logo: logo,
            address: address
        };
    }

    function hasUIRight(navItemId) {
        var officeConfiguration = Client.OfficeConfiguration || {};
        var enabledNavItems = ((officeConfiguration.explorer || {}).EnabledTabs || [])
            .concat((officeConfiguration.current || {}).EnabledTabs || []);

        return Tools.contains(enabledNavItems, navItemId);
    }

    tools.client = {
        getLocationData: getLocationData,
        hasUIRight: hasUIRight
    };
})(window.Tools || (window.Tools = {}));