(function (global) {
    var ColorPicker = function(options) {
        this.options = options;

        if (!options || !options.$element || !options.$element.length) {
            return;
        }

        this.IsStatic = options.$staticContainer && options.$staticContainer.length;
        this.IsRenderTo = !!options.RenderTo;

        setInitialColor.call(this);

        this.picker = new CP(options.$element[0], this.IsStatic ? false : undefined, this.IsRenderTo);
        this.$picker = $(this.picker.picker);

        createColorWrapperColumn.call(this);
        createTextbox.call(this);
        updateButtons.call(this);
        setFitMethod.call(this);
        addAdditionalClass.call(this);

        bindEvents.call(this);
    };

    function onPickerChange(picker, options, color) {
        if (!color) {
            removeSelectedColor(options);

            return;
        }

        color = ('#' + color).toUpperCase();

        options.$element
            .val(color)
            .attr('value', color)
            .data('color', color)
            .attr('data-color', color);

        if (options.$textinput) {
            options.$textinput
                .val(color)
                .css({
                    'background-color': color,
                    color: new Color(color).getContrastTextColor()
                })
                .attr('value', color)
                .data('color', color)
                .attr('data-color', color);

            if (!options.$element !== options.$textinput) {
                options.$textinput.trigger('change');
            }
        }

        if (options.SetBackgroundColor) {
            options.$element.css({
                backgroundColor: color
            });
        }

        if (options.buttons) {
            if (options.buttons.remove) {
                $(options.buttons.remove.selector).removeAttr('disabled');
            }
        }

        options.$element.trigger('change', color);
    }

    function removeSelectedColor(options) {
        options.$element
            .val('')
            .removeAttr('value')
            .removeAttr('data-color')
            .data('color', '');

        if (options.$textinput) {
            options.$textinput
                .val('')
                .css({
                    'background-color': '',
                    color: ''
                })
                .removeAttr('value')
                .removeAttr('data-color')
                .data('color', '');

            if (!options.$element !== options.$textinput) {
                options.$textinput.trigger('change');
            }
        }

        if (options.SetBackgroundColor) {
            options.$element.css({
                backgroundColor: '#fff'
            });
        }

        options.$element.trigger('change');
    }

    function setColor(hexCode) {
        this.picker.set(hexCode);
        this.picker.trigger('change', [!!hexCode ? hexCode.slice(1) : null], 'main-change');
    }

    function onDefaultColorClick(event, picker) {
        picker.set(this.title);
        picker.trigger('change', [this.title.slice(1)], 'main-change');
    }

    function updatePicker(picker) {
        if (this.value.length) {
            picker.set(this.value);
            picker.trigger('change', [this.value.slice(1)]);
        }
    }

    function unbindEvents() {
        var picker = this.picker;
        var options = this.options;

        picker.off('change', 'main-change');

        options.$element.find('color-picker-box').off('.colorPicker');
        $(picker.picker).find('.color-picker-box').off('.colorPicker');

        if (options.$textinput instanceof $) {
            options.$textinput.off('.colorPicker');
        }

        if (options.buttons) {
            if (options.buttons.remove) {
                this.$picker.find(options.buttons.remove.selector).off('click');
            }
        }
    }

    function bindEvents() {
        unbindEvents.call(this);

        var picker = this.picker;
        var options = this.options;
        var updateTimeout;
        var $defaultColors = $(picker.picker).find('.color-picker-box');

        picker.on('change', function(color) {
            onPickerChange(picker, options, color);
        }, 'main-change');

        $defaultColors.on('click.colorPicker', function(e) {
            onDefaultColorClick.call(this, e, picker);
        });

        if (options.$textinput) {
            options.$textinput.on('input.colorPicker', function() {
                var that = this;

                if (updateTimeout) {
                    window.clearTimeout(updateTimeout);
                    updateTimeout = null;
                }

                if ($(that).val().length <= 0 || $(that).is(':invalid')) {
                    return;
                }

                updateTimeout = window.setTimeout(function() {
                    if (!$(that).is(':invalid')) {
                        updatePicker.call(that, picker);
                    }

                    window.clearTimeout(updateTimeout);
                    updateTimeout = null;
                }, 500);
            });

            options.$textinput.on('mouseup.colorPicker', function(e) {
                e.stopPropagation();
            });
        }

        if (options.buttons) {
            if (options.IsOptional &&
                options.buttons.remove &&
                options.buttons.remove.onClick instanceof Function) {
                $(options.buttons.remove.selector).on('click', options.buttons.remove.onClick);
            }
        }
    }

    function createColorWrapperColumn() {
        var $picker = this.$picker;
        var $column = $('<div class="color-picker-color-column"></div>');

        $column.append(createDefaultColorsContainer.call(this));

        $picker.append($column);

        $picker.find('.color-picker-control').prependTo($column);
    }

    function updateButtons() {
        var options = this.options;

        if (!options.buttons) {
            return;
        }

        if (options.IsOptional && options.buttons.remove && !!options.buttons.remove.selector) {
            var $btn = $(options.buttons.remove.selector);

            $btn.text(options.buttons.remove.caption);
            $btn.removeClass('hide');

            if (!options.InitialColor) {
                $btn.attr('disabled', 'disabled');
            }
        }
    }

    function createDefaultColorsContainer() {
        var options = this.options;
        var defaultColors = [];
        var $container, $box;

        if ((options.DefaultColors || []).length) {
            defaultColors = defaultColors.concat(options.DefaultColors);
        }

        if (!defaultColors.length) {
            return;
        }

        this.DefaultColors = true;

        $container = $('<div class="color-picker-defaultColor"></div>');

        defaultColors.forEach(function(defaultColor) {
            $box = $('<div class="color-picker-box"></div>');
            $box.attr('title', defaultColor)
                .css('backgroundColor', defaultColor);

            $container.append($box);
        });

        return $container;
    }

    function setInitialColor() {
        var options = this.options;
        var initialColor = options.InitialColor;

        if (!initialColor && !options.IsOptional) {
            initialColor = '#7E1210';
        }

        if (initialColor) {
            options.$element
                .val(initialColor)
                .attr('value', initialColor)
                .attr('data-color', initialColor)
                .data('color', initialColor);
        } else {
            options.$element
                .val('')
                .removeAttr('value')
                .removeAttr('data-color')
                .data('color', '');
        }
    }

    function createTextbox() {
        var options = this.options;
        var picker = this.picker;

        if (options.$element.attr('type') === 'text') {
            options.$textinput = options.$element;
        } else if (options.ShowTextbox) {
            var $container = $('<div class="color-picker-text"></div>');
            var $textinput = $('<input type="text" class="color-picker-text-input" value="#{0}">'.format(CP.HSV2HEX(picker.get([0, 1, 1]))));
            options.$textinput = $textinput;

            $container.append($textinput);
            this.$picker.find('.color-picker-control').prepend($container);
        }

        if (options.$textinput) {
            options.$textinput[0].minLength = 4;
            options.$textinput[0].maxLength = 7;
            options.$textinput[0].pattern = '^#[A-Fa-f0-9]{4,6}$';
            options.$textinput[0].required = !options.IsOptional;

            if (options.$textinput.siblings('.button-steal')) {
                options.$textinput.siblings('.button-steal').addClass('hide');

                if (options.buttons && options.buttons.remove) {
                    options.$textinput.removeClass('xlarge');
                }
            }
        }
    }

    function setFitMethod() {
        var options = this.options;

        if (this.IsStatic) {
            this.picker.fit = function() {
                this.picker.style.left = this.picker.style.top = '';
            }
        } else if (this.IsRenderTo) {
            this.picker.fit = function() {
                var position = options.$element.position();
                var height = options.$element.outerHeight();
                var $picker = $(this.picker);

                $picker.css({
                    top: position.top + height,
                    left: position.left,
                    width: $picker.outerWidth()
                });
            }
        }
    }

    function addAdditionalClass() {
        if (this.IsStatic) {
            this.$picker.addClass('static');
        }
    }

    function show() {
        if (this.picker.visible) {
            return;
        }

        var options = this.options;
        var picker = this.picker;

        if (this.IsStatic) {
            picker.enter(options.$staticContainer[0]);
        } else if (this.IsRenderTo) {
            picker.enter(options.RenderTo);
        } else {
            picker.enter();
        }
    }

    function hide() {
        if (!this.picker.visible) {
            return;
        }

        if (this.options.OnHide instanceof Function) {
            this.options.OnHide();
        }

        this.picker.exit();
    }


    ColorPicker.prototype.Show = show;
    ColorPicker.prototype.Hide = hide;
    ColorPicker.prototype.SetColor = setColor;

    return (global.ColorPicker = ColorPicker);
})(window);