/**
 * @require ./tools.js
 */
(function (tools) {
    function getFolderPathForModifiedData(identifier, printGivenFolder) {
        if (!(changemode.Folders && changemode.Folders.hasOwnProperty(identifier))) {
            return '';
        }

        var parent = changemode.Folders[identifier];

        if (!parent.Parent) {
            return '';
        }

        if (!printGivenFolder) {
            parent = parent.Parent;
        }

        var path = parent.Title;

        for (var i = 2; i > 0 && (parent = parent.Parent); i--) {
            if (!parent.Parent) {
                break;
            }

            path = '{0} &rsaquo; {1}'.format(parent.Title, path);
        }

        return path;
    }

    tools.folders = {
        getFolderPathForModifiedData: getFolderPathForModifiedData
    };
})(window.Tools || (window.Tools = {}));