/**
 * @require ../tools.js
 */

(function(tools) {
    /**
     * Input-Optionen für ein Buttons
     * @typedef ButtonInputOptions
     * @property {string} [class] Zusätzliche CSS-Klassen
     * @property {string} type Bestimmt welcher <input> Type
     * @property {number} [min] Mindestwert bei Zahlen
     * @property {number} [max] Maximalwert bei Zahlen
     * @property {number|string} [val] Standardwertt für das <input>
     */

    /**
     * Erstelloptionen für einen Button
     * @typedef ButtonOptions
     * @property {string} [Title] Bezeichung (kann bei Input optional sein)
     * @property {string} [Tooltip]
     * @property {string|number} [ID]
     * @property {boolean} [Clearable] Bestimmt ob Status zurückgesetzt werden kann
     * @property {boolean} [Toggle] Definiert Checkbox Verhalten
     * @property {ButtonInputOptions} [InputOptions] Optionen für Button mit <input>
     * @property {string} [IconURL]
     * @property {boolean} [IsActive] Bestimmt beim Toggle Button ob initial angewählt
     * @property {string} [Classes] CSS-Klassen für Design
     */

    /**
     * Generiert ein HTML-Markup für ein Button mit den festgelegen Optionen
     * @param {ButtonOptions} options Erstelloptionen für den Button
     * @return {string} HTML-Markup für den Button
     */
    function Button(options) {
        if (!options) {
            throw Error('Missing options for Button constructor!');
        }

        var html = options.Clearable ? ['<div class="clearable-button-selection">'] : [];

        // Button Klassen vorbereiten

        var classes = options.Classes instanceof Array ?
            options.Classes.reduce(function(acc, val) {
                acc[val] = true;
                return acc;
            }, {}) : {};

        classes['button-steal'] = true;

        if (options.IconURL) {
            classes['with-icon'] = true;
        }

        if (options.IsActive) {
            classes['is-active'] = true;
        }

        // Button Eigenschaften festlegen

        html.push('<div');

        var attributes = {
            class: Object.keys(classes).join(' ')
        };

        if (options.ID != null) {
            attributes['id'] = String(options.ID);
        }

        if (options.Tooltip || options.Title) {
            attributes['title'] = options.Tooltip || options.Title;
        }

        for (var attrName in attributes) {
            if (attributes.hasOwnProperty(attrName)) {
                html.push(' ');
                html.push(attrName);
                html.push('="');
                html.push(attributes[attrName]);
                html.push('"');
            }
        }

        html.push('>');

        // Button Extras anfügen

        if (options.IconURL) {
            html.push('<div class="icon"><img src="');
            html.push(options.IconURL);
            html.push('"></div>');
        }

        if (options.Title) {
            html.push('<div class="caption">');
            html.push(options.Title);
            html.push('</div>')
        }

        if (options.InputOptions) {
            html.push('<input ');

            for (var propName in options.InputOptions) {
                if (options.InputOptions.hasOwnProperty(propName)) {
                    html.push(' ');
                    html.push(propName);
                    html.push('="');
                    html.push(options.InputOptions[propName]);
                    html.push('"');
                }
            }

            if (options.Toggle && !options.IsActive) {
                html.push(' disabled');
            }

            html.push(' />');
        }

        html.push('</div>')

        if (options.Clearable) {
            html.push('<div class="button-steal btn-reset clear">×</div>');
            html.push('</div>')
        }

        return html.join('');
    }

    tools.components = tools.components || {};
    tools.components.Button = Button;
})(window.Tools || (window.Tools = {}));
