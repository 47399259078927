/**
 * @require ./tools.js
 */
(function (tools) {
    function getTypeName(element, root) {
        switch (element.Type) {
            case Enums.elementType.Root:
            case Enums.elementType.Location:
                return i18next.t('elementTypes.room');
            case Enums.elementType.ObjectTemplate:
                return i18next.t('elementTypes.item');
            case Enums.elementType.Form:
                return i18next.t('elementTypes.form');
            case Enums.elementType.CheckpointGroupTemplate:
                return i18next.t('elementTypes.testgroupTemplate');
            case Enums.elementType.MasterdataTemplate:
                return i18next.t('elementTypes.masterDataTemplate');
            case Enums.elementType.PropertyTemplate:
                return i18next.t('elementTypes.propertyTemplate');
            case Enums.elementType.Sensor:
                return i18next.t('elementTypes.sensor');
            case Enums.elementType.MasterdataGroup:
                return i18next.t('elementTypes.masterDataGroup');
            case Enums.elementType.FormHeader:
                return i18next.t('elementTypes.formHeader');
            case Enums.elementType.FormFooter:
                return i18next.t('elementTypes.formFooter');
            case Enums.elementType.SubsampleFormRow:
                return i18next.t('elementTypes.formRow');
            case Enums.elementType.SingletonFormRow:
                if (root &&
                    (root.Type === Enums.elementType.Form || root.Type === Enums.elementType.FormTemplate) &&
                    root.IsWeighted) {
                    return i18next.t('elementTypes.singleFormRow_weighted');
                }

                return i18next.t('elementTypes.singleFormRow');
            case Enums.elementType.Parametergroup:
                return i18next.t('elementTypes.testgroup');
            case Enums.elementType.Checkbox:
                return i18next.t('elementTypes.checkbox');
            case Enums.elementType.Number:
                if (element.RepresentationType &&
                    root &&
                    root.Type === Enums.elementType.Form &&
                    root.IsSurvey) {
                    switch (element.RepresentationType) {
                        case 1:
                            return i18next.t('formsOfRepresentation.smileysHorizontal');
                        case 2:
                            return i18next.t('formsOfRepresentation.smileysVertical');
                        case 3:
                            return i18next.t('formsOfRepresentation.stars');
                    }
                }

                return i18next.t('elementTypes.number');
            case Enums.elementType.Line:
                return i18next.t('elementTypes.line');
            case Enums.elementType.Memo:
                return i18next.t('elementTypes.memo');
            case Enums.elementType.Date:
                return i18next.t('elementTypes.date');
            case Enums.elementType.Time:
                return i18next.t('elementTypes.time');
            case Enums.elementType.Photo:
                return i18next.t('elementTypes.photo');
            case Enums.elementType.Scancode:
                return i18next.t('elementTypes.scancode');
            case Enums.elementType.LocationCode:
                return i18next.t('elementTypes.locationCode');
            case Enums.elementType.ListBox:
                return i18next.t('elementTypes.listbox');
            case Enums.elementType.MultiListBox:
                return i18next.t('elementTypes.multipleChoice');
            case Enums.elementType.Info:
                return i18next.t('elementTypes.info');
            case Enums.elementType.Print:
                return i18next.t('elementTypes.print');
            case Enums.elementType.Signature:
                return i18next.t('elementTypes.signature');
            case Enums.elementType.UsersAndTeams:
                return i18next.t('elementTypes.usersAndTeams');
            case Enums.elementType.PhoneNumber:
                return i18next.t('elementTypes.phoneNumber');
            case Enums.elementType.EMailAddresses:
                return i18next.t('elementTypes.emailAddresses');
            case Enums.elementType.IndividualData:
                if (element.AdditionalSettings) {
                    var schemas = IndividualData.GetSchemas();

                    var types = $.map(element.AdditionalSettings.Types || [], function (type) {
                        var schema = Tools.getFirst(schemas, type, 'Type');

                        return schema ? schema.NamePlural : null;
                    });

                    if (types.length) {
                        return types.join(', ');
                    }
                }

                return i18next.t('elementTypes.individualData');
            case Enums.elementType.Files:
                return i18next.t('elementTypes.files');
            default:
                return i18next.t('misc.unknown');
        }
    }

    function prepareAdditionalProperties(properties, prepareForPrint) {
        var propertyList = {};
        var groups = [];
        var property;
        var parent;

        if ((properties || []).length) {
            for (var pCnt = 0, pLen = properties.length; pCnt < pLen; pCnt++) {
                property = properties[pCnt];

                propertyList[property.OID] = $.extend(true, {}, property);
            }

            for (var oid in propertyList) {
                property = propertyList[oid];

                if (!!property.ParentOID) {
                    parent = propertyList[property.ParentOID];

                    if (!parent) {
                        continue;
                    }

                    parent.Children = parent.Children || [];
                    parent.Children.push(property);
                    property.Parent = parent;

                    parent.Children.sort(Tools.SortByPosition);
                } else {
                    groups.push(property);
                    groups.sort(Tools.SortByPosition);
                }

                property.Value = getAdditionalPropertyValue(property, prepareForPrint);
            }
        }

        return groups;
    }

    function getAdditionalPropertyValue(property, prepareForPrint, asStringValue) {
        if (property.Type !== Enums.additionalPropertyType.Checkbox
            && !property.Value) {
            return '-/-';
        }

        switch (property.Type) {
            case Enums.additionalPropertyType.Checkbox:
                if (property.Value === true) {
                    if (asStringValue) {
                        return i18next.t('misc.yes');
                    }

                    return '<img src="./img/checked.svg" width="{0}">'.format(prepareForPrint ? 25 : 50);
                } else if (property.Value === false) {
                    if (asStringValue) {
                        return i18next.t('misc.no');
                    }

                    return '<img src="./img/crosschecked.svg" width="{0}">'.format(prepareForPrint ? 25 : 50);
                } else {
                    if (asStringValue) {
                        return '-/-';
                    }

                    return '<img src="./img/unchecked.svg" width="{0}">'.format(prepareForPrint ? 25 : 50);
                }
            case Enums.additionalPropertyType.Date:
                return Tools.dateTime.dateToString(new Date(property.Value));
            case Enums.additionalPropertyType.Time:
                return Tools.dateTime.timeToString(new Date(property.Value));
            case Enums.additionalPropertyType.Image:
                if (asStringValue) {
                    return property.Value;
                }

                var file = Files[property.Value];

                if (!file) {
                    return '-/-';
                }

                return '<img src="{0}images/s/{1}" />'.format(Config.BaseUri, file.Filename);
            case Enums.additionalPropertyType.IndividualData:
                var selectedEntities = typeof property.Value === 'object' ?
                    property.Value[property.SubType] || [] :
                    [];

                var texts = $.map(selectedEntities, function (id) {
                    return IndividualData.GetEntityTitle(property.SubType, id);
                }).filter(function (title) {
                    return !!title;
                });

                texts.sort(Tools.SortStringArray);

                return texts.join('<br>') || '-/-';
        }

        return property.Value;
    }

    function isDescendant(descendant, ancestor) {
        while (descendant) {
            if (descendant.OID === ancestor.OID) {
                return true;
            }

            descendant = descendant.Parent;
        }

        return false;
    }

    function getElementPath(identifier, useModified, typeFilter) {
        var dataSource = useModified ? changemode.Elements : DataManager.OrganizationUnitLoader.Data.DataMap;
        var path;

        if (dataSource.hasOwnProperty(identifier)) {
            var parent = dataSource[identifier];

            if (!parent.Parent) {
                return '';
            }

            parent = parent.Parent;
            path = parent.Title;

            for (var i = 2; i > 0 && (parent = parent.Parent); i--) {
                if (typeFilter instanceof Array &&
                    typeFilter.indexOf(parent.Type) === -1) {
                    break;
                }

                path = '{0} &rsaquo; {1}'.format(parent.Title, path);
            }
        }

        return path;
    }

    function formatElementCoding(prefix, ident) {
        if (!prefix || !ident) {
            return null;
        }

        if (typeof ident === 'number') {
            ident = formatElementCodingIdent(ident);
        }

        return prefix + ident;
    }

    function formatElementCodingIdent(ident) {
        if (!ident) {
            return null;
        }

        return ('' + ident).padleft('0', 4);
    }

    function createElementHierarchy(elements) {
        if (!(elements instanceof Array)) {
            return null;
        }

        var map = elements.reduce(function (map, elem) {
            map[elem.OID] = elem;

            return map;
        }, {});

        var root;

        elements.forEach(function (elem) {
            var parentElement = map[elem.ParentOID];

            if (elem.ParentOID && !parentElement) {
                delete elem.ParentOID;
            }

            if (elem.Type === Enums.elementType.Form && parentElement && parentElement.Type === Enums.elementType.Root) {
                delete elem.ParentOID;
                parentElement = null;
            }

            if (!elem.ParentOID) {
                root = elem;
                return;
            }

            if (parentElement) {
                elem.Parent = parentElement;

                if (elem.Type >= Enums.elementType.Checkbox) {
                    // Prüfpunkte
                    parentElement.Parameters = parentElement.Parameters || [];
                    parentElement.Parameters.push(elem);
                    parentElement.Parameters.sort(Tools.SortByPosition);
                } else if (Tools.contains(Enums.elementType.AllGroups, elem.Type)) {
                    // Prüfgruppen
                    parentElement.Parametergroups = parentElement.Parametergroups || [];
                    parentElement.Parametergroups.push(elem);
                    parentElement.Parametergroups.sort(Tools.SortByPosition);
                } else if (elem.Type !== Enums.elementType.Root) {
                    // Der Rest
                    parentElement.Children = parentElement.Children || [];
                    parentElement.Children.push(elem);
                    parentElement.Children.sort(Tools.SortByPosition);
                }
            }
        });

        return root;
    }

    function createHierarchyForDownloadedElements(elements, attachToOUs) {
        if (!(elements instanceof Array) || !elements.length) {
            return;
        }

        attachToOUs = attachToOUs || false;

        var cache = (elements || []).reduce(function (dict, element) {
            dict[element.OID] = Tools.Escape.Element(element);

            return dict;
        }, {});

        for (var oid in cache) {
            var element = cache[oid];

            if (!element.ParentOID) {
                continue;
            }

            var parent = cache[element.ParentOID] || DataManager.OrganizationUnitLoader.Data.DataMap[element.ParentOID];

            if (!parent) {
                continue;
            }

            element.Parent = parent;

            if (attachToOUs &&
                (parent.Type === Enums.elementType.Root || parent.Type === Enums.elementType.Location) &&
                element.Type === Enums.elementType.Parametergroup) {
                parent.Parametergroups = parent.Parametergroups || [];
                parent.Parametergroups.push(element);
                parent.Parametergroups.sort(Tools.SortByPosition);
            }

            if (parent.Type >= Enums.elementType.SingletonFormRow &&
                element.Type >= Enums.elementType.Checkbox) {
                parent.Children = parent.Children || [];
                parent.Children.push(element);
                parent.Children.sort(Tools.SortByPosition);
            }
        }

        return cache;
    }

    function getFromAdditionalSettings(element, settingsKey, defaultValue) {
        if (!element || !element.AdditionalSettings || !settingsKey) {
            return defaultValue;
        }

        return element.AdditionalSettings[settingsKey] || defaultValue;
    }

    tools.element = {
        getTypeName: getTypeName,
        prepareAdditionalProperties: prepareAdditionalProperties,
        getAdditionalPropertyValue: getAdditionalPropertyValue,
        isDescendant: isDescendant,
        getElementPath: getElementPath,
        formatElementCoding: formatElementCoding,
        formatElementCodingIdent: formatElementCodingIdent,
        createElementHierarchy: createElementHierarchy,
        createHierarchyForDownloadedElements: createHierarchyForDownloadedElements,
        getFromAdditionalSettings: getFromAdditionalSettings
    };
})(window.Tools || (window.Tools = {}));