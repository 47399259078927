/**
 * @require ./tools.js
 */
(function (tools) {
    function getWithMarks(settings) {
        var viewboxRegex = /<svg[^>]*width="(\d+)"[^>]*height="(\d+)"/ig;
        var size = settings.size || 's';
        var match, oid, image, svgWidth, svgHeight, viewBoxWidth, viewBoxHeight, viewBox, src;
        var svg = [];

        if (settings.filename) {
            image = { Filename: settings.filename };

            for (oid in Files) {
                if (Files[oid].Filename === settings.filename) {
                    image = Files[oid];
                    break;
                }
            }
        } else if (settings.image) {
            image = settings.image;
        }

        svgWidth = settings.width || '100%';
        svgHeight = settings.height || '100%';
        viewBox = '';

        if ((match = viewboxRegex.exec(image.Marks)) && match.length === 3) {
            viewBoxWidth = parseInt(match[1], 10);
            viewBoxHeight = parseInt(match[2], 10);

            if (viewBoxWidth && viewBoxHeight) {
                viewBox = ' viewBox="0 0 {0} {1}"'.format(viewBoxWidth, viewBoxHeight);
            }
        }

        src = image.Local ?
            './img/' + image.Filename :
            '{0}images/{1}/{2}'.format(Config.BaseUri, size, image.Filename);

        svg.push('<svg width="{0}" height="{1}" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1"{2}>'
            .format(svgWidth, svgHeight, viewBox));

        svg.push(
            '<image xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="{0}" x="0" y="0" width="100%" height="100%" data-filename="{1}" crossorigin="use-credentials"'.format(src, image.Filename),
            (settings.additionalClasses || []).length ? ' class="{0}"'.format(settings.additionalClasses.join(' ')) : '',
            (settings.customAttributes || []).length ? settings.customAttributes.join(' ') : '',
            '></image>'
        );

        svg.push(getInnerSVG(image.Marks));
        svg.push('</svg>');

        return svg.join('');
    }

    function getInnerSVG(marks) {
        if (!marks) {
            return '';
        }

        var svgRegex = /<svg.*?>([\S\s]*)<\/svg>/ig;
        var innerSVG = '';
        var match = svgRegex.exec(marks);

        if (match && match.length > 1) {
            innerSVG =  match[0] || '';
        }

        return innerSVG;
    }

    tools.image = {
        getWithMarks: getWithMarks
    };
})(window.Tools || (window.Tools = {}));